import Button from '../components/inputs/Button';
import Form from '../components/inputs/Form';
import Input from '../components/inputs/Input';
import { useEffect, useRef, useState } from 'react';

import { ReactComponent as ActivationIcon } from '../assets/ybw_landing_activation.svg';
import { ReactComponent as EvaluationIcon } from '../assets/ybw_landing_evaluation.svg';
import { ReactComponent as IntroductionIcon } from '../assets/ybw_landing_introduction.svg';
import { ReactComponent as OnboardingIcon } from '../assets/ybw_landing_onboarding.svg';
import { ReactComponent as QuickScanIcon } from '../assets/ybw_landing_quickscan.svg';

import './PartnerPage.scss';

function RoadMap(props) {

   const SCROLLABLE_PIXELS_PER_STEP = 1500;

   // state vars: calculations
   const [currentStep, setCurrentStep] = useState(0);
   const [nextStepProgress, setNextStepProgress] = useState(0);
   const [totalProgress, setTotalProgress] = useState(0);
   const [scrollablePixels, setScrollablePixels] = useState(1000);
   const [scrolledPixelsTop, setScrolledPixelsTop] = useState(0); 
   const [scrolledPixelsBottom, setScrolledPixelsBottom] = useState(0); 

   // state vars: look
   const [opacity, setOpacity] = useState(1);
   
   // element refs
   const scrollAreaRef = useRef(null);
   const viewportRef = useRef(null);

   useEffect(() => {

      const newScrollablePixels = SCROLLABLE_PIXELS_PER_STEP * props.steps.length + SCROLLABLE_PIXELS_PER_STEP;
      setScrollablePixels(newScrollablePixels);

      const roadMapScrollListener = () => {
         const newScrolledPixelsTop = Math.max(-1 * scrollAreaRef.current.getBoundingClientRect().y, 0);
         const newScrolledPixelsBottom = Math.min(newScrolledPixelsTop + viewportRef.current.getBoundingClientRect().height, newScrollablePixels);
         setScrolledPixelsTop(newScrolledPixelsTop);
         setScrolledPixelsBottom(newScrolledPixelsBottom);
         
         // current step and progress
         const newCurrentStep = Math.min(Math.floor(newScrolledPixelsTop / SCROLLABLE_PIXELS_PER_STEP), props.steps.length - 1);
         const completedStepsScrollable = newCurrentStep * SCROLLABLE_PIXELS_PER_STEP;
         const newNextStepProgress = (newScrolledPixelsTop - completedStepsScrollable) / SCROLLABLE_PIXELS_PER_STEP;
         setCurrentStep(newCurrentStep);  
         setNextStepProgress(Math.min(1, newNextStepProgress));
         setTotalProgress(newScrolledPixelsTop / scrollablePixels);
         
         // calculate style
         let newOpacity;
         const FADE_THRESHOLD = 0.35;
         if ((newNextStepProgress <= FADE_THRESHOLD && newCurrentStep > 0 || newNextStepProgress >= 1 - FADE_THRESHOLD && newCurrentStep < props.steps.length - 1)) {
            newOpacity = Math.max(Math.min(newNextStepProgress, 1 - newNextStepProgress) / FADE_THRESHOLD - 0.1, 0);
         } else {
            newOpacity = 1;
         }

         setOpacity(newOpacity);

      }

      window.addEventListener('scroll', roadMapScrollListener);

      return () => {
         window.removeEventListener('scroll', roadMapScrollListener);
      }

   }, [props.steps]);

   return (
      <div 
         id="roadmap-scroll-area"
         style={{
            height: `${scrollablePixels}px`
         }}
         ref={scrollAreaRef}
         >

         <div 
            id="roadmap-viewport"
            ref={viewportRef}
            style={{
               position: (scrolledPixelsTop > 0 && scrolledPixelsBottom < scrollablePixels ? "fixed" : "absolute"),
               top: (scrolledPixelsBottom < scrollablePixels ? "0px" : "auto"),
               bottom: (scrolledPixelsBottom < scrollablePixels ? "auto" : "0px" )
            }}
            >

            <div id="roadmap-bg">

               {[...Array(10)].map((value, key) => {

                  return (

                     <div
                        key={key}
                        className="roadmap-bg-row"
                        style={{
                           transform: "translateX(" + String((key % 2 == 0 ? 1 : -1) * totalProgress * 8 + (key % 2 == 0 ? 5 : 0)) + "%)"
                        }}
                        >

                        {[...Array(20)].map((value, key) => {

                           return (
                              <h3 key={key}>Hoe we werken.</h3>
                           )

                        })}

                     </div>

                  );

               })}

            </div>
               
            <div id="roadmap-container">

               <div id="roadmap-progress">

                  <div 
                     id="roadmap-graphic"
                     style={{
                        opacity: opacity
                     }}
                     >
                     {props.steps[currentStep].graphic}
                  </div>

                  <div id="roadmap-progress-dots">

                     <div id="roadmap-progress-dots-bg"></div>

                     {[...Array(9)].map((value, key) => {
                        return (
                           <div 
                              key={key}
                              style={{
                                 transform: "scale(" + String(1 - (0.15 * Math.abs(4 - key)))  + ")",
                                 marginBottom: String(10 - Math.abs(4 - key) * 4) + "px",
                              }}
                              className="roadmap-progress-dot">
                              
                           </div>
                        )
                     })}

                     <div 
                        id="roadmap-progress-indicator"
                        style={{
                           top: String(-10 + nextStepProgress * 120) + "%",
                        }}
                        />

                  </div>

               </div>

               <div 
                  id="roadmap-text"
                  style={{
                     opacity: opacity
                  }}
                  >
                  
                  <span>Stap {currentStep + 1}</span>
                  <h2>{props.steps[currentStep].title}</h2>
                  <p>{props.steps[currentStep].text}</p>

               </div>

            </div>

         </div>

      </div>
   )

}

export default function PartnerPage () {

   useEffect(() => {
      document.title = "Partner Worden - You Be Well";
   }, []);

   const [isVisible, setIsVisible] = useState(new Array(5));
   const domRefs = useRef([]);

   // manage submissions
   const [submitted, setSubmitted] = useState(false);

   const handleScroll = () => {
     domRefs.current.forEach((domRef, index) => {
       if (!domRef) return;
       const rect = domRef.getBoundingClientRect();
       const isInView = (
         rect.top <= window.innerHeight
       );
      setIsVisible(prevVisible => {
         const updatedVisible = [...prevVisible];
         updatedVisible[index] = isInView;
         return updatedVisible;
      });
     });
   };

   useEffect(() => {
      window.addEventListener('scroll', handleScroll, true);
      handleScroll();
  
      return () => {
        window.removeEventListener('scroll', handleScroll, true);
      };
   }, []);

   return (
      <main id="partners">

         <section id="hero">

            <div className="container">
               
               <div id="hero-tagline">
                  <h1>Healthy People.<br />Healthy Business.</h1>
                  <p>Met You Be Well werken jouw medewerkers geautomatiseerd aan hun welzijn.</p>

                  <div className="buttons">
                     <Button 
                        href="#contact-form" 
                        name="Boek QuickScan"
                        cta
                        />
                     <Button 
                        href="/activities"
                        name="Bekijk aanbod"
                        target="_blank"
                     />
                  </div>
               </div>

               <div id="hero-images">
                  <div id="hero-image-wrapper">
                     <div className="col">
                        <img src={require('../assets/tile_boxing.jpg')} />
                        <img src={require('../assets/tile_talk.jpg')} />
                        <img src={require('../assets/tile_food.jpg')} />
                     </div>
                     <div className="col">
                        <img src={require('../assets/tile_massage.jpg')} />
                        <img src={require('../assets/tile_meditate.jpg')} />
                     </div>
                  </div>
               </div>

            </div>
         </section>

         <section 
            id="usps"
            className={"container" + (isVisible[0] ? " in-view" : "")}
            ref={el => domRefs.current[0] = el}
            >

            <h2>Sluit je aan bij You Be Well.</h2>
            <p>Stel budget beschikbaar voor je medewerkers, zodat zij welzijnsactiviteiten uit kunnen voeren.</p>

            <div className="usp">
               <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M1.604 24c1.853-2.784 7.647-8.21 13.919-9.494l.525 3.276c-3.773.264-9.01 2.523-14.444 6.218zm-1.604-1c2.037-2.653 6.013-6.906 6.226-15.092l-3.271.561c.418 4.888-1.546 10.626-2.955 14.531zm20.827-11.423l.802 2.4 2.371.883-2.035 1.504-.107 2.528-2.06-1.471-2.437.68.763-2.413-1.4-2.109 2.531-.02 1.572-1.982zm-11.911 3.677h-.018c-.268 0-.49-.213-.499-.483-.098-2.877.511-4.87 3.798-5.24 1.953-.219 2.029-1.116 2.135-2.357.099-1.171.235-2.775 2.737-2.959 1.23-.09 1.908-.307 2.267-.725.407-.475.528-1.357.403-2.948-.022-.275.184-.516.459-.538.254-.019.516.184.537.46.151 1.906-.035 2.972-.64 3.678-.556.647-1.411.957-2.953 1.07-1.651.122-1.712.846-1.814 2.046-.106 1.247-.251 2.956-3.02 3.267-2.33.262-3.011 1.247-2.91 4.212.01.276-.207.507-.482.517zm12.084-9.254c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm-13.715-4.058l-2.531.017-1.601-1.959-.766 2.412-2.359.918 2.058 1.473.144 2.527 2.037-1.501 2.447.643-.798-2.401 1.369-2.129zm3.715.058c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2z"/></svg>
               <h3>Gezonder<span className="stress">.</span></h3>
               <p>Minimaliseer stress- en burn-out-klachten onder jouw personeel.</p>
            </div>
            <div className="usp">
               <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M1.604 24c1.853-2.784 7.647-8.21 13.919-9.494l.525 3.276c-3.773.264-9.01 2.523-14.444 6.218zm-1.604-1c2.037-2.653 6.013-6.906 6.226-15.092l-3.271.561c.418 4.888-1.546 10.626-2.955 14.531zm20.827-11.423l.802 2.4 2.371.883-2.035 1.504-.107 2.528-2.06-1.471-2.437.68.763-2.413-1.4-2.109 2.531-.02 1.572-1.982zm-11.911 3.677h-.018c-.268 0-.49-.213-.499-.483-.098-2.877.511-4.87 3.798-5.24 1.953-.219 2.029-1.116 2.135-2.357.099-1.171.235-2.775 2.737-2.959 1.23-.09 1.908-.307 2.267-.725.407-.475.528-1.357.403-2.948-.022-.275.184-.516.459-.538.254-.019.516.184.537.46.151 1.906-.035 2.972-.64 3.678-.556.647-1.411.957-2.953 1.07-1.651.122-1.712.846-1.814 2.046-.106 1.247-.251 2.956-3.02 3.267-2.33.262-3.011 1.247-2.91 4.212.01.276-.207.507-.482.517zm12.084-9.254c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm-13.715-4.058l-2.531.017-1.601-1.959-.766 2.412-2.359.918 2.058 1.473.144 2.527 2.037-1.501 2.447.643-.798-2.401 1.369-2.129zm3.715.058c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2z"/></svg>
               <h3>Persoonlijk<span className="stress">.</span></h3>
               <p>One size does not fit all. Personaliseer het aanbod voor jouw medewerkers.</p>
            </div>
            <div className="usp">
               <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M1.604 24c1.853-2.784 7.647-8.21 13.919-9.494l.525 3.276c-3.773.264-9.01 2.523-14.444 6.218zm-1.604-1c2.037-2.653 6.013-6.906 6.226-15.092l-3.271.561c.418 4.888-1.546 10.626-2.955 14.531zm20.827-11.423l.802 2.4 2.371.883-2.035 1.504-.107 2.528-2.06-1.471-2.437.68.763-2.413-1.4-2.109 2.531-.02 1.572-1.982zm-11.911 3.677h-.018c-.268 0-.49-.213-.499-.483-.098-2.877.511-4.87 3.798-5.24 1.953-.219 2.029-1.116 2.135-2.357.099-1.171.235-2.775 2.737-2.959 1.23-.09 1.908-.307 2.267-.725.407-.475.528-1.357.403-2.948-.022-.275.184-.516.459-.538.254-.019.516.184.537.46.151 1.906-.035 2.972-.64 3.678-.556.647-1.411.957-2.953 1.07-1.651.122-1.712.846-1.814 2.046-.106 1.247-.251 2.956-3.02 3.267-2.33.262-3.011 1.247-2.91 4.212.01.276-.207.507-.482.517zm12.084-9.254c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm-13.715-4.058l-2.531.017-1.601-1.959-.766 2.412-2.359.918 2.058 1.473.144 2.527 2.037-1.501 2.447.643-.798-2.401 1.369-2.129zm3.715.058c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2z"/></svg>
               <h3>Efficiënt<span className="stress">.</span></h3>
               <p>Binnen 1 week moeiteloos live, volledig ontzorgd door onze specialisten.</p>
            </div>

         </section>

         <section id="info" className="container">

            <h2>Waarom You Be Well werkt.</h2>
            <p>Je hebt geen HR-teams nodig om een welzijnsbeleid te bouwen.</p>

            <div 
               className={"row" + (isVisible[1] ? " in-view" : "")}
               ref={el => domRefs.current[1] = el}
               >
               <div className="text">
                  <span>Implementatie</span>
                  <h2>Jouw tijd is waardevol<span className="stress">.</span></h2>
                  <p>Met You Be Well werk je geautomatiseerd aan het welzijn van jouw medewerkers. Profiteer van een groot aanbod en ons unieke boekingssysteem.</p>
               </div>
               <div className="image">
                  <img src={require('../assets/woman_phone.jpg')} />
               </div>
            </div>

            <div
               className={"row" + (isVisible[2] ? " in-view" : "")}
               ref={el => domRefs.current[2] = el}
               >
               <div className="text">
                  <span>Engagement</span>
                  <h2>Gebruik stimuleren wij<span className="stress">.</span></h2>
                  <p>Door aanbod te personaliseren en geautomatiseerd gebruik te triggeren, gaan je medewerkers daadwerkelijk met hun welzijn aan de slag.</p>
               </div>
               <div className="image">
                  <img src={require('../assets/client_meeting.jpg')} />
               </div>
            </div>

            <div
               className={"row" + (isVisible[3] ? " in-view" : "")}
               ref={el => domRefs.current[3] = el}
               >
               <div className="text">
                  <span>No cure? No pay.</span>
                  <h2>Jij hebt de regie<span className="stress">.</span></h2>
                  <p>Bepaal zelf hoeveel jouw medewerkers gebruik kunnen maken van You Be Well. Ligt het gebruik in een bepaalde periode lager? Dan dalen je kosten evenredig.</p>
               </div>
               <div className="image">
                  <img src={require('../assets/entrepreneur.jpg')} />
               </div>
            </div>

         </section>

         <section 
            id="cta-quickscan"
            className={isVisible[4] ? " in-view" : ""}
            ref={el => domRefs.current[4] = el}
            >

            <div id="cta-quickscan-bg">

               {[...Array(10)].map((value, key) => {

                  return (
                     <div
                        key={key}
                        className="arrow-row">
                        {[...Array(100)].map(() => {
                           return (
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.024 4h6.015l7.961 8-7.961 8h-6.015l7.961-8-7.961-8zm-10.024 16h6.015l7.961-8-7.961-8h-6.015l7.961 8-7.961 8z"/></svg>
                           )
                        })}
                     </div>
                  );

               })}
            
            </div>

            <div className="container">

               <h2>Nieuwsgierig? Doe de gratis QuickScan.</h2>
               <p>Ontdek vrijblijvend de wensen van jouw medewerkers op het gebied van well-being.</p>

               <img src={require('../assets/partner_cta.jpg')} />

               <Button 
                  href="#contact-form" 
                  name="Reserveer nu"
                  cta
                  />
            </div>

         </section>

         <RoadMap 
            steps={[
               {title: "Introductie", text: "In deze kennismaking horen we graag wie je bent. Waar liggen de raakvlakken met well-being?", graphic: <IntroductionIcon />},
               {title: "Gratis QuickScan", text: "Met deze door ons verzorgde scan brengen we in kaart wat jouw medewerkers willen doen met well-being.", graphic: <QuickScanIcon />},
               {title: "Onboarding", text: "Met een introductiesessie voor worden je medewerkers gemaakt in de wereld van You Be Well.", graphic: <OnboardingIcon />},
               {title: "Activatie", text: "Wij zorgen ervoor dat je medewerkers kunnen inloggen op You Be Well en activiteiten gaan boeken.", graphic: <ActivationIcon />},
               {title: "Evaluatie", text: "Wij houden je op de hoogte van de resultaten: hoe wordt You Be Well binnen jouw organisatie gebruikt?", graphic: <EvaluationIcon />},
            ]}

            />

         <a id="contact-form"></a>

         <section id="contact">

            <div className="container">

               <div className="img-container">
                  <img src={require('../assets/partners_form.jpg')} />  
               </div>

               <div className="form-container">

                  <h3>Regel een QuickScan<span className="stress">.</span></h3>
                  <p>Ontdek de wensen van jouw medewerkers op het gebied van hun welzijn.</p>

                  <ul>
                     <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>Binnen 1 minuut aangevraagd, binnen 24 uur een inhoudelijke reactie</li>
                     <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>Sit back and relax: volledig gratis uitgevoerd door ons</li>
                     <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>Met als resultaat: een bruikbaar overzicht van de welzijnswensen van jouw medewerkers</li>
                  </ul>

                  {!submitted &&

                     <Form
                        action="/api/v1/leadgen"
                        submitBtnText="Aanvragen"
                        submitCallback={() => { 
                           setSubmitted(true); 
                        }}
                        >

                        <Input
                           id="contact-name"
                           type="text"
                           name="contact_name"
                           placeholder="Je naam"
                           icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"/></svg>}
                           maxLength="50"
                           required />

                        <Input
                           id="contact-company"
                           type="text"
                           name="contact_company_name"
                           placeholder="Naam van je bedrijf"
                           icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"/></svg>}
                           maxLength="50"
                           required />

                        <Input
                           id="contact-email"
                           type="email"
                           name="email"
                           placeholder="E-mailadres"
                           icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"/></svg>}
                           maxLength="50"
                           required />

                        <Input
                           type="text"
                           name="tel"
                           placeholder="Telefoonnummer"
                           maxLength="15"
                           icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/></svg>} 
                           />
   
                     </Form>
                  }

                  {submitted &&

                     <>
                        <p
                           id="contact-form-success"
                           >
                           Gelukt! We komen binnen 24 uur bij je terug. Ondertussen You Be Well verkennen?
                        </p>

                        <Button 
                           href="/activities"
                           name="Alle activiteiten"
                        />

                     </>

                  }

               </div>

            </div>

         </section>

      </main>
   );

}