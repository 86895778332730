import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch';

import Ad from '../components/Ad';
import Button from '../components/inputs/Button';
import Form from '../components/inputs/Form';
import Select from '../components/inputs/Select';

import './HomePage.scss';

export default function HomePage() {

   const [ads, setAds] = useState([]);
   const navigate = useNavigate();
   const [filterValues, setFilterValues] = useState({
      'activities': [],
      'city': [],
   });

   // convert uuids of options to activities
   const [optionMap, setOptionMap] = useState({});

   const searchActivities = () => {

      let activity = document.getElementById('select-activity-value').value;
      const city = document.getElementById('select-city-value').value;

      if (!activity && !city) {
         navigate('/activities');
         return;
      }

      let query = "?";
      const options = activity.split(";");
      for (let i = 0; i < options.length; i++) {
         if (options[i]) {
            query += (i > 0 ? '&' : '') + 'options[]=' + options[i];
         }
      }

      if (city) {
         if (activity) {
            query += '&';
         }
         query += 'city=' + city;
      }

      navigate('/activities/' + query);

   }

   const setInitFilters = (cities, options) => {

      const filters = {}
      
      // selects
      filters['city'] = []
      for (const city of cities) {
         filters['city'].push({
            name: city.name,
            value: city.id,
            category: city.province
         });
      }

      filters['options'] = []
      for (const option of options) {
         filters['options'].push({
            name: option.pretty_name,
            value: option.id,
            category: option.category
         });
      }

      setFilterValues(filters);

   }

   const request = {
      activities: {
          endpoint: "/api/v1/activities?n=3",
          method: "GET",
      },
      options: {
          endpoint: "/api/v1/activities/options",
          method: "GET",
      },
      cities: {
         endpoint: "/api/v1/activities/cities",
         method: "GET",
      }
   }
   const [data, loading, error] = useFetch(request);

   useEffect(() => {
      if (data.activities && data.activities.data) {
         setAds(data.activities.data.slice(0, 3));
      }
      if (data.cities && data.options) {
         setInitFilters(data.cities.data, data.options.data);
         const newOptionMap = {};
         for (const option of data.options.data) {
            newOptionMap[option.id] = option;
         }
         setOptionMap(newOptionMap);
      }
   }, [loading]);

   useEffect(() => {
      const shownAds = document.getElementsByClassName('ad');
      for (const ad of shownAds) {
         ad.classList.add('show');
      }
   }, [ads]);

   useEffect(() => {
      document.title = "Verbeter Je Well-Being - You Be Well";
   }, []);  

   return (
      <main id="home">

         <section id="hero">

            <div id="hero-tagline" className="container">
               <div id="hero-tagline-text">
                  <h1>Verbeter je well-being<span className="stress">.</span></h1>
                  <h2>Via You Be Well vind je bij jou passende activiteiten om je welzijn te verhogen.</h2>
               </div>

               <div id="hero-image">
                  <img src={require('../assets/hero_image.jpg')} />
               </div>

            </div>

         </section>

         <section id="hero-search" className="container">
            <div className="form-container">

               <Form
                  id="homepage-search"
                  customSubmit={searchActivities}
                  oneLineSubmit
                  oneLineSubmitIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9.145 18.29c-5.042 0-9.145-4.102-9.145-9.145s4.103-9.145 9.145-9.145 9.145 4.103 9.145 9.145-4.102 9.145-9.145 9.145zm0-15.167c-3.321 0-6.022 2.702-6.022 6.022s2.702 6.022 6.022 6.022 6.023-2.702 6.023-6.022-2.702-6.022-6.023-6.022zm9.263 12.443c-.817 1.176-1.852 2.188-3.046 2.981l5.452 5.453 3.014-3.013-5.42-5.421z"/></svg>}>

                  <div id="hero-search-inputs">

                     <Select
                        id="select-activity"
                        placeholder="Activiteit"
                        name="activity-type"
                        options={filterValues.options ? filterValues.options : []}
                        zIndex="3"
                        icon={<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 24 24"><path d="M10.361.125c-2.504.344-4.758 1.457-6.523 3.099 3.332.159 5.773 1.521 8.351 1.836-.618-2.186-1.2-3.643-1.828-4.935m2.85 9.88c-4.936.474-9.392 2.043-12.988 4.279.463 2.396 1.634 4.541 3.295 6.201 3.802-2.97 5.168-8.005 9.853-9.176-.045-.437-.1-.871-.16-1.304m-.509-2.925c-3.857-.211-7.25-2.45-10.442-2.068-1.417 1.969-2.26 4.379-2.26 6.988l.007.158c3.347-2.259 8.54-3.758 12.868-4.184-.056-.298-.108-.598-.173-.894m11.236 3.703c-2.843-.707-5.834-1.005-8.716-.909.055.412.096.826.137 1.241 3.412.228 6.002 3.617 7.084 6.78.986-1.743 1.557-3.751 1.557-5.895 0-.411-.022-.817-.062-1.217m-3.539-7.343c-2.114.799-2.941 2.915-5.667 3.509l.16.889c2.507-.099 5.161.065 8.607.746-.587-1.975-1.666-3.737-3.1-5.144m-6.893 9.864c-3.361 1.011-5.057 5.821-8.444 8.473 1.961 1.395 4.351 2.223 6.938 2.223l.554-.028c.756-3.443 1.132-6.819.952-10.668m2-.124c.169 3.603-.16 7.068-.856 10.516 2.628-.594 4.932-2.043 6.594-4.05-1.005-2.781-2.837-5.958-5.738-6.466m3.161-11.154c-2.243 1.588-2.462 2.536-4.417 2.965-.444-1.67-1.025-3.375-1.74-4.965 2.271.096 4.382.811 6.157 2"/></svg>}
                        max={5}
                        />

                     <Select
                        id="select-city"
                        placeholder="Plaats"
                        name="activity-city"
                        options={filterValues.city ? filterValues.city : []}
                        zIndex="2"
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>}
                        />

                  </div>

               </Form>

            </div>
         </section>

         <section id="popular">

            <div className="container">

               <div className="light-blue"></div>

               <h2>Nu populair<span className="stress">.</span></h2>
               <p className="subtitle">Activiteiten die momenteel extra gewild zijn.</p>
               
               <div className="ads">

                  {ads && ads.map((value, key) => {
                     return (<Ad key={key} optionMap={optionMap} {...value} />)
                  })}

                  {loading && Array(3).fill(1).map((value, key) => {
                     return (
                        <div className="ad-placeholder" key={key}>
                           <div className="img-placeholder placeholder white">
                              <div className="h3-placeholder placeholder white"></div>
                           </div>
                           <div className="text-placeholder">
                              <div className="tag-placeholder placeholder white"></div>
                           </div>
                        </div>
                     );
                  })}

               </div>

               <Button 
                  name="Alles bekijken"
                  href="/activities"
                  cta
                  />

            </div>   

         </section>

         {/* <HomePageTiles 
            id="places"
            title="Populaire plaatsen"
            subtitle="Dit zijn de 'places-to-be'."
            tiles={[
               {href: '/activities?city=Amsterdam', imgSrc: require('../assets/amsterdam.jpg'), label: 'Amsterdam'},
               {href: '/activities?city=Amsterdam', imgSrc: require('../assets/amsterdam.jpg'), label: 'Amsterdam'},
               {href: '/activities?city=Amsterdam', imgSrc: require('../assets/amsterdam.jpg'), label: 'Amsterdam'},
               {href: '/activities?city=Amsterdam', imgSrc: require('../assets/amsterdam.jpg'), label: 'Amsterdam'},
               {href: '/activities?city=Amsterdam', imgSrc: require('../assets/amsterdam.jpg'), label: 'Amsterdam'},
            ]}
            />

         <HomePageTiles    
            id="activities"
            title="Populaire activiteiten"
            subtitle="Deze activiteiten worden vaak geboekt."
            tiles={[
               {href: '/activities?city=Amsterdam', imgSrc: require('../assets/amsterdam.jpg'), label: 'Amsterdam'},
               {href: '/activities?city=Amsterdam', imgSrc: require('../assets/amsterdam.jpg'), label: 'Amsterdam'},
               {href: '/activities?city=Amsterdam', imgSrc: require('../assets/amsterdam.jpg'), label: 'Amsterdam'},
               {href: '/activities?city=Amsterdam', imgSrc: require('../assets/amsterdam.jpg'), label: 'Amsterdam'},
               {href: '/activities?city=Amsterdam', imgSrc: require('../assets/amsterdam.jpg'), label: 'Amsterdam'},
            ]}
            /> */}

         <section id="categories">
            
            <div className="container">

               <div className="left">
                  <h2>Activiteiten die bij jou passen<span className="stress">.</span></h2>
                  <p>Of je nu coaching zoekt, onder begeleiding wilt mediteren of iets nieuws wilt proberen: je vindt het via You Be Well.</p>
                  <Button 
                     name="Bekijk activiteiten"
                     href="/activities"
                     cta
                     />
               </div>

               <div className="right">
                     
                  <Link className="category" to="/activities?options[]=730455e4-b367-4034-8110-c7394a8c0dde">
                     <img src={require('../assets/tile_meditation.jpg')} />
                     <h4>Meditatie</h4>
                  </Link>

                  <Link className="category" to="/activities?options[]=885156f4-87b6-4c78-9649-782a2a290454">
                     <img src={require('../assets/tile_lifestyle.jpg')} />
                     <h4>Lifestyle coaching</h4>
                  </Link>

                  <Link className="category" to="/activities?options[]=63d26165-b283-4419-8a85-b2a2c135e7ec">
                     <img src={require('../assets/tile_spa.jpg')} />
                     <h4>Spa en sauna</h4>
                  </Link>

                  <Link className="category" to="/activities?options[]=f7207d1c-591a-4c03-a09d-56277e2a1ec6">
                     <img src={require('../assets/tile_breathing.jpg')} />
                     <h4>Ademtraining</h4>
                  </Link>
                  
                  <div className="light-blue"></div>

               </div>

            </div>

         </section>

         <section 
            id="partner-cta">

            <div className="container">

               <div className="content-wrapper">
               
                  <div className="image"> 

                     <img 
                        src={require('../assets/homepage_partner_cta.jpg')}
                        />

                  </div>

                  <div className="text">

                     <div>
                        <span>Voor werkgevers</ span>
                        <h2>You Be Well-Zakelijk.</h2>
                        <p>Stel budget beschikbaar voor jouw medewerkers om activiteiten te boeken en aan hun welzijn te werken.</p>
                     </div>

                     <Button 
                        href="/partners"
                        name="Bekijk"
                        />

                  </div>

               </div>

            </div>
         
         </section>

      </main>        
    );

}