import { useParams, useNavigate } from 'react-router';
import { useEffect, useState } from 'react';

import Form from '../components/inputs/Form';
import Input from '../components/inputs/Input';
import Rating from '../components/Rating';
import useFetch from '../hooks/useFetch';
import { useNotifications } from '../contexts/NotificationContext';

import './RatingPage.scss';

export default function RatingPage() {

    const navigate = useNavigate();
    const { path } = useParams();

    // notifications
    const { addNotification } = useNotifications();

    // request booking information
    const [booking, setBooking] = useState({});
    const request = {
        booking: {
            endpoint: `/api/v1/bookings/${path}`,
            method: 'GET'
        },
        allowRating: {
            endpoint: `/api/v1/bookings/${path}/rate`,
            method: 'GET'
        }
    }
    const [data, loading, error] = useFetch(request);

    useEffect(() => {

        let allowRating = false;
        if (data.allowRating && data.allowRating.data) {
            allowRating = data.allowRating.data.allow_rating == true;
            if (!allowRating) {
                navigate('/hub');
                addNotification('Je hebt voor deze activiteit al eens een review geschreven. Dit kan maximaal één keer.');
            }
        }

        if (data.booking && allowRating) {
            if ('error' in data.booking) {
                navigate('/hub');
            } else {
                setBooking(data.booking.data);
            }
        }

    }, [loading]);

    return (

        <main
            id="rating">

            <div className="container">

                <div className="form-container">

                    <h3>Review schrijven.</h3>
                    <p>Laat ons weten wat je vond van '{booking.title}' bij {booking.location_name}.</p>

                    <Form
                        id="rating-form"
                        action={`/api/v1/bookings/${path}/rate`}
                        submitBtnText="Opslaan"
                        submitCallback={(e, data) => {
                            if ('data' in data) {
                                addNotification('Je review is toegevoegd. Dank je voor het schrijven!');
                                navigate('/hub');
                            }
                        }}
                    >

                        <Rating
                            input
                            invert
                            />

                        <Input
                            name="title"
                            type="text"
                            placeholder="Titel van de ervaring"
                            maxLength="50"
                            required
                            />

                        <Input
                            name="message"
                            type="text"
                            rows="4"
                            placeholder="Beschrijving van de ervaring"
                            maxLength="250"
                            required
                            multiline
                            />

                    </Form>

                </div>

            </div>

        </main>

    );

}