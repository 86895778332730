import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import FavoriteButton from './inputs/FavoriteButton';
import Rating from './Rating';
import useWindowWidth from '../hooks/useWindowWidth';

import './Ad.scss';

export default function Ad(props) {

   const [options, setOptions] = useState([]);
   const [availability, setAvailability] = useState([]);
   const [currentDay, setCurrentDay] = useState(null);
   const [currentTime, setCurrentTime] = useState('-');
   const [openingHours, setOpeningHours] = useState(null);

   // window width for opening ads in new tab
   const windowWidth = useWindowWidth();

   const optionRef = useRef(null);   
   const scrollOptionRef = useRef(null);

   const dayMap = {
      'mon': 'ma',
      'tue': 'di',
      'wed': 'wo',
      'thu': 'do',
      'fri': 'vr',
      'sat': 'za',
      'sun': 'zo'
   }

   useEffect(() => {

      if (props.options && props.optionMap) {
         setOptions(props.options.split(";").map((value, key) => {

            if (!(value in props.optionMap) || props.optionMap[value] === undefined) return;

            const option = props.optionMap[value];
            const categoryDisplayValue = option.category
               .replace('Complementair', 'Complement.')
               .replace('Coaching', 'Coach');
            const displayValue = option.pretty_name
               .replace(/coach(ing)?|\/?therapeut|(consult|meting|yoga|e\smassage|balans)$/g, '')
               .replace('mindfulness', 'mindfuln.')
               .replace('Persoonlijke ontwikkeling', 'Ontwikkeling')
               .replace('Spreken in het openbaar', 'Openbaar spreken')
               .replace('Fysio-/manuele ', 'Fysio')
               .trim();

            if (displayValue) {
               return (
                  <span key={key}>
                     <span className="category">{categoryDisplayValue}</span>
                     {displayValue}
                  </span>
               );
            }
            
         }));
      }
      
   }, [props.options, props.optionMap]);

   const formatTime = (openingHours) => {
      if (!openingHours.start) {
         return `Tot ${openingHours.end}`;
      } else if (!openingHours.end) {
         return `Vanaf ${openingHours.start}`;
      } else {
         return `${openingHours.start}-${openingHours.end}`;
      }
   } 

   const grabOptions = (e) => {

      const startPosition = e.pageX;
      const startScrollPosition = scrollOptionRef.current.scrollLeft;
      const scrollOptions = (e) => {

         const currentScroll = e.pageX;
         scrollOptionRef.current.scrollLeft = startScrollPosition - (e.pageX - startPosition) / 1.2;

      }

      window.addEventListener('mousemove', scrollOptions);

      window.addEventListener('mouseup', () => {
         window.removeEventListener('mousemove', scrollOptions);
      });

   }

   useEffect(() => {

      if (props.opening_hours) {

         const availableDays = JSON.parse(props.opening_hours);
         let firstAvailableDay = null;
         setAvailability(Object.keys(dayMap).map((value, key) => {

            const dayIsAvailable = Object.keys(availableDays).includes(value);
            if (firstAvailableDay === null && dayIsAvailable) {
               firstAvailableDay = value;
            }

         }));

         setCurrentDay(firstAvailableDay);
         if (firstAvailableDay !== null) {
            setCurrentTime(formatTime(availableDays[firstAvailableDay]));
         }
         setOpeningHours(availableDays);

      }

   }, [props.opening_hours]);

   return (

      <div className={"ad" + (props.static ? " static": "")}>
         
         <Link 
            id={props.id ? "ad-" + props.id : ""}
            to={"/activities/" + props.pretty_url + (props.edit ? "/edit" : "")}
            target={windowWidth >= 1024 ? "_blank" : "_self"}
            data-activity-type={props.type}
            data-activity-city={props.city}
            data-activity-hot={props.hot == 1 ? "true" : "false"}
            data-activity-excl={props.excl == 1 ? "true" : "false"}
            onClick={(e) => {
               if (e.target.classList.contains('day') || optionRef.current.contains(e.target)) {
                  e.preventDefault();
                  return;
               }  
            }}
            >

            <div className="img-container">

               {props.images && 
                  <>
                     {props.images.length > 0 &&
                        <img src={'/images/' + props.images[0].image_path.replace(".png", ".jpg").replace(".jpg", "_thumbnail.jpg")} />}

                     {props.images.length == 0 &&
                        <div className="img-placeholder">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z"/></svg>
                        </div>
                     }
                  </>
               }
               
               <div className="tags">
                  {(props.boosted && !props.static) &&
                     <span className="hot tag">
                        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 24 24"><path d="M8.625 0c.61 7.189-5.625 9.664-5.625 15.996 0 4.301 3.069 7.972 9 8.004 5.931.032 9-4.414 9-8.956 0-4.141-2.062-8.046-5.952-10.474.924 2.607-.306 4.988-1.501 5.808.07-3.337-1.125-8.289-4.922-10.378zm4.711 13c3.755 3.989 1.449 9-1.567 9-1.835 0-2.779-1.265-2.769-2.577.019-2.433 2.737-2.435 4.336-6.423z"/></svg>
                        Geboost
                     </span>
                  }
               </div>

               <div className="text">
                  <div className="left">
                     <h3><span>{props.location_name}</span></h3>
                     <span className="city">{props.city_name}</span>
                     <Rating
                        rating={props.rating}
                        n={props.n_ratings}
                        />
                  </div>
               </div>

            </div>

            <div className="options">
               <div 
                  className="subtitle"
                  ref={optionRef}
                  onMouseDown={grabOptions}
                  >
                  
                  <div 
                     className="subtitle-options"
                     ref={scrollOptionRef}
                     >
                     {options}
                  </div>                  

               </div>

               <div className={"daytime" + (props.start_time && props.end_time ? "" : " hidden")}>

                  <div className="days">
                     {Object.keys(dayMap).map((value, key) => {
                     
                        const dayIsAvailable = openingHours !== null && Object.keys(openingHours).includes(value);

                        return (
                           <span 
                              className={"day" + (dayIsAvailable ? " active" : "") + (value === currentDay ? " selected" : "")}
                              onClick={dayIsAvailable ? (e) => { 
                                 setCurrentTime(formatTime(openingHours[value]));
                                 setCurrentDay(value);
                              } : null}
                              key={key}>
                              {dayMap[value]}
                           </span>
                        );

                     })}
                  </div>

                  <div className="time">
                     
                     {currentTime}

                  </div>

               </div>
               
            </div>

         </Link>

         {(!props.static && !props.noFavorite) &&
            <FavoriteButton
               isFavorite={props.favorite}
               activityID={props.id} />
         }


      </div>
   );

}