import { Link } from 'react-router-dom';
import Linkify from "react-linkify";
import QRCode from 'react-qr-code';
import { useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import Form from './inputs/Form';
import Input from './inputs/Input';
import { ReactComponent as UserIcon } from '../assets/faq_user.svg';
import { useNotifications } from '../contexts/NotificationContext';

import './Chat.scss';


function ChatMessages(props) {

   const [chatId, setChatId] = useState(null);
   const [currentUser, setCurrentUser] = useState(null);
   const [booking, setBooking] = useState(null);
   const [allowMessageSend, setAllowMessageSend] = useState(false);
   const [capMessages, setCapMessages] = useState(false);

   const { addNotification } = useNotifications();

   const mostRecentCreatedAt = (props.chatID in props.chatCache && props.chatCache[props.chatID].length > 0 ? props.chatCache[props.chatID].slice(-1)[0].created_at : '').replace(' ', 'T')
   const request = {
      messages: {
          endpoint: "/api/v1/chats/" + props.chatID + (mostRecentCreatedAt ? `?after=${mostRecentCreatedAt}` : ''),
          method: "GET",
      },
   }
   const [data, loading, error] = useFetch(request, chatId);

   useEffect(() => {
      if (!loading) {
         if (data.messages.data) {

            const newMessages = props.chatID in props.chatCache ? props.chatCache[props.chatID] : [{
               id: '-',
               user_id: props.currentUser.type == 'user' ? null : props.currentUser.id,
               message: `Automatisch bericht: bedankt voor je boeking van "${booking.title}" bij ${booking.location_name}. We nemen snel contact met je op voor een afspraak.`,
               created_at: booking.created_at
            }];

            // prevent duplicate messages
            for (const message of data.messages.data) {
               if (!newMessages.find(x => x.id == message.id)) {
                  newMessages.push(message);
               }
            }
            newMessages.push();
            
            if (!(props.chatID in props.chatCache) && props.status == 'cancelled') {
               newMessages.push({
                  user_id: (booking.editor_status == 'cancelled' && props.currentUser.type == 'editor' || 
                              booking.booker_status == 'cancelled' && props.currentUser.type == 'user' ? props.currentUser.id : null),
                  message: `Automatisch bericht: de boeking is door deze gebruiker geannuleerd.`
               });
            }


            props.updateChatCache(props.chatID, newMessages);

            // check if the last five messages are by the same user
            if (newMessages.length < 5) {
               setCapMessages(false);
               return;
            }
            for (const message of newMessages.slice(-5)) {
               if (message.user_id != currentUser) {
                  setCapMessages(false);
                  return;
               }
            }
            setCapMessages(true);

         }
      }
   }, [loading, data.messages]);

   useEffect(() => {
      if (props.currentUser && props.chatID && props.booking) {
         setCurrentUser(props.currentUser.id);
         setChatId(props.chatID);
         setBooking(props.booking);
      }
   }, [props.currentUser, props.chatID, props.booking]);

   const renderLink = (decoratedHref, decoratedText, key) => {
      return (
         <>
            <Link 
               to={decoratedHref} 
               target="_blank" >
                  {decoratedText}
            </Link>
            &nbsp;(externe link)
         </>
      );
    };

   const postMessage = (e) => {

      // extract original value
      const messageInput = document.getElementById('chat-message-input');
      const message = messageInput.value;
      messageInput.value = '';

      // add message locally
      const newMessages = [...props.chatCache[props.chatID]];
      newMessages.push({
         message: message,
         user_id: currentUser,
         created_at: new Date().toISOString().split('Z')[0]
      });
      props.updateChatCache(props.chatID, newMessages);

   }

   const location = useLocation();
   const [search, setSearch] = useSearchParams();
   const navigate = useNavigate();

   const currentLocalDate = new Date().toLocaleDateString();
   const yesterdayLocalDate = new Date(new Date() - 86_400_000).toLocaleDateString();

   return (
      <>
         
         <div id="chat-messages-wrapper">

            <div id="chat-messages-content" className={props.status}>
            
               {props.chatCache && chatId && chatId in props.chatCache && props.chatCache[chatId].map((message, key) => {

                  const yourMessage = currentUser == message.user_id;

                  return (
                     <div 
                        key={key}
                        className={"chat-message" + (yourMessage ? " you": "")}
                        >

                        <p>
                           <Linkify
                              componentDecorator={renderLink}
                              >
                              {message.message}
                           </Linkify>
                        </p>
                        <span>
                           {yourMessage ? "Jij " : props.otherUserFirstName + " "}
                           {message.created_at &&
                              <>
                                 •
                                 {" " + new Date(message.created_at + "Z").toLocaleString().replace(/:[0-9]{2}$/, '').replace(currentLocalDate, 'Vandaag om').replace(yesterdayLocalDate, 'Gisteren om').trim()}
                              </>
                           }
                        </span>

                     </div>
                  );

               })}

               {(props.currentUser.type === 'user' && booking && booking.editor_status === 'confirmed' && booking.user_status !== 'reviewed') &&
                  <div className="chat-message">
                     <p>We hopen dat je een fijne ervaring hebt gehad. <Link to={'/bookings/' + booking.booking_id + '/rate'}>Review je boeking</Link>.</p>
                     <span>You Be Well • Zojuist</span>
                  </div>
               }

               {(['editor', 'admin'].includes(props.currentUser.type) && booking && booking.editor_status === 'confirmed') &&
                  <div className="chat-message you">
                     <p>Je hebt de boeking bevestigd. De betaling komt eraan!</p>
                     <span>You Be Well • Zojuist</span>
                  </div>
               }

            </div>

         </div>

         <div id="chat-messages-footer">

            <div className="form-container">

               {['editor', 'admin'].includes(props.currentUser.type) && props.booking.editor_status !== 'confirmed' && search.get('code') &&

                  <Form
                     id="booking-confirm"
                     action={"/api/v1/bookings/" + props.booking.booking_id + "/:confirm"}
                     submitBtnText="Bevestigen"
                     submitCallback={() => { 
                        navigate(location.pathname, { replace: true }); 
                        props.moveChatToNewStatus(chatId, 'confirmed'); 
                        addNotification('Je hebt de boeking afgerond.');
                     }}
                     oneLineSubmitIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>}
                     oneLineSubmit
                     noLoad
                     >

                     <p>QR-code gescand. Boeking afronden?</p>

                     <input value={search.get("code")} name="code" type="hidden" />

                  </Form>

               }

               {(!['editor', 'admin'].includes(props.currentUser.type) || props.booking.editor_status === 'confirmed' || !search.get('code')) &&
                  <Form
                     id="chat-message-form"
                     action={"/api/v1/chats/" + props.chatID}
                     oneLineSubmitIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z"/></svg>}
                     oneLineSubmit
                     disabled={!allowMessageSend || capMessages}
                     submitCallback={postMessage}
                     autoComplete="off"
                     noLoad
                     >

                     <Input 
                        id="chat-message-input"
                        type="text"
                        name="message"
                        example={
                           props.status == "cancelled" ? "Deze boeking is geannuleerd." : 
                              (props.status == "confirmed" ? "Deze boeking is afgerond." : 
                                 (capMessages ? "Je kunt niet meer dan vijf berichten achter elkaar sturen." : "Je bericht"))}
                        maxLength="250"
                        rows="1"
                        onChange={(e) => { setAllowMessageSend(e.target.value.length >= 2)}}
                        readOnly={capMessages || ["cancelled", "confirmed"].includes(props.status) }
                        multiline
                        noExample
                        />
                     
                  </Form>
               }

            </div>

         </div>

      </>

   );

}

export default function Chat(props) {

   const [currentChatID, setCurrentChatID] = useState(null);
   const [chats, setChats] = useState({});
   const [bookings, setBookings] = useState({});
   const [chatCache, setChatCache] = useState({});
   const [chatMap, setChatMap] = useState({});

   const request = {
      chats: {
          endpoint: "/api/v1/chats",
          method: "GET",
      },
   }

   const [data, loading, error] = useFetch(request);

   const getBookingStatus = (booking) => {
      return [booking.booker_status, booking.editor_status].includes('cancelled') ? 'cancelled' : (booking.editor_status == 'confirmed' ? 'confirmed' : 'agreed');
   }

   const moveChatToNewStatus = (chatId, newStatus) => {

      const newChats = {};
      for (const chat of data.chats.data) {
         const booking = bookings[chat.booking_id];

         let status;
         if (chat.id == chatId) {
            status = newStatus;
         } else {
            status = getBookingStatus(booking);
         }

         if (!newChats[status]) {
            newChats[status] = [];
         }
         newChats[status].push(chat);
      }
      setChats(newChats);

   }

   const updateChatCache = (chatID, messages) => {
      setChatCache(prevState => ({
         ...prevState,
         [chatID]: messages
      }));
   }

   useEffect(() => {
      if (!loading) {
         if (data.chats.data && props.bookings) {

            const newBookings = {};
            for (const booking of props.bookings) {
               newBookings[booking.booking_id] = booking;
            }
            
            const newChats = {
               'agreed': [], 'confirmed': [], 'cancelled': []
            };

            const newChatMap = {};
            const chatIdBookings = {}
            for (const chat of data.chats.data) {
               const booking = newBookings[chat.booking_id];
               chat.booking = booking;
               const status = getBookingStatus(booking);
               chatIdBookings[chat.id] = booking;
               newChats[status].push(chat);
               newChatMap[chat.id] = chat;
            }
            setChatMap(newChatMap);
            setBookings(chatIdBookings);
            setChats(newChats);
         }
      }
   }, [loading, props.bookings]);

   const navigate = useNavigate();
   const { path } = useParams();
   const currentBooking = path !== undefined && Object.keys(bookings).length > 0 ? bookings[path] : null;

   return (

      <div id="chat">

         <div 
            id="chat-chats"
            className={path !== null && path !== undefined ? "hide-chats": ""}
            >

            {Object.keys(chats).length > 0 && ['agreed', 'confirmed', 'cancelled'].map((status, key) => {

               return (

                  <>

                     <h4
                        className={"section-header" + (status !== "agreed" ? " hide" : "")}>
                        <span>
                           {{agreed: 'Openstaand', confirmed: 'Voltooid', cancelled: 'Geannuleerd'}[status]}
                           <span
                              className="section-count"
                              >
                              {chats[status].length}
                           </span>
                        </span>
                        <svg
                           onClick={(e) => { e.target.parentElement.classList.toggle('hide'); }}
                           xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>
                     </h4>
                     

                     {chats[status].length == 0 &&
                        <p className="no-bookings-status-message">Geen boekingen met deze status.</p>}

                     <div>

                        {Object.keys(bookings).length > 0 && chats[status].map((chat, key) => {


                           if (!bookings[chat.id]) {
                              return;
                           }

                           const chatName = chat.other_user_first_name + " " + chat.other_user_last_name;

                           const localDatetime = new Date(chat.updated_at + "Z");
         
                           return(
                              <Link 
                                 to={`/hub/chats/${chat.id}`}
                                 key={key}
                                 className={"chat-thumbnail" + (path == chat.id ? " selected" : "")}
                                 onClick={() => { 
                                    chat.status = null;

                                    // reset chat input content when switching chats
                                    const chatInput = document.getElementById('chat-message-input'); 
                                    if (chatInput) {
                                       chatInput.value = '';
                                    }
                                 }}
                                 preventScrollReset={true}
                                 replace
                                 >
         
                                 <span className="chat-thumbnail-location">{bookings[chat.id].location_name}</span>
                                 <span className="chat-thumbnail-name">{chatName}</span>

                                 {status === 'agreed' &&
                                    <>
                                       <span className="chat-thumbnail-date">{localDatetime.toLocaleDateString()}<span> • </span>{localDatetime.toLocaleTimeString().replace(/:\d{2}$/, '')}</span>
                                       <span className="chat-thumbnail-activity">{bookings[chat.id].title}</span>
                                    </>
                                 }

                                 {(chat.status && chat.status.startsWith('new_') && chat.status.split('_')[1] != props.user.id && path != chat.id) &&
                                    <span className="chat-thumbnail-new">Nieuw</span>
                                 }
         
                              </Link>
                           )
         
                        })}

                     </div>

                  </>

               )

            })

            }

         </div>

         <div id="chat-messages">

            {(!path || !currentBooking) &&
            
               <div id="chat-placeholder">
                  <UserIcon />
                  <p>Klik een chat aan om een activiteit te plannen.</p>
               </div>
            }

            {(path && currentBooking) &&
            
               <>
               
                  <div id="chat-messages-header">

                     <div id="chat-messages-header-names">
                        <svg 
                           onClick={() => { navigate('/hub/chats/') }}
                           xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.068.016l-3.717 3.698 5.263 5.286h-13.614v6h13.614l-5.295 5.317 3.718 3.699 11.963-12.016z"/></svg>
                        <div>
                           <span>{chatMap[path].other_user_first_name + ' ' + chatMap[path].other_user_last_name}</span><span> • </span><span>{currentBooking.location_name}</span>
                        </div>
                     </div>

                     <div id="chat-messages-header-icons">
                        
                        <div className="chat-messages-header-panel">

                           <input 
                              id="chat-messages-header-panel-info-checkbox"
                              type="checkbox" 
                              name="chat-messages-header-checkbox"
                              />

                           <label 
                              htmlFor="chat-messages-header-panel-info-checkbox"
                              onClick={() => { if (props.user.type == 'user') document.getElementById('chat-messages-header-panel-qr-checkbox').checked = false}}
                              >
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 24c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zm1-6h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z"/></svg>
                           </label>

                           <div 
                              id="chat-messages-header-panel-info"
                              className="chat-messages-header-panel-content">

                              <ul>

                                 <li>
                                    <h4>Dienst</h4>
                                    <span>{currentBooking.title}</span>
                                 </li>

                                 <li>
                                    <h4>Omschrijving</h4>
                                    <span>{currentBooking.description}</span>
                                 </li>

                                 <li>
                                    <h4>Tarief</h4>
                                    <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 8v-2c0-1.104.896-2 2-2h20c1.104 0 2 .896 2 2v2h-24zm24 3v7c0 1.104-.896 2-2 2h-20c-1.104 0-2-.896-2-2v-7h24zm-15 5h-6v1h6v-1zm3-2h-9v1h9v-1zm9 0h-3v1h3v-1z"></path></svg>{currentBooking.price}</span>
                                 </li>

                              </ul>

                              {props.status == 'agreed' &&
                                 <Form
                                    className="cancel"
                                    submitBtnText="Annuleren"
                                    submitCallback={() => {moveChatToNewStatus(currentChatID, 'cancelled')}}
                                    action={"/api/v1/bookings/" + currentBooking.booking_id + "/:cancel"}
                                    noLoad
                                    method="get">
                                 </Form>
                              }

                           </div>

                        </div>

                        {(props.user.type == "user" && getBookingStatus(currentBooking) !== 'cancelled') &&
                           
                           <div
                              className="chat-messages-header-panel">

                              <input 
                                 id="chat-messages-header-panel-qr-checkbox"
                                 type="checkbox" 
                                 name="chat-messages-header-checkbox"
                                 />

                              <label 
                                 htmlFor="chat-messages-header-panel-qr-checkbox"
                                 onClick={() => {document.getElementById('chat-messages-header-panel-info-checkbox').checked = false}}
                                 >
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 21v3h3v-3h-1v-1h-1v1h-1zm2 1v1h-1v-1h1zm-23 2h8v-8h-8v8zm1-7h6v6h-6v-6zm20 3v-1h1v1h-1zm-19-2h4v4h-4v-4zm8-3v2h-1v-2h1zm2-8h1v1h-1v-1zm1-1h1v1h-1v-1zm1 2v-1h1v1h-1zm0-2h1v-6h-3v1h-3v1h3v1h2v3zm-1-4v-1h1v1h-1zm-7 4h-4v-4h4v4zm6 0h-1v-2h-2v-1h3v1h1v1h-1v1zm-4-6h-8v8h8v-8zm-1 7h-6v-6h6v6zm3 0h-1v-1h2v2h-1v-1zm-3 3v1h-1v-1h1zm15 6h2v3h-1v-1h-2v1h-1v-1h-1v-1h1v-1h1v1h1v-1zm-4 2h-1v1h-1v-1h-1v-1h1v-1h-2v-1h-1v1h-1v1h-2v1h-1v6h5v-1h-1v-2h-1v2h-2v-1h1v-1h-1v-1h3v-1h2v2h-1v1h1v2h1v-2h1v1h1v-1h1v-2h1v-1h-2v-1zm-1 3h-1v-1h1v1zm6-6v-2h1v2h-1zm-9 5v1h-1v-1h1zm5 3v-1h1v2h-2v-1h1zm-3-23v8h8v-8h-8zm7 7h-6v-6h6v6zm-1-1h-4v-4h4v4zm1 4h1v2h-1v1h-2v-4h1v2h1v-1zm-4 6v-3h1v3h-1zm-13-7v1h-2v1h1v1h-3v-1h1v-1h-2v1h-1v-2h6zm-1 4v-1h1v3h-1v-1h-1v1h-1v-1h-1v1h-2v-1h1v-1h4zm-4-1v1h-1v-1h1zm19-2h-1v-1h1v1zm-13 4h1v1h-1v-1zm15 2h-1v-1h1v1zm-5 1v-1h1v1h-1zm-1-1h1v-3h2v-1h1v-1h-1v-1h-2v-1h-1v1h-1v-1h-1v1h-1v-1h-1v1h-1v1h-1v-1h1v-1h-4v1h2v1h-2v1h1v2h1v-1h2v2h1v-4h1v2h3v1h-2v1h2v1zm1-5h1v1h-1v-1zm-2 1h-1v-1h1v1z"/></svg>
                              </label>

                              <div 
                                 id="chat-messages-header-panel-qr"
                                 className="chat-messages-header-panel-content">

                                 <QRCode
                                    id="qrcode"
                                    value={"https://" + window.location.hostname + window.location.pathname + "?code=" + currentBooking.code}
                                    size={200}
                                    style={{ height: "auto"}}
                                    viewBox={`0 0 200 200`}
                                    />

                                 <p>Alleen laten scannen bij de (eerste) afspraak</p>

                              </div>

                           </div>
                        }

                     </div>

                  </div>

                  <ChatMessages
                     currentUser={props.user}
                     otherUserFirstName={chatMap[path].other_user_first_name}
                     chatID={path} 
                     booking={currentBooking}
                     status={getBookingStatus(currentBooking)}
                     chatCache={chatCache}
                     updateChatCache={updateChatCache}
                     moveChatToNewStatus={moveChatToNewStatus}
                     />

               </>

            }

         </div>

      </div>

   )

}