import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Input from './inputs/Input';

import './Calculator.scss';

export default function Calculator() {

   const [employeeCount, setEmployeeCount] = useState(200);
   const [dailyAbsenceCost, setDailyAbsenceCost] = useState(250);
   const [absencePercentageBefore, setAbsencePercentageBefore] = useState(8.);
   const [absencePercentageAfter, setAbsencePercentageAfter] = useState(7.);

   const [search, setSearch] = useSearchParams();
   const [calculatorDefaults, setCalculatorDefaults] = useState({});

   const displayCalculation = !isNaN(employeeCount) && !isNaN(absencePercentageBefore) && !isNaN(absencePercentageAfter) && !isNaN(dailyAbsenceCost);
   const absenceAfterLowerThanBefore = absencePercentageBefore > absencePercentageAfter;

   useEffect(() => {

      const defaults = {};

      const searchEmployeeCount = parseInt(search.get('employees'));
      if (searchEmployeeCount && searchEmployeeCount < 100000 && searchEmployeeCount >= 1) {
         defaults['employees'] = searchEmployeeCount;
      } else {
         defaults['employees'] = 200;
      }
      setEmployeeCount(defaults['employees']);

      const searchDailyAbsenceCost = parseInt(search.get('costs'));
      if (searchDailyAbsenceCost && searchDailyAbsenceCost < 100000 && searchDailyAbsenceCost >= 0) {
         defaults['costs'] = searchDailyAbsenceCost;
      } else {
         defaults['costs'] = 250;
      }
      setDailyAbsenceCost(defaults['costs']);

      const searchAbsencePercentageBefore = Math.round((parseFloat(search.get('absenceBefore')) + Number.EPSILON) * 100) / 100;
      if (searchAbsencePercentageBefore && searchAbsencePercentageBefore <= 100 && searchAbsencePercentageBefore >= 0) {
         defaults['absenceBefore'] = searchAbsencePercentageBefore;
      } else {
         defaults['absenceBefore'] = 8.;
      }
      setAbsencePercentageBefore(defaults['absenceBefore']);

      const searchAbsencePercentageAfter = Math.round((parseFloat(search.get('absenceAfter')) + Number.EPSILON) * 100) / 100;
      if (searchAbsencePercentageAfter && searchAbsencePercentageAfter <= 100 && searchAbsencePercentageAfter >= 0) {
         defaults['absenceAfter'] = searchAbsencePercentageAfter;
      } else {
         defaults['absenceAfter'] = 7.;
      }
      setAbsencePercentageAfter(defaults['absenceAfter']);

      setCalculatorDefaults(defaults);

   }, [search]);

   return (
      
      <div id="calculator-component" className="container">

         <h3>De Well-Being Besparingstool<span className="stress">.</span></h3>
         <p>Vul de gegevens in voor jouw organisatie. Zo zie je de besparing die je realiseert met Well-Being.</p>

         <div id="calculator-input">
            
            <Input
               type="text"
               placeholder="Aantal medewerkers"
               example="Bijv. 100"
               maxLength="6"
               defaultValue={calculatorDefaults.employees}
               onInput={(e) => setEmployeeCount(parseInt(e.target.value))}
               icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.997 18h-11.995l-.002-.623c0-1.259.1-1.986 1.588-2.33 1.684-.389 3.344-.736 2.545-2.209-2.366-4.363-.674-6.838 1.866-6.838 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.811-2.214c-1.29-.298-2.49-.559-1.909-1.657 1.769-3.342.469-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.324 0 3.903 2.268 1.77 2.246 6.676h4.501l.002-.463c0-.946-.074-1.493-1.192-1.751zm-22.806 2.214h4.501c-.021-4.906 2.246-2.772 2.246-6.676 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.619 1.359-1.909 1.657-1.119.258-1.193.805-1.193 1.751l.002.463z"/></svg>}
               inputMode="numeric"
               numericOnly large />

            <Input
               type="text"
               placeholder="Kosten verzuim p/dag"
               example="Bijv. 250"
               maxLength="6"
               defaultValue={calculatorDefaults.costs}
               onInput={(e) => setDailyAbsenceCost(parseInt(e.target.value))}
               icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.728 20.517c-3.488 0-5.613-2.461-6.443-5.517h6.715l.333-2h-7.398c-.059-.664-.064-1.335-.014-2h7.746l.333-2h-7.755c.786-3.106 2.855-5.626 6.154-5.626 1.133 0 2.391.203 3.836.62l.765-3.162c-1.854-.552-3.616-.832-5.244-.832-5.959 0-9.541 4.152-10.594 9h-2.162l-.333 2h2.203c-.049.666-.051 1.334-.007 2h-2.53l-.333 2h3.145c1.033 4.848 4.664 9 11.085 9 1.5 0 3.004-.276 4.476-.821l-.883-3.23c-1.048.378-2.088.568-3.095.568z"/></svg>}
               inputMode="numeric"
               numericOnly large />


            <Input
               type="text"
               placeholder="% verzuim vóór Well-Being"
               example="Bijv. 5"
               maxLength="5"
               max="100"
               inputMode="decimal"
               defaultValue={calculatorDefaults.absenceBefore}
               onInput={(e, newValue) => { setAbsencePercentageBefore(parseFloat(newValue))}}
               icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 0c-3.371 2.866-5.485 3-9 3v11.535c0 4.603 3.203 5.804 9 9.465 5.797-3.661 9-4.862 9-9.465v-11.535c-3.516 0-5.629-.134-9-3zm0 6.5c3.036 0 5.5 2.464 5.5 5.5s-2.464 5.5-5.5 5.5-5.5-2.464-5.5-5.5 2.464-5.5 5.5-5.5zm1 2.5v2h2v2h-2v2h-2v-2h-2v-2h2v-2h2z"/></svg>}
               decimalOnly large />

            <Input
               type="text"
               placeholder="% verzuim na Well-Being"
               example="Bijv. 3"
               maxLength="5"
               max="100"
               inputMode="decimal"
               defaultValue={calculatorDefaults.absenceAfter}
               onInput={(e, newValue) => {setAbsencePercentageAfter(parseFloat(newValue));}}
               icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 0c-3.371 2.866-5.485 3-9 3v11.535c0 4.603 3.203 5.804 9 9.465 5.797-3.661 9-4.862 9-9.465v-11.535c-3.516 0-5.629-.134-9-3zm0 6.5c3.036 0 5.5 2.464 5.5 5.5s-2.464 5.5-5.5 5.5-5.5-2.464-5.5-5.5 2.464-5.5 5.5-5.5zm1 2.5v2h2v2h-2v2h-2v-2h-2v-2h2v-2h2z"/></svg>}
               decimalOnly large />

            {(!displayCalculation || !absenceAfterLowerThanBefore) &&
               <div className="warning">
                  {!displayCalculation &&
                     <p>Vul alle velden in.</p>
                  }
                  {displayCalculation && !absenceAfterLowerThanBefore &&
                     <p>Vul een hoger verzuimpercentage in vóór Well-Begin dan na Well-Being.</p>
                  }
               </div>}

         </div>

         <div id="calculator-output">

            <div className="calculator-output-section">
               <h4>Zonder Well-Being<span className="stress">.</span></h4>
               <p>Dit zijn de huidige kosten door afwezigheid van werknemers.</p>
               <div className="outcome before">
                  <span>
                     {(displayCalculation && absenceAfterLowerThanBefore) &&
                        <>&euro;&nbsp;{parseInt(employeeCount * absencePercentageBefore / 100 * dailyAbsenceCost * 228).toLocaleString()}</>}
                     {(!displayCalculation || !absenceAfterLowerThanBefore) &&
                        <>&euro;&nbsp; -</>}
                  </span>
                  <h4>Kosten door verzuim</h4>
               </div>
               <h4>Met Well-Being<span className="stress">.</span></h4>
               <p>Dit is het bedrag dat je bespaart door het verzuim te verlagen.</p>
               <ul>
                  <li>
                     <span>{displayCalculation && absenceAfterLowerThanBefore ? (employeeCount * ((absencePercentageBefore - absencePercentageAfter) / 100)).toFixed(2) : "-"}</span>
                     <h4>Minder FTE in verzuim</h4>
                  </li>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
                  <li>
                     <span>&euro;&nbsp;{!isNaN(dailyAbsenceCost) ? dailyAbsenceCost : "0"},-</span>
                     <h4>Kosten verzuim per dag</h4>
                  </li>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
                  <li>
                     <span>228</span>
                     <h4>Werkdagen per jaar</h4>
                  </li>
               </ul>
               <span className="equals">=</span>
               <div className="outcome">
                  <span>
                     {(displayCalculation && absenceAfterLowerThanBefore) &&
                        <>&euro;&nbsp;{parseInt(employeeCount * (absencePercentageBefore - absencePercentageAfter) / 100 * dailyAbsenceCost * 228).toLocaleString()}</>}
                     {(!displayCalculation || !absenceAfterLowerThanBefore) &&
                        <>&euro;&nbsp; -</>}
                  </span>
                  <h4>Besparing door Well-Being</h4>
               </div>
            </div>
            
         </div>

      </div>

   );

}