import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  
  const [prevPath, setPrevPath] = useState(null);
  const { pathname } = useLocation();

  useEffect(() => {
    const newMainPath = pathname.substring(0, pathname.lastIndexOf('/'));
    if (prevPath === null || (newMainPath !== prevPath && !prevPath.startsWith("/hub/chats") || !newMainPath.startsWith("/hub/chats"))) {
      window.scrollTo(0, 0);
    }
    setPrevPath(newMainPath);
  }, [pathname]);

  return null;

}
