import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
import useTokenStorage from "../../hooks/useTokenStorage";

export const ProtectedRoute = (props) => {

    const [getTokens, saveToken, removeToken, clearTokens, getToken, getTokenExpriy, refreshToken, newTokenObtained] = useTokenStorage();
    const navigate = useNavigate();
    const location = useLocation();
    const { user, login } = useAuth();

    const [refreshStatus, setRefreshStatus] = useState('pending');

    useEffect(() => {
      
        const access_token = getToken('access_token');
        const access_token_expiry =  getTokenExpriy('access_token');
        const refresh_token = getToken('refresh_token');
        const refresh_token_expiry = getTokenExpriy('refresh_token');

        const queryPath = window.location.pathname;
        if (!access_token || !refresh_token) {
          navigate('/login?auto-redirect=true&redirect=' + encodeURIComponent(queryPath), { replace: true });
        } else if (Date.now() > refresh_token_expiry) {
          navigate('/login?forced=true&redirect=' + encodeURIComponent(queryPath), { replace: true });
        } else if (Date.now() > access_token_expiry && Date.now() <= refresh_token_expiry) {
          refreshToken().then((data) => {
            
            if ('error' in data) {
              navigate('/login?forced=true', { replace: true });
              clearTokens();
              return;
            } else {
              login(data.data);
            }
            setRefreshStatus('done');
          });
        } else {
          setRefreshStatus('done');        
        }
    }, [getTokens, getToken, getTokenExpriy, location.pathname]); // Monitor changes in the tokens state

    if (refreshStatus === 'pending') {
      return <div style={{width: '100vw', height: '100vh', display: 'block'}}></div>;
    }

    if (user) {
      if (user.first_name === null && location.pathname != '/welcome') {
        navigate('/welcome');
        return;
      } else if (user.first_name !== null && location.pathname == '/welcome') {
        navigate('/hub');
        return;
      }
    }
  
    return props.children;

};