import { HashLink } from 'react-router-hash-link';
import L from "leaflet";
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import { useEffect, useState } from 'react';

import 'leaflet/dist/leaflet.css';

import './MapTile.scss';

export default function MapTile(props) {

   const [lat, setLat] = useState(null);
   const [lon, setLon] = useState(null);
   const [zoom, setZoom] = useState(null);
   const [markers, setMarkers] = useState([]);

   const markerIcon = L.divIcon({
      html: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg> \
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>',
      className: "svg-icon",
      iconSize: [24, 24],
      iconAnchor: [12, 24],  
   });

   useEffect(() => {

      if (props.lat) setLat(props.lat);
      if (props.lon) setLon(props.lon);
      if (props.zoom) setZoom(props.zoom);
      if (props.markers) setMarkers(props.markers);

   }, [props.lat, props.lon, props.zoom, props.markers]);

   const RecenterAutomatically = ({lat, lon, zoom}) => {
      const map = useMap();
       useEffect(() => {
         if (lat && lon && zoom) {
            map.setView([lat, lon], zoom);
         }
       }, [lat, lon, zoom]);
       return null;
     }

   return (
      <div 
         id="maptile-wrapper"
         className={props.noDrag ? "no-drag" : ""}
         >

         <div id="maptile">

            <MapContainer 
               center={[lat, lon]} 
               zoom={zoom} 
               scrollWheelZoom={false}
               >
               <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
               />

               <RecenterAutomatically
                  lat={lat} 
                  lon={lon}
                  zoom={zoom}
                  />

               {markers.map((marker, key) => {

                  return(
                     <Marker 
                        position={[marker.lat, marker.lon]}
                        icon={markerIcon} 
                     >

                        {marker.popup &&
                           <Popup>
                              <div className="popup-left">
                                 <span>{marker.popup.name.length <= 15 ? marker.popup.name : marker.popup.name.substring(0, 12) + '...'}</span>
                                 <span>{marker.popup.city.length <= 15 ? marker.popup.city : marker.popup.city.substring(0, 12) + '...'}</span>
                                 <HashLink 
                                    to={marker.popup.href}
                                    onClick={(() => {
                                       const target = document.querySelector(marker.popup.href);
                                       if (target) {
                                          target.classList.add('target');
                                          setTimeout(() => {
                                             target.classList.remove('target');
                                          }, 4000);
                                       }
                                    })}
                                    >
                                    Bekijken
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>
                                 </HashLink>
                              </div>
                              <div className="popup-right">
                                 <img src={'/images/' + marker.popup.img} />
                              </div>
                           </Popup>
                        }

                     </Marker>
                  );

               })}
            </MapContainer>

         </div>

         {props.children}

      </div>
   )

}