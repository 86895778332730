import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch';

import { ReactComponent as EditorIcon } from '../assets/faq_editor.svg';
import { ReactComponent as EmployerIcon } from '../assets/faq_employer.svg';
import { ReactComponent as UserIcon } from '../assets/faq_user.svg';

import './DocsPage.scss';

function QuestionAndAnswer (props) {

   const [shown, setShown] = useState(false);

   useEffect(() => {
      setShown(false);
   }, [props.question]);

   return (

      <div className={"question-and-answer" + (shown ? " show": "")}>

         <h4 
            onClick={() => { setShown(!shown); }}>
               {props.question}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>
         </h4>
         <p>{props.answer}</p>

      </div>

   );

}

function FAQ (props) {

   const [category, setCategory] = useState("user");
   const [shownQuestionsAndAnswers, setShownQuestionsAndAnswers] = useState([]);

   useEffect(() => {

      if (props.questionsAndAnswers && Object.keys(props.questionsAndAnswers).length && category) {
         const newQuestionsAndAnswers = props.questionsAndAnswers[category];
         setShownQuestionsAndAnswers(newQuestionsAndAnswers);
      }

   }, [props.questionsAndAnswers, category]);

   return (

      <>
      
         <div id="faq-switcher">

            <div 
               className={"switch" + (category == "user" ? " active" : "")}
               onClick={() => {
                  setCategory("user")
               }}
            >
               <UserIcon />
               <h3>Gebruiker</h3>
            </div>

            <div 
               className={"switch" + (category == "editor" ? " active" : "")}
               onClick={() => {
                  setCategory("editor")
               }}
            >
               <EditorIcon />
               <h3>Aanbieder</h3>
            </div>

            <div 
               className={"switch" + (category == "employer" ? " active" : "")}
               onClick={() => {
                  setCategory("employer")
               }}
               >
               <EmployerIcon />
               <h3>Werkgever</h3>
            </div>

         </div>

         <div id="questions-and-answers">

            {shownQuestionsAndAnswers.map((value, key) => {

               return (
                  <QuestionAndAnswer
                     key={key + category}
                     question={value.question}
                     answer={value.answer}
                      />
               )

            })}

         </div>
      
      </>

   );

}


export default function InsightsOverviewPage() {

   const [articles, setArticles] = useState([
      {title: 'Mindfulness', shortTitle: 'Mindfulness verlicht stress door je terug te brengen naar het hier en nu.', show: true, img: require('../assets/insights_mindfulness.jpg'), href: '/insights/mindfulness'},
      {title: 'Mindfulness', shortTitle: 'Mindfulness verlicht stress door je terug te brengen naar het hier en nu.', show: true, img: require('../assets/insights_mindfulness.jpg'), href: '/insights/mindfulness'},
      {title: 'Mindfulness', shortTitle: 'Mindfulness verlicht stress door je terug te brengen naar het hier en nu.', show: true, img: require('../assets/insights_mindfulness.jpg'), href: '/insights/mindfulness'},
      {title: 'Mindfulness', shortTitle: 'Mindfulness verlicht stress door je terug te brengen naar het hier en nu.', show: true, img: require('../assets/insights_mindfulness.jpg'), href: '/insights/mindfulness'},
      {title: 'Mindfulness', shortTitle: 'Mindfulness verlicht stress door je terug te brengen naar het hier en nu.', show: true, img: require('../assets/insights_mindfulness.jpg'), href: '/insights/mindfulness'},
      {title: 'Mindfulness', shortTitle: 'Mindfulness verlicht stress door je terug te brengen naar het hier en nu.', show: true, img: require('../assets/insights_mindfulness.jpg'), href: '/insights/mindfulness'},
      {title: 'Mindfulness', shortTitle: 'Mindfulness verlicht stress door je terug te brengen naar het hier en nu.', show: true, img: require('../assets/insights_mindfulness.jpg'), href: '/insights/mindfulness'},
      {title: 'Mindfulness', shortTitle: 'Mindfulness verlicht stress door je terug te brengen naar het hier en nu.', show: true, img: require('../assets/insights_mindfulness.jpg'), href: '/insights/mindfulness'},
   ]);
   const [nArticlesShown, setNArticlesShown] = useState(1);
   
   const request = {
      activities: {
          endpoint: "/api/v1/activities?n=3",
          method: "GET",
      },
      activitiesForFilters: {
          endpoint: "/api/v1/activities",
          method: "GET",
      }
   }
   const [data, loading, error] = useFetch(request);

   // FAQ
   const [faq, setFaq] = useState({});
   useEffect(() => {

      const faqJSON = require("../assets/faq.json");
      setFaq(faqJSON);

   }, []);

   const { path } = useParams();


   return (

      <main id="learn-overview">

         <section id="hero">

            <div className="container">
               <h2>You Be Well Academy<span className="stress">.</span></h2>
               <p>Lees hier over de werking van ons platform.</p>
            </div>

         </section>

         <section id="learn-container" className="container"> 

            <div id="learn-content">

               {/* <section id="learn-activities">
                  <h2 id="learn-anchor-activities">Activiteiten die je well-being verhogen.</h2>
                  <p>Lees hier over ons aanbod en vind informatie over de activiteiten die bij jou passen.</p>

                  {articles.map((article, key) => {
                     return (
                        <Link 
                           to={article.href}
                           key={key} className="search-result">
                              <img src={article.img} />
                              <h4>{article.title}<span className="stress">.</span></h4>
                              <p>{article.shortTitle}</p>
                        </Link>
                     );
                  })}
               </section> */}
               
               {path === 'faq' &&
                  <section id="learn-faq">
                     <h2 id="learn-anchor-faq">FAQ.</h2>
                     <p>Vind je antwoord in onze veelgestelde vragen.</p>

                     <FAQ 
                        questionsAndAnswers={faq}
                        />

                  </section>
               }

               {path === 'privacy' &&

                  <section id="learn-privacy">

                  <h2>Privacyverklaring</h2>
                  <p>You Be Well respecteert de privacy van haar gebruikers platform, in het bijzonder hun rechten met betrekking tot de geautomatiseerde verwerking van persoonsgegevens. Vanwege volledige transparantie hebben wij daarom een beleid geformuleerd en geïmplementeerd met betrekking tot deze verwerkingen zelf, het doel ervan alsook de mogelijkheden voor betrokkenen om hun rechten zo goed mogelijk te kunnen uitoefenen. Voor alle aanvullende informatie over de bescherming van persoonsgegevens kunt u terecht op de website van de Autoriteit persoonsgegevens. De aan u beschikbaar gestelde privacy policy is de enige versie die van toepassing is, totdat een nieuwe versie de huidige versie vervangt.</p>
                  <h3>Artikel 1 - Wettelijke bepalingen</h3>
                  <p>Verantwoordelijke voor de verwerking van persoonsgegevens (Hierna ook: “De beheerder”): You Be Well, gevestigd te Prof. Snijdersstraat 8, 2628RA Delft, Kvk-nummer: 77425812.</p>
                  <h3>Artikel 2 – De verwerking van persoonsgegevens</h3>
                  <p>Uw persoonsgegevens worden verzameld door You Be Well. Onder persoonsgegevens worden verstaan: alle informatie over een geïdentificeerde of identificeerbare natuurlijke persoon; als identificeerbaar wordt beschouwd een natuurlijke persoon die direct of indirect kan worden geïdentificeerd, met name aan de hand van een identificator zoals een naam, een identificatienummer, locatiegegevens, een online identificator of een of meer elementen die kenmerkend zijn voor de fysieke, fysiologische, genetische, psychische, economische, culturele of sociale identiteit. Wij verwerken de volgende categorieën persoonsgegevens van u:
                  <ul>
                     <li>Geslacht</li>
                     <li>Volledige naam</li>
                     <li>E-mailadres(sen)</li>
                     <li>IP-adres (bij het accepteren van analytische cookies)</li>
                  </ul>
                  <p>Buiten You Be Well zijn de volgende partijen zijn mogelijke verwerkers van deze gegevens:</p>
                  <ul>
                     <li>Leadinfo B.V. (<Link to="https://leadinfo.com/" target="_blank">leadinfo.com</Link>)</li>
                     <li>Google Analytics (<Link to="https://policies.google.com/technologies/partner-sites" target="_blank">Privacybeleid Google</Link>)</li>
                     <li>Hubspot (<Link to="https://hubspot.com/" target="_blank">hubspot.com</Link>)</li>
                  </ul>
                  </p>
                  <h3>Artikel 3 - Doel van de verwerking</h3>
                  <p>We verzamelen uw persoonsgegevens niet zomaar. Uw persoonsgegevens worden verwerkt voor:
                  <ul>
                     <li>Het doen van mededelingen via de post, mails en/of nieuwsbrieven</li>
                  </ul>
                  </p>
                  <h3>Artikel 4 - Registratie persoonsgegevens</h3>
                  <p>Uw persoonsgegevens worden geregistreerd in een (elektronisch) register.</p>
                  <h3>Artikel 5 - Uw rechten met betrekking tot uw gegevens</h3>
                  <p>Op grond van artikel 13 lid 2 sub b AVG heeft u recht op inzage van en rectificatie of wissing van uw persoonsgegevens of beperking van de u betreffende verwerking, alsmede het recht tegen de verwerking bezwaar te maken en het recht op gegevensoverdraagbaarheid. U kunt deze rechten uitoefenen door contact met ons op te nemen via hello@youbewell.nl. Ieder verzoek daartoe dient te worden vergezeld van een kopie van een geldig identiteitsbewijs, waarop u uw handtekening heeft gezet en onder vermelding van het adres waarop er met u contact kan worden opgenomen. Binnen 1 maand na het ingediende verzoek, krijgt u antwoord op uw verzoek. Afhankelijk van de complexiteit van de verzoeken en het aantal van de verzoeken kan deze termijn indien nodig met 2 maanden worden verlengd.</p>
                  <h3>Artikel 6 – Wettelijke verplichtingen</h3>
                  <p>In geval van schending van enige wet- of regelgeving, waarvan u wordt verdacht en waarvoor de autoriteiten persoonsgegevens nodig hebben die de beheerder heeft verzameld, worden deze aan hen verstrekt na een uitdrukkelijk en gemotiveerd verzoek van die autoriteiten, waarna deze persoonsgegevens mitsdien niet meer onder de bescherming van de bepalingen van deze privacyverklaring vallen.</p>
                  <h3>Artikel 7 - Commerciële aanbiedingen</h3>
                  <p>U kunt commerciële aanbiedingen krijgen van de beheerder, wanneer u daar toestemming voor heeft gegeven. Indien u deze niet (meer) wenst te ontvangen, stuurt u een mail naar het volgende adres: hello@youbewell.nl. Uw gegevens kunnen worden niet gebruikt door partners van de verantwoordelijke voor commerciële doeleinden.</p>
                  <h3>Artikel 8 - Bewaartermijn gegevens</h3>
                  <p>De door de beheerder verzamelde gegevens worden gebruikt en bewaard voor de duur zoals deze bij wet is bepaald.</p>
                  <h3>Artikel 9 - Toepasselijk recht</h3>
                  <p>Op deze voorwaarden is Nederlands Recht van toepassing. De rechtbank van de vestigingsplaats van de beheerder is exclusief bevoegd bij eventuele geschillen omtrent deze voorwaarden, behoudens wanneer hierop een wettelijke uitzondering van toepassing is.</p>
                  <h3>Artikel 10 - Contact</h3>
                  <p>Voor verzoeken, vragen, productinformatie of meer informatie, stuur een mail naar hello@youbewell.nl.</p>


                  </section>

               }

               {path === 'content' &&

                  <section id="learn-content">

                     <h2>Contentbeleid Aanbieders</h2>
                     <p>Wij geloven dat het delen van aantrekkelijke content niet alleen jouw profiel versterkt, maar ook bijdraagt aan een betere ervaring voor onze gebruikers. Hier zijn enkele richtlijnen en tips om je te helpen bij het creëren van aantrekkelijke content die jouw diensten optimaal presenteert en je profiel nog aantrekkelijker maakt voor potentiële klanten.</p>

                     <h3>Afbeeldingsrichtlijnen:</h3>
                     <ul>
                        <li>Houd afbeeldingen onder 2MB en maximaliseer de afmetingen tot 2000 x 2000 voor optimale weergave.</li>
                        <li>Gebruik scherpe afbeeldingen met een goede resolutie om wazigheid te voorkomen.</li>
                        <li>Tip: Gebruik een fotobewerkingsprogramma, zoals Canva, om eenvoudig afbeeldingen te bewerken en te optimaliseren voor onze richtlijnen.</li>
                        <li>Plaats de afbeelding die jouw dienst het beste vertegenwoordigt als eerste foto op je profiel.</li>
                        <li>Voorbeelden van geschikte afbeeldingen zijn: actiefoto's van jou tijdens het uitvoeren van de dienst, afbeeldingen van je praktijkruimte, ontspannende klanten en duidelijke illustraties van je diensten, zoals reflexpunten bij voetreflexologie.</li>
                        <li>Voeg ook een foto van jezelf toe, maar plaats deze niet als eerste foto.</li>
                     </ul>
                     <h3>Tips voor promotie:</h3>
                     <ul>
                        <li>Laat actiefoto's zien van jou in actie, ontspannende klanten, sfeervolle ruimtes en tevreden cliënten.</li>
                        <li>Gebruik afbeeldingen om de sfeer en ervaring van jouw diensten over te brengen.</li>
                     </ul>
                     <h3>Over de activiteit/omschrijving:</h3>
                     <ul>
                        <li>Beschrijf je activiteit kort en krachtig, alsof je direct communiceert met potentiële klanten.</li>
                        <li>Identificeer de doelgroep en hun behoeften, zoals stressvermindering, gezondheid en welzijn.</li>
                        <li>Benadruk de voordelen en resultaten die klanten kunnen verwachten.</li>
                        <li>Wees specifiek, gebruik eenvoudige taal en benadruk unieke eigenschappen van jouw diensten.</li>
                     </ul>
                     <p>Door deze richtlijnen te volgen, zal je profiel op ons platform nog aantrekkelijker worden en zul je meer boekingen genereren.</p>

                  </section>

               }

               {path === "terms-and-conditions" &&

                  <section id="learn-terms-and-conditions">


<h3>Artikel 1 - Definities</h3>
<p>You Be Well, gevestigd te Delft , KvK-nummer 77425812, wordt in deze algemene voorwaarden aangeduid als opdrachtnemer. De wederpartij van opdrachtnemer wordt in deze algemene voorwaarden aangeduid als opdracht-gever. Partijen zijn opdrachtnemer en opdrachtgever samen. Met de overeenkomst wordt bedoeld de overeenkomst tussen partijen. Opdrachtnemer stelt in deze alleen een platform beschikbaar waar boekingen kunnen worden geplaatst voor wellbeing activiteiten van dienstverleners.</p>

<h3>Artikel 2 - Toepasselijkheid algemene voorwaarden</h3>
<p>Deze voorwaarden zijn van toepassing op alle transacties over het platform van opdrachtnemer. Afwijken van deze voorwaarden kan alleen als dat uitdrukkelijk én schriftelijk door partijen is overeengekomen. De overeenkomst bevat voor opdrachtnemer steeds inspanningsverplichtingen, geen resultaats-verplichtingen.</p>

<h3>Artikel 3 - Betaling</h3>
<p>Declaraties moeten onmiddelijk worden betaald, tenzij partijen hierover andere afspraken hebben gemaakt of op de declaratie een andere betaaltermijn vermeld is. Betaalt opdrachtgever niet binnen de overeengekomen termijn, dan is hij van rechtswege, zonder dat daarvoor enige aanmaning nodig is, in verzuim. Vanaf dat moment is opdrachtnemer gerechtigd de verplichtingen op te schorten totdat opdrachtgever aan zijn betalingsverplichting heeft voldaan. Blijft opdrachtgever in gebreke, dan zal opdrachtnemer tot invordering overgaan. De kosten met betrekking tot die invordering komen voor rekening van de opdrachtgever. Wanneer opdracht-gever in verzuim is, is hij wettelijke rente, buitengerechtelijke incassokosten en overige schade verschuldigd aan opdrachtnemer. De incassokosten worden berekend aan de hand van het Besluit vergoeding voor buitengerechtelijke incassokosten. In geval van liquidatie, faillissement, beslag of surseance van betaling van de opdrachtgever zijn de vorderingen van opdrachtnemer op de opdrachtgever onmiddellijk opeisbaar. Weigert opdrachtgever zijn medewerking aan de uitvoering van de opdracht door opdrachtnemer, dan is hij nog steeds verplicht de afgesproken prijs aan opdrachtnemer te betalen.</p>

<h3>Artikel 4 - Aanbiedingen en offertes</h3>
<p>Aanbiedingen zijn vrijblijvend, tenzij in het aanbod een andere termijn van aanvaarding is genoemd. Offertes zijn indicatief en geven koper bij overschrijding daarvan geen recht op ontbinding of schadevergoeding, tenzij partijen uitdrukkelijk én schriftelijk anders zijn overeengekomen.</p>

<h3>Artikel 5 - Prijzen</h3>
<p>De op aanbiedingen, offertes en facturen genoemde prijzen zijn inclusief de verschuldigde btw, tenzij anders overeengekomen.</p>

<h3>Artikel 6 - Prijsindexering</h3>
<p>Opdrachtnemer is gerechtigd om zijn tarieven ten aller tijden te verhogen.</p>

<h3>Artikel 7 - Informatieverstrekking door opdrachtgever</h3>
<p>Opdrachtgever stelt alle informatie die voor de uitvoering van de opdracht relevant is beschik-baar voor opdrachtnemer. Opdrachtgever is verplicht alle gegevens en bescheiden, die opdrachtnemer meent nodig te hebben voor het correct uitvoeren van de opdracht, tijdig en in de gewenste vorm en op de gewenste wijze ter beschikking te stellen. Opdrachtgever staat in voor de juistheid, volledigheid en betrouwbaarheid van de aan opdrachtnemer ter beschikking gestelde gegevens en bescheiden, ook indien deze van derden afkomstig zijn, voor zover uit de aard van de opdracht niet anders voortvloeit. Indien en voor zover opdrachtgever dit verzoekt, retourneert opdrachtnemer de betreffende bescheiden. Stelt opdrachtgever niet, niet tijdig of niet behoorlijk de door opdrachtnemer verlangde gegevens en bescheiden beschikbaar en loopt de uitvoering van de opdracht hierdoor vertraging op, dan komen de daaruit voortvloeiende extra kosten en extra honoraria voor rekening van opdracht-gever.</p>

<h3>Artikel 8 - Intrekking opdracht</h3>
<p>Het staat opdrachtgever vrij om de opdracht aan opdrachtnemer tot 48 uur voor de start van de dienst te annuleren. Wanneer opdrachtgever de opdracht te laat intrekt, is opdrachtgever verplicht het verschuldigde loon en de gemaakte onkosten van opdrachtnemer te betalen.</p>

<h3>Artikel 9 - Uitvoering van de overeenkomst</h3>
<p>Opdrachtnemer heeft het recht om werkzaamheden te laten verrichten door dienstverleners zoals weergegeven op het platform van opdrachtnemer.</p>

<h3>Artikel 10 - Contractduur opdracht</h3>
<p>De overeenkomst tussen opdrachtgever en opdrachtnemer wordt aangegaan o.b.v. de aard van de overeenkomst.</p>

<h3>Artikel 11 - Wijziging van de overeenkomst</h3>
<p>Indien tijdens de uitvoering van de overeenkomst blijkt dat het voor een behoorlijke uitvoering van de opdracht noodzakelijk is om de te verrichten werkzaamheden te wijzigen of aan te vullen, passen partijen tijdig en in onderling overleg de overeenkomst dienovereenkomstig aan. Indien partijen overeenkomen dat de overeenkomst wordt gewijzigd of aangevuld, kan het tijdstip van voltooiing van de uitvoering daardoor worden beïnvloed. Opdrachtnemer stelt opdracht-gever hiervan zo spoedig mogelijk op de hoogte. Dit mag ook worden gedaan door dienstverleners zoals weergegeven op het platform van opdrachtnemer.</p>

<h3>Artikel 12 - Overmacht</h3>
<p>Indien zich een situatie voordoet als gevolg waarvan opdrachtnemer niet aan zijn verplichtingen jegens opdrachtgever kan voldoen, dan worden die verplichtingen opgeschort zolang opdrachtnemer niet aan zijn verplichtingen kan voldoen. Opdrachtnemer is in het geval als bedoeld in het tweede lid van dit artikel niet gehouden tot vergoeding van enige schade, ook niet als opdrachtnemer als gevolg van de overmachtstoestand enig voordeel geniet.</p>

<h3>Artikel 13 - Overdracht van rechten</h3>
<p>Rechten van een partij uit deze overeenkomst kunnen niet worden overgedragen zonder de voorafgaande schriftelijke instemming van de andere partij. Deze bepaling geldt als een beding met goederenrechtelijke werking zoals bedoeld in artikel 3:83, tweede lid, Burgerlijk Wetboek.</p>

<h3>Artikel 14 - Garantie</h3>
<p>Partijen zijn een overeenkomst met een dienstverlenend karakter aangegaan, welke voor opdrachtnemer enkel een inspanningsverplichting bevat en dus geen resultaatsverplichting.</p>

<h3>Artikel 15 - Verzekering</h3>
<p>Zowel opdrachtnemer als opdrachtgever hoeven voor deze overeenkomst geen verdere verzekeringen af te sluiten.</p>

<h3>Artikel 16 - Hoofdelijke aansprakelijkheid</h3>
<p>Als de opdracht wordt verstrekt door meer dan één opdrachtgever, dan zijn alle opdrachtgevers hoofdelijk aansprakelijk voor de nakoming van alle verplichtingen die voortvloeien uit deze algemene voorwaarden en de onderhavige overeenkomst.</p>

<h3>Artikel 17 - Aansprakelijkheid dienstverlener</h3>
<p>De opdrachtgever vrijwaart opdrachtnemer tegen alle aanspraken van dienstverleners, die verband houden met de door de opdrachtnemer geleverde diensten en diensten van derden zoals weergegeven op het platform van opdrachtnemer.</p>

<h3>Artikel 18 - Aansprakelijkheid opdrachtgever</h3>
<p>Ingeval een opdracht wordt verstrekt door meer dan één persoon, is ieder van hen hoofdelijk aansprakelijk voor de bedragen die uit hoofde van die opdracht aan opdrachtnemer verschuldigd zijn.</p>

<h3>Artikel 19 - Klachtplicht</h3>
<p>Opdrachtgever is verplicht klachten over de verrichte werkzaamheden direct schriftelijk te melden aan opdrachtnemer. De klacht bevat een zo gedetailleerd mogelijke omschrijving van de tekort-koming, zodat opdrachtnemer in staat is hierop adequaat te reageren.</p>

<h3>Artikel 20 - Intellectueel eigendom</h3>
<p>Tenzij partijen schriftelijk anders zijn overeengekomen, behoudt opdrachtnemer alle intellectuele absolute rechten (waaronder auteursrecht, octrooirecht, merkenrecht, tekeningen- en modellen-recht, etc.) op alle ontwerpen, tekeningen, geschriften, dragers met gegevens of andere informatie, offertes, afbeeldingen, schetsen, modellen, maquettes, etc. De genoemde intellectuele absolute rechten mogen niet zonder schriftelijke toestemming van opdrachtnemer worden gekopieerd.</p>

<h3>Artikel 21 - Wijziging algemene voorwaarden</h3>
<p>Opdrachtnemer is gerechtigd deze algemene voorwaarden te wijzigen of aan te vullen. Wijzigingen van ondergeschikt belang kunnen te allen tijde worden doorgevoerd. Grote inhoudelijke wijzigingen zal opdrachtnemer zoveel mogelijk proberen vooraf met de "klant" bespreken. Consumenten zijn gerechtigd bij een wezenlijke wijziging van de algemene voorwaarden de overeenkomst op te zeggen.</p>

<h3>Artikel 22 - Toepasselijk recht en bevoegde rechter</h3>
<p>Op iedere overeenkomst tussen partijen is uitsluitend het Nederlands recht van toepassing. Wanneer in een gerechtelijke procedure één of meerdere bepalingen van deze algemene voorwaarden als onredelijk bezwarend worden aangemerkt, dan blijven de overige bepalingen onverminderd van kracht. De Nederlandse rechter in het arrondissement waar opdrachtnemer is gevestigd/ praktijk houdt/kantoor houdt is exclusief bevoegd om kennis te nemen van eventuele geschillen tussen partijen, tenzij de wet dwingend anders voorschrijft.</p>

                  </section>

               }

            </div>

            <div id="learn-nav">

               {/* <ul>
                  <li><Link to="#learn-anchor-activities">Activiteiten</Link></li>
                  <li><Link to="#learn-anchor-faq">FAQ</Link></li>
               </ul> */}

            </div>

         </section>

      </main>

   )

}