import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Form from "../components/inputs/Form";
import Input from "../components/inputs/Input";

import { AuthContext } from "../contexts/AuthContext";
import ConsentInput from "../components/inputs/ConsentInput";
import { ReactComponent as UserIcon } from '../assets/faq_user.svg';
import { ReactComponent as EditorIcon } from '../assets/faq_editor.svg';
import { useContext, useEffect, useState } from "react";
import { useNotifications } from "../contexts/NotificationContext";

import './LoginPage.scss';

export default function LoginPage() {

   const { login, logout } = useContext(AuthContext);
   const { addNotification } = useNotifications();

   const location = useLocation();
   const [search, setSearch] = useSearchParams();
   const navigate = useNavigate();
   
   const [passwordRequested, setPasswordRequested] = useState(false);
   const [passwordResetDone, setPasswordResetDone] = useState(false);

   const onLogin = (e, data) => {

      if ('error' in data) {
         const loginInput = document.getElementById('login-password');
         loginInput.classList.add('error');
         if (loginInput.parentElement.nextElementSibling == null || !loginInput.parentElement.nextElementSibling.classList.contains('error-message')) {
            if (data.error == "Invalid credentials") {
               const errorMessage = document.createElement('p');
               errorMessage.classList.add('error-message');
               errorMessage.textContent = "Verkeerd e-mailadres/wachtwoord.";
               loginInput.parentElement.insertAdjacentElement("afterend", errorMessage);
            }
         }
         return;
      }

      if ('data' in data) {

         new Promise((resolve, reject) => {
            try {
               login(data.data);
               resolve();
            } catch (error) {
               reject();
            }
         }).then(() => {
            if (search.get('redirect')) {
               navigate(search.get('redirect'));
            } else {
               if (data.data.user_type && data.data.user_type == 'user') {
                  navigate('/activities');
               } else {
                  navigate('/hub');
               }
            }
            addNotification('Je bent nu ingelogd. Welkom!');
         });

      }

   }

   useEffect(() => {

      if (location.pathname == '/logout') {
         logout();
         navigate('/login');
         return;
      }

      const form = document.getElementById('login-form');
      if (form) {
         const inputs = form.getElementsByTagName('input');
         for (const input of inputs) {
            input.addEventListener('keydown', (e) => {
               if (e.key == 'Enter') {
                  e.preventDefault();
                  form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true} ));
               }
            });
         }
      }

   }, [location.pathname]);

   useEffect(() => {
      const title = location.pathname == "/signup" ? "Aanmelden bij" : "Inloggen op";
      document.title = `${title} You Be Well`;
   }, [location.pathname]);

   return (

      <main id="login">

         <section id="login-form-container" className="container">
            <div className="form-container">

               {location.pathname.replace(/\/$/, '') == '/login'  &&

                  <>

                     <h3>Inloggen<span className="stress">.</span></h3>
                     <p>Nog geen account? <Link to="/signup">Maak deze aan</Link>.</p>

                     {(search.get('forced') == 'true' || search.get('auto-redirect')) &&
                        <p id="auto-logout">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 8v-2c0-3.313 2.687-6 6-6 3.312 0 6 2.687 6 6v2h-2v-2c0-2.206-1.795-4-4-4s-4 1.794-4 4v2h-2zm-3 2v14h18v-14h-18z"/></svg>
                           {search.get('forced') == 'true' ? "Je sessie is verlopen. Log opnieuw in." : "Je moet eerst inloggen."}
                        </p>
                     }

                     <Form
                        id="login-form"
                        action="/api/v1/auth"
                        submitBtnText="Inloggen"
                        submitCallback={onLogin}
                        loadText="Inloggen..."
                        >

                        <Input 
                           type="email"
                           name="email"
                           placeholder="E-mailadres"
                           icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"/></svg>}
                           required 
                           />

                        <Input
                           id="login-password"
                           type="password"
                           name="password"
                           placeholder="Wachtwoord"
                           icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12.804 9c1.038-1.793 2.977-3 5.196-3 3.311 0 6 2.689 6 6s-2.689 6-6 6c-2.219 0-4.158-1.207-5.196-3h-3.804l-1.506-1.503-1.494 1.503-1.48-1.503-1.52 1.503-3-3.032 2.53-2.968h10.274zm7.696 1.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"/></svg>}
                           required
                           />
                        
                        <p id="forgot-password">Wachtwoord vergeten? <Link to="/request-password">Reset deze hier</Link>.</p>

                     </Form>

                  </>

               }

               {location.pathname.replace(/\/$/, '') == '/request-password' &&

                  <>

                     <h3>Wachtwoord resetten<span className="stress">.</span></h3>
                     
                     {passwordRequested &&
                        <p className="requested">Wachtwoord-reset aangevraagd. Je ontvangt een link op het opgegeven e-mailadres als deze bij You Be Well bestaat.</p>
                     }

                     {!passwordRequested &&
                  
                        <>
                           <p>Geef je e-mailadres op en volg de instructies in de e-mail die je ontvangt.</p>

                              <Form
                                 id="login-form"
                                 submitBtnText="Aanvragen"
                                 action="/api/v1/password-reset"
                                 submitCallback={(e, data) => { 
                                    if (data.error && data.error == "Password resets cannot be triggered too frequently. Please check your inbox (or spam folder).") {
                                       const errorMessage = document.createElement('p');
                                       errorMessage.classList.add('error-message');
                                       errorMessage.textContent = 'Gebruik de link in je mailbox (of spam-map).';
                                       document.getElementById('email-input').parentElement.insertAdjacentElement("afterend", errorMessage);
                                       document.getElementById('email-input').classList.add('error');
                                    } else {
                                       setPasswordRequested(true); 
                                    }
                                 }}>

                                 <Input 
                                    id="email-input"
                                    type="email"
                                    name="email"
                                    placeholder="E-mailadres"
                                    icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12.804 9c1.038-1.793 2.977-3 5.196-3 3.311 0 6 2.689 6 6s-2.689 6-6 6c-2.219 0-4.158-1.207-5.196-3h-3.804l-1.506-1.503-1.494 1.503-1.48-1.503-1.52 1.503-3-3.032 2.53-2.968h10.274zm7.696 1.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"/></svg>}
                                    />

                              </Form>
                        </>

                     }

                  </>

               }

               {location.pathname.replace(/\/$/, '') == '/reset-password' &&

                  <>

                     {(search.get('token') === null || search.get('email') === null) && navigate('/login')}

                     <h3>Wachtwoord resetten<span className="stress">.</span></h3>
                     {passwordResetDone &&

                        <p className="requested">Je hebt je wachtwoord succesvol ingesteld. <Link to="/login">Log opnieuw in</Link>.</p>

                     }

                     {!passwordResetDone &&

                     <>

                        <p>Geef het nieuwe wachtwoord op.</p>

                           <Form
                              id="login-form"
                              submitBtnText="Resetten"
                              action="/api/v1/password-reset"
                              submitCallback={(e, data) => {
                                 const feedback = data;
                                 if (!('error' in data) || feedback.email === "Invalid value.") {
                                    setPasswordResetDone(true);
                                 } else if (data.error === "Invalid token.") {
                                    const errorMessage = document.createElement('p');
                                    errorMessage.classList.add('error-message');
                                    errorMessage.textContent = 'Je reset-link is ongeldig of verlopen.';
                                    document.getElementById('check-password-reset').parentElement.insertAdjacentElement("afterend", errorMessage);
                                    document.getElementById('check-password-reset').classList.add('error');
                                 }
                              }}
                              noHoneyPot
                              >

                              <Input 
                                 type="password"
                                 name="password"
                                 placeholder="Wachtwoord"
                                 icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12.804 9c1.038-1.793 2.977-3 5.196-3 3.311 0 6 2.689 6 6s-2.689 6-6 6c-2.219 0-4.158-1.207-5.196-3h-3.804l-1.506-1.503-1.494 1.503-1.48-1.503-1.52 1.503-3-3.032 2.53-2.968h10.274zm7.696 1.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"/></svg>}
                                 required newPassword
                                 />

                              <Input
                                 placeholder="Herhaling van het wachtwoord"
                                 type="password"
                                 id="check-password-reset"
                                 name="check-password"
                                 icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12.804 9c1.038-1.793 2.977-3 5.196-3 3.311 0 6 2.689 6 6s-2.689 6-6 6c-2.219 0-4.158-1.207-5.196-3h-3.804l-1.506-1.503-1.494 1.503-1.48-1.503-1.52 1.503-3-3.032 2.53-2.968h10.274zm7.696 1.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"/></svg>}
                                 required 
                                 />

                              <input 
                                 type="hidden"
                                 name="email"
                                 value={search.get('email')} />

                              <input 
                                 type="hidden"
                                 name="token"
                                 value={search.get('token')} />

                           </Form>
                     
                     </>

                     }

                  </>

               }

               {location.pathname.replace(/\/$/, '') == '/signup' &&

                  <>

                     <h3>Aanmelden<span className="stress">.</span></h3>

                     {passwordRequested &&
                        <>
                           <p className="requested">Account aangevraagd. Open de link in de e-mail die je van ons ontvangt.</p>
                           {search.get('referral') &&
                              <p className="referral">Leuk dat je via een andere gebruiker van You Be Well komt. De WellBits zijn aan diegene toegewezen.</p>
                           }
                        </>
                     }

                     {!passwordRequested &&

                        <>

                           <p>Welkom! Geef je e-mailadres op. Al een account? <Link to="/login">Log in</Link>.</p>

                           <Form
                              id="login-form"
                              submitBtnText="Aanvragen"
                              action="/api/v1/users"
                              submitCallback={(e, data) => { 
                                 if (!('error' in data)) {
                                    setPasswordRequested(true); 
                                 } else {
                                    const errorMessage = document.createElement('p');
                                    errorMessage.classList.add('error-message');
                                    errorMessage.textContent = 'Kies een ander e-mailadres.';
                                    document.getElementById('signup-email').parentElement.insertAdjacentElement("afterend", errorMessage);
                                    document.getElementById('signup-email').classList.add('error');
                                    document.getElementById('signup-email').parentElement.classList.remove('valid');
                                 }   
                              }}>

                                 
                              {search.get('referral') &&
                                 <input 
                                    type="hidden"
                                    name="referral"
                                    value={search.get('referral')}
                                    />
                              }
                              
                              <Input 
                                 id="signup-email"
                                 type="email"
                                 name="email"
                                 placeholder="E-mailadres"
                                 example="Je e-emailadres"
                                 icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12.804 9c1.038-1.793 2.977-3 5.196-3 3.311 0 6 2.689 6 6s-2.689 6-6 6c-2.219 0-4.158-1.207-5.196-3h-3.804l-1.506-1.503-1.494 1.503-1.48-1.503-1.52 1.503-3-3.032 2.53-2.968h10.274zm7.696 1.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"/></svg>}
                                 required
                                 explicitValidationOnInput
                                 />

                              <div
                                 id="login-form-types"
                                 >
                                 
                                 <h4>Wat omschrijft jou?</h4>

                                 <input id="login-form-user-type-user" name="type" type="radio" value="user" defaultChecked />
                                 <input id="login-form-user-type-editor" name="type" type="radio" value="editor" />

                                 <label
                                    id="login-form-user-type-user-label"
                                    htmlFor="login-form-user-type-user"
                                    >
                                    <UserIcon />
                                    <h4>Activiteiten<br />doen</h4>
                                 </label>

                                 <label
                                    id="login-form-user-type-editor-label"
                                    htmlFor="login-form-user-type-editor"
                                    >
                                    <EditorIcon />
                                    <h4>Activiteiten<br />aanbieden</h4>
                                 </label>

                                 <p id="login-form-user-type-user-p">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 24c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zm1-6h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z"/></svg>
                                    Je wilt <strong>activiteiten boeken</strong> via You Be Well.
                                 </p>

                                 <p id="login-form-user-type-editor-p">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 24c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zm1-6h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z"/></svg>
                                    Je wilt <strong>activiteiten aanbieden</strong> via You Be Well.
                                 </p>

                              </div>

                              <ConsentInput 
                                 id="474ca587-ad31-4f9d-a5dc-739b278cec3a"
                                 />

                           </Form>

                           <p id="login-form-privacy">

                              Wij verwerken gegevens op een veilige wijze volgens onze <Link to="/docs/privacy" target="_blank">privacyverklaring</Link>.

                           </p>

                        </>

                     }

                  </>

               }

            </div>

         </section>

         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity="1" d="M0,128L120,160C240,192,480,256,720,234.7C960,213,1200,107,1320,53.3L1440,0L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity="1" d="M0,128L120,160C240,192,480,256,720,234.7C960,213,1200,107,1320,53.3L1440,0L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>

      </main>

   )

}