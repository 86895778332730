import { Link, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Ad from '../components/Ad';
import Button from '../components/inputs/Button';
import Form from '../components/inputs/Form';
import MapTile from '../components/MapTile';
import Select from '../components/inputs/Select';
import useFetch from '../hooks/useFetch';

import './OverviewPage.scss';
import Checkbox from '../components/inputs/Checkbox';
import { useHistory } from 'react-router';


export default function OverviewPage() {

   const [search, setSearch] = useSearchParams();

   const [ads, setAds] = useState([]);
   const [shownAds, setShownAds] = useState([]);
   const [nOutOfBounds, setNOutOfBounds] = useState(0);
   const [upperBoundShownAds, setUpperBoundShownAds] = useState(999);
   const [sortKey, setSortKey] = useState('sorting_order');

   // convert uuids of options to activities
   const [optionMap, setOptionMap] = useState({});
   const [favorites, setFavorites] = useState(new Set());

   // location
   const [lat, setLat] = useState(52.379189);
   const [lon, setLon] = useState(4.899431);
   const [zoom, setZoom] = useState(7);

   // filters
   const [showFilter, setShowFilter] = useState(false);
   const toggleFilter = () => {
      setShowFilter(!showFilter);
   }
   const [filterValues, setFilterValues] = useState({});

   const setInitFilters = (ads, options, cities) => {

      const filters = {};

      filters['type'] = [
         {name: 'Groep', value: 'group', count: null},
         {name: 'Individueel', value: 'individual', count: null},
         {name: 'Online', value: 'online', count: null},
      ]

      filters['city'] = [];
      const searchCity = search.get('city');
      for (const city of cities) {
         
         filters['city'].push({
            name: city.name,
            value: city.id,
            category: city.province,
            lat: city.lat,
            lon: city.lon,
            count: null,
         });

         // initialize city coordinates on map
         if (!searchCity) {
            setZoom(7);
            setLat(52.379189);
            setLon(4.899431);
         } else if (searchCity === city.id) {
            setZoom(12);
            setLat(city.lat);
            setLon(city.lon);
         }

      }


      filters['options'] = []
      for (const option of options) {
         filters['options'].push({
            name: option.pretty_name,
            value: option.id,
            category: option.category,
            count: null,
         });
      }

      for (const attribute of ['options', 'type', 'city']) {

         for (const filter of filters[attribute]) {
            filter.count = ads.filter((ad) => ad.show && (ad[attribute] === null || ad[attribute].includes(filter.value))).length;
         }

      }

      setFilterValues(filters);

   }

   const applyFilters = () => {

      for (const ad of ads) {
         ad.show = true;
      }

      const newFilterValues = {...filterValues};
      for (const filterKey of Object.keys(newFilterValues)) {
         for (const filter of newFilterValues[filterKey]) {
            filter['count'] = 0;
         }
      }
      const filterValuesExist = Object.keys(newFilterValues).length;

      for (const attribute of ['options[]', 'type', 'city', 'day[]']) {

         const filter = Array.from(search.keys()).includes(attribute);

         let value;
         if (filter) {
            if (attribute.endsWith('[]')) {
               value = search.getAll(attribute);
            } else {
               value = search.get(attribute).toLowerCase();
            }
         }

         if (!value) continue;

         for (const ad of ads) {
            const adAttribute = attribute.replace("[]", "");

            // single selects
            if (['type'].includes(adAttribute)) {
               
               if (filter && value && !String(ad[adAttribute]).toLowerCase().includes(value)) {
                  ad.show = false;
               }

            // multiselects
            } else if (['options'].includes(adAttribute) && value) {
               
               let adShow = false;
               for (const searchValue of value) {
                  if (filter && String(ad[adAttribute]).toLowerCase().includes(searchValue)) {
                     adShow = true;
                     break;
                  }
               }
               ad.show = adShow;

            } else if (adAttribute === 'day') {

               if (ad['opening_hours']) {
                  const openingHours = JSON.parse(ad['opening_hours']);
                  if (!openingHours || value.filter(val => Object.keys(openingHours).includes(val)).length == 0) {
                     ad.show = false;
                  }
               }
               continue;

            } else if (filter && value && String(ad[adAttribute]).toLowerCase() != value) {
               
               ad.show = false;

            }

            // update counts
            if (ad.show && filterValuesExist) {
               for (const filter of newFilterValues[adAttribute]) {
                  if (ad[attribute] === null || ad[adAttribute].includes(filter.value)) {
                     filter.count++;
                  }
               }
            }

         }

         if (filterValuesExist) {
            setFilterValues(filterValues);
         }

      }

      updateShownAds([...ads.filter(ad => ad.show)]);

   }

   const onFilter = () => {      

      const params = {};
      
      // single- and multi-selects
      const selects = document.getElementsByClassName('select');
      for (const select of selects) {

         const value = select.getElementsByTagName('input')[0].value.toLowerCase();
         if (value == "") continue;
         const attribute = select.id.substring(16); 

         if (select.classList.contains('multi')) {
            params[attribute + '[]'] = value.split(";");
         } else {
            params[attribute] = value;
         }         

      }

      // checkboxes
      const checkboxes = document.getElementsByClassName('checkbox');
      for (const checkbox of checkboxes) {

         const checked = checkbox.getElementsByTagName('input')[0].checked;
         if (!checked) continue;
         const attributeValue = checkbox.getElementsByTagName('input')[0].id.substring(9);
         const [attribute, value] = attributeValue.split('-')
         const adAttribute = attribute + (value.endsWith("[]") ? "[]" : "");
         if (!Object.keys(params).includes(adAttribute)) {
            params[adAttribute] = [];
         }
         params[adAttribute].push(value.replace("[]", ""));

      }

      // sliders
      const sliders = document.getElementsByClassName('slider');
      for (const slider of sliders) {

         const attribute = slider.id.substring(16); 
         const inputs = slider.getElementsByTagName("input");
         const min = inputs[0].value;
         const max = inputs[1].value;
         const adAttribute = attribute.replace("-", "_");

         params[adAttribute + "_min"] = min;
         params[adAttribute + "_max"] = max;

      }

      // text inputs
      const inputs = document.getElementsByTagName('input');
      for (const input of inputs) {

         if (input.type == "text" && input.value.length > 0 && input.getAttribute('name')) {
            params[input.getAttribute('name')] = input.value;
         }

      }

      if (selects.length) {
         setSearch(params);
      }

   }

   // request activities
   const request = {
      activities: {
          endpoint: "/api/v1/activities",
          method: "GET",
      },
      options: {
         endpoint: "/api/v1/activities/options",
         method: "GET",
      },
      activityOptions: {
         endpoint: "/api/v1/activities/options",
         method: "GET",
      },
      cities: {
         endpoint: "/api/v1/activities/cities",
         method: "GET",
      },
      favorites: {
         endpoint: "/api/v1/favorites",
         method: "GET",
      }
   }

   const [data, loading, error] = useFetch(request);

   useEffect(() => {

      if (!loading) {

         if (ads.length === 0 && data.activities) {
            for (const ad of data.activities.data) {
               ad["show"] = true;
            }
            setAds(data.activities.data);
         }
   
         if (data.favorites && data.favorites.data) {
            const newFavorites = new Set();
            for (const favorite of data.favorites.data) {
               newFavorites.add(favorite.activity_id);
            }
            setFavorites(newFavorites);
         }   

      }

   }, [loading]);

   useEffect(() => {

      applyFilters();
      if (!loading && ads.length && data.activityOptions && data.cities && data.options) {

         // initialize filters
         setInitFilters(ads, data.activityOptions.data, data.cities.data);

         // initialize UUID-pretty name map for options for displaying <Ad />
         const newOptionMap = {};
         for (const option of data.options.data) {
            newOptionMap[option.id] = option;
         }
         setOptionMap(newOptionMap);

      } 
   }, [ads, search]);

   const updateShownAds = (ads, doNotHide=null) => {

      setShownAds(ads);

   }

   useEffect(() => {
      document.title = "Well-Being Activiteiten - You Be Well";
   }, []);

   return (
         <main id="overview">

            <section id="hero">

               <div id="hero-tagline" className="container">
                  <div id="hero-tagline-text">
                     <h1>Hoe investeer jij vandaag in jezelf<span className="stress">?</span></h1>
                     <p>Bekijk de activiteiten van onze aanbieders.</p>
                  </div>
               </div>
            
            </section>

            <section id="filters-results">

               <div className="container">

                  <div id="filters" className={showFilter ? "show" : ""}>

                     <div className="form-container">

                        <Form
                           id="filter-form"
                           submitBtnText="Filteren"
                           onChange={onFilter}
                           additionalButton={
                              <Button 
                                 name="Bekijk resultaten"
                                 noLink
                                 onClick={() => {
                                    setShowFilter(false);
                                 }}
                                 />
                           }
                           noSubmit
                           mutationObserver
                           >

                           <h4>Activiteit.</h4>

                           <Select
                              id="select-activity-options"
                              placeholder="Activiteit"
                              name="activity-type"
                              label="Activiteit"
                              defaultValue={search.getAll('options[]')}
                              options={filterValues.options ? filterValues.options : []}
                              className="z-index-select-10"
                              icon={<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 24 24"><path d="M10.361.125c-2.504.344-4.758 1.457-6.523 3.099 3.332.159 5.773 1.521 8.351 1.836-.618-2.186-1.2-3.643-1.828-4.935m2.85 9.88c-4.936.474-9.392 2.043-12.988 4.279.463 2.396 1.634 4.541 3.295 6.201 3.802-2.97 5.168-8.005 9.853-9.176-.045-.437-.1-.871-.16-1.304m-.509-2.925c-3.857-.211-7.25-2.45-10.442-2.068-1.417 1.969-2.26 4.379-2.26 6.988l.007.158c3.347-2.259 8.54-3.758 12.868-4.184-.056-.298-.108-.598-.173-.894m11.236 3.703c-2.843-.707-5.834-1.005-8.716-.909.055.412.096.826.137 1.241 3.412.228 6.002 3.617 7.084 6.78.986-1.743 1.557-3.751 1.557-5.895 0-.411-.022-.817-.062-1.217m-3.539-7.343c-2.114.799-2.941 2.915-5.667 3.509l.16.889c2.507-.099 5.161.065 8.607.746-.587-1.975-1.666-3.737-3.1-5.144m-6.893 9.864c-3.361 1.011-5.057 5.821-8.444 8.473 1.961 1.395 4.351 2.223 6.938 2.223l.554-.028c.756-3.443 1.132-6.819.952-10.668m2-.124c.169 3.603-.16 7.068-.856 10.516 2.628-.594 4.932-2.043 6.594-4.05-1.005-2.781-2.837-5.958-5.738-6.466m3.161-11.154c-2.243 1.588-2.462 2.536-4.417 2.965-.444-1.67-1.025-3.375-1.74-4.965 2.271.096 4.382.811 6.157 2"/></svg>}
                              max={15}
                              />

                           <Select
                              id="select-activity-type"
                              placeholder="Type activiteit"
                              name="activity-type"
                              label="Type activiteit"
                              defaultValue={search.get('type')}
                              options={filterValues.type ? filterValues.type : []}
                              className="z-index-select-8"
                              icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.118 16.064c2.293-.529 4.428-.993 3.394-2.945-3.146-5.942-.834-9.119 2.488-9.119 3.388 0 5.644 3.299 2.488 9.119-1.065 1.964 1.149 2.427 3.394 2.945 1.986.459 2.118 1.43 2.118 3.111l-.003.825h-15.994c0-2.196-.176-3.407 2.115-3.936zm-10.116 3.936h6.001c-.028-6.542 2.995-3.697 2.995-8.901 0-2.009-1.311-3.099-2.998-3.099-2.492 0-4.226 2.383-1.866 6.839.775 1.464-.825 1.812-2.545 2.209-1.49.344-1.589 1.072-1.589 2.333l.002.619z"/></svg>}
                              readOnly
                              />

                           <h4>Dag van de week.</h4>
                           <div className="checkbox-row">
                              {[["mon", "Ma"], ["tue", "Di"], ["wed", "Wo"], ["thu", "Do"], ["fri", "Vrij"], ["sat", "Za"], ["sun", "Zo"]].map((value, key) => {
                                 return (
                                    <Checkbox 
                                       id={`activity-day-${value[0]}[]`}
                                       name="days[]"
                                       value={value[0]}
                                       label={value[1]}
                                       onChange={onFilter}
                                       checked={search.getAll(`day[]`) !== null && search.getAll(`day[]`).includes(value[0])}
                                       />
                                 );
                              })}
                           </div>

                           <h4>Locatie.</h4>

                           <MapTile 
                               lat={lat}
                               lon={lon}
                               zoom={zoom}
                               markers={ads.filter((ad) => ad.show && ad.lat && ad.lon).map((ad, key) => {
                                 return {lat: ad.lat, lon: ad.lon, popup: {
                                    name: ad.location_name,
                                    city: ad.city_name,
                                    img: ad.images[0].image_path.replace(".jpg", "_thumbnail.jpg"),
                                    href: "#ad-" + ad.id
                                 }}
                               })}
                               children={
                                 <Select
                                    id="select-activity-city"
                                    placeholder="Plaats"
                                    name="activity-city"
                                    label="Plaats"
                                    defaultValue={search.get('city')}
                                    options={filterValues.city ? filterValues.city : []}
                                    className="z-index-select-9"
                                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>}
                                    />
                               }
                              />

                        </Form>

                     </div>
                     
                     {/options|type|city|day/.test(window.location.search) &&
                        <div id="reset-filters-wrapper">
                           <Link 
                              id="reset-filters" 
                              to="/activities" 
                              onClick={() => { 

                                 const form = document.getElementById();

                              }}
                              replace>Reset filters</Link>
                        </div>
                     }

                  </div>


                  <div id="results">
                     
                     <div id="results-info">
                        <span id="results-info-filter">Filters:

                           {!/options|type|city|day/.test(window.location.search) && 
                              <span className="filter">Geen</span>
                           }

                           {['options[]', 'type', 'city', 'day[]'].map((value, key) => {
                              const mapping = {
                                 'options[]': 'Activiteit',
                                 'type': 'Type activiteit',
                                 'city': 'Plaats',
                                 'day[]': 'Dag van de week'
                              }
                              if (search.get(value)) {
                                 return (
                                    <span className="filter" key={key}>
                                       {mapping[value]}
                                    </span>
                                 )
                              }
                           })}

                        </span>
                        <span id="results-info-n"><span>{shownAds.length}</span> {shownAds.length === 1 ? "resultaat" : "resultaten"}</span>
                     </div>

                     <div className="ads">
                        {shownAds.map((value, key) => {
                           return (<Ad 
                                       favorite={favorites && favorites.has(value.id)}
                                       key={key} {...value} optionMap={optionMap} />)
                        })}

                        {loading && ads.length === 0 && Array(8).fill(1).map((value, key) => {
                           return (
                              <div className="ad-placeholder" key={key}>
                                 <div className="img-placeholder placeholder white">
                                    <div className="h3-placeholder placeholder white"></div>
                                 </div>
                                 <div className="text-placeholder">
                                    <div className="tag-placeholder placeholder white"></div>
                                 </div>
                              </div>
                           );
                        })}

                        {!loading && !shownAds.length &&
                           <p id="no-ads">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 5h2v10h-2v-10zm1 14.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"/></svg>
                              Er zijn geen activiteiten gevonden die aan de zoekcriteria voldoen. <Link to="/activities">Toon alles</Link>.
                           </p>}

                     </div>

                  </div>

               </div>

            </section>

            <span id="show-filters" onClick={toggleFilter}>
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 0l-8.412 15h-5.215l-8.373-15h22zm-13 17v7h4v-7h-4z"/></svg>
            </span>

         </main>        
    );

}