import { useEffect, useRef, useState } from 'react';

import { useAuth } from '../contexts/AuthContext';

export default function useTokenStorage() {
  
  const tokensRef = useRef({});
  const [newTokenObtained, setNewTokenObtained] = useState(false);

  const saveToken = (key, token, expirationTime) => {
    const updatedTokens = {
      ...tokensRef.current,
      [key]: { token, expirationTime },
    };
    tokensRef.current = updatedTokens;
    localStorage.setItem('tokens', JSON.stringify(tokensRef.current));
  };

  const removeToken = (key) => {
    const updatedTokens = { ...tokensRef.current };
    delete updatedTokens[key];
    localStorage.setItem('tokens', JSON.stringify(updatedTokens));
    tokensRef.current = updatedTokens;
  };

  const clearTokens = async () => {
    localStorage.removeItem('tokens');
    tokensRef.current = {};
  };

  const getTokens = () => {
    return tokensRef.current;
  };

  const getToken = (key) => {
    return tokensRef.current[key] ? tokensRef.current[key].token : null;
  };

  const getTokenExpriy = (key) => {
    return tokensRef.current[key] ? tokensRef.current[key].expirationTime : null;
  };

  useEffect(() => {
    const storedTokens = JSON.parse(localStorage.getItem('tokens'));
    if (storedTokens) {
      tokensRef.current = storedTokens;
    }
  }, []);

  async function refreshToken() {
    try {

      const refreshToken = getToken('refresh_token');
      if (!refreshToken) {
        return;
      }

      const response = await fetch('/api/v1/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + refreshToken
        }
      });
      const data = await response.json();
  
      if (!('error' in data)) {
 
        await new Promise((resolve) => {
          saveToken(
            'refresh_token',
            data.data.refresh_token,
            Date.now() + data.data.refresh_token_expires * 1000
          );
          saveToken(
            'access_token',
            data.data.access_token,
            Date.now() + data.data.access_token_expires * 1000
          );

          resolve();
          setNewTokenObtained(true);
        });
      }
      
      return data;
    } catch (error) {
      
    }
  }

  return [getTokens, saveToken, removeToken, clearTokens, getToken, getTokenExpriy, refreshToken, newTokenObtained];
}
