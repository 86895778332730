import { Link } from 'react-router-dom';
import React from 'react';

import { useNotifications } from '../contexts/NotificationContext';

import './NotificationContainer.scss';

const NotificationContainer = () => {
  
  const { notifications, removeNotification } = useNotifications();

  return (
    <div id="notification-container">
      {notifications.map((notification, key) => (
        <div key={notification.id} className={"notification" + (notification.fading ? " fading": "")}>
          <div className="progress-bar"></div>
          <h4>Melding</h4>
          <p>{notification.message}</p>
          {notification.href && notification.hrefText &&
            <Link to={notification.href}>{notification.hrefText}</Link>
          }
          <span onClick={() => {removeNotification(notification.id)}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
          </span>
        </div>
      ))}
    </div>
  );

};

export default NotificationContainer;