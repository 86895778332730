import { HashLink } from 'react-router-hash-link';
import { useEffect, useState } from 'react';

import './Rating.scss';

export default function Rating(props) {

   const [rating, setRating] = useState(0);
   const [roundRating, setRoundRating] = useState(0);

   useEffect(() => {
      if (props.rating) {
         setRating(Math.round(props.rating * 5) / 5);
         setRoundRating(Math.round(props.rating * 2) / 2);
      } else {
         setRating(null);
      }
   }, [props.rating]);


   const ratingDiv = <div 
      id={props.id} 
      className={"rating" + 
         (props.invert ? " invert" : "") + 
         (props.shrink ? " shrink" : "") +
         (props.input ? " input" : "")
      }
      onClick={props.input ? (e) => { 
         const errorElement = e.target.classList.contains('rating-half') ? e.target.parentElement.parentElement.nextElementSibling : null;
         if (errorElement && errorElement.classList.contains('error-message')) { 
            errorElement.remove(); 
         } 
         document.getElementById('rating-score').classList.remove('error');
      } : null}
      >
      <div className="rating-score">
         {
            Array(10).fill().map((value, key) => {

               return (
                  <div 
                     className={"rating-half" + (roundRating >= key + 1 ? " full": "") + (key % 2 == 0 ? " left-half" : "")}
                     onClick={props.input ? () => {setRating(key + 1); setRoundRating(key + 1)} : null}
                     >
                     
                  </div>
               )

            })
         }
      </div>

      <span>   

         {props.input &&

            <>
               {rating === null &&
                  <>Je beoordeling</>
               }
               {(rating !== null && props.n > 0) &&
                  <>{(rating / 2).toFixed(1)} <span>/ 5.0</span></>
               }
            </>

         }

         {(!props.input && !props.single) &&

            <>            
               {(rating !== null && props.n > 0) &&
                  <>{(rating / 2).toFixed(1)}</>
               }

               {(props.n !== null && props.n > 0) &&
                  <span> • {props.n} review{props.n == 1 ? "" : "s"}</span>   
               }

               {(props.n !== null && props.n === 0) &&
                  <span> • 0 reviews</span>   
               }
            </>

         }
      </span>

      {props.input &&
         <input 
            id="rating-score"
            name="rating-score"
            type="hidden"
            value={rating ? rating : ""}
            required
            />
      }

   </div>;

   if (props.href && !props.input) {
      return (
         <HashLink
            to={props.href} >
               {ratingDiv}
         </HashLink>
      )
   }

   return ratingDiv;

}