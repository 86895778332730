import { useContext, useEffect, useState } from 'react';

import Ad from './Ad';
import { AuthContext } from '../contexts/AuthContext';
import Button from './inputs/Button';
import Chat from './Chat';
import Form from './inputs/Form';
import Input from './inputs/Input';
import { Link, useLocation } from 'react-router-dom';
import ProfileAdmin from './ProfileAdmin';
import ProfileFinance from './ProfileFinance';
import { ReactComponent as Logo } from '../assets/ybw_logo.svg';
import useFetch from '../hooks/useFetch';
import { useNotifications } from '../contexts/NotificationContext';

import './Profile.scss';


function EditProfile(props) {

    const [fadingOut, setFadingOut] = useState(false);
    const [user, setUser] = useState({});

    const closeForm = () => {
        setFadingOut(true);
        setTimeout(() => {
           props.closeFormFn();
           setFadingOut(false);
        }, 350);
    }

    const { addNotification } = useNotifications();

    const onSubmit = () => {

        if (props.type == 'name') {

            user.first_name = document.getElementById('edit-profile-first-name').value;
            user.last_name = document.getElementById('edit-profile-last-name').value;

        } else if (props.type == 'financial') {

            user.iban_number = document.getElementById("edit-profile-iban-number").value;;
            user.coc_number = document.getElementById("edit-profile-coc-number").value;;
            user.vat_number = document.getElementById("edit-profile-vat-number").value;

        }

        addNotification('Je gegevens zijn aangepast.');
        closeForm();

    }

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    const cancelButton = <Button name="Annuleren" onClick={closeForm} noIcon />

    let formComponents;
    if (props.type == 'name') {

        formComponents = <>
            <h4>Persoonlijke gegevens<span className="stress">.</span></h4>
            <p>Zo verschijn je op You Be Well.</p>

            <Form 
                action="/api/v1/user"
                submitBtnText="Opslaan"
                additionalButton={cancelButton}
                submitCallback={onSubmit}
                >

                <div className="two-inputs">

                    <div>
                        <Input 
                            id="edit-profile-first-name"
                            name="first_name"
                            type="text"
                            placeholder="Voornaam"
                            defaultValue={user ? user.first_name : ""}
                            icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/></svg>}
                            required
                            />
                    </div>

                    <div>
                        <Input   
                            id="edit-profile-last-name"
                            name="last_name"
                            type="text"
                            placeholder="Achternaam"
                            defaultValue={user ? user.last_name : ""}
                            icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/></svg>}
                            required
                            />
                    </div>

                </div>

            </Form>
        </>;

    } else if (props.type == 'password') {

        formComponents = <>
            <h4>Beveiliging<span className="stress">.</span></h4>
            <p>Wijzig hier je wachtwoord.</p>

            <Form 
                action="/api/v1/password-reset"
                submitBtnText="Opslaan"
                additionalButton={cancelButton}
                submitCallback={onSubmit}>

            {(user && user.email) &&
                <input type="hidden" name="email" value={user.email} />}

            <Input
                name="old-password"
                placeholder="Huidig wachtwoord"
                example="Je huidige wachtwoord"
                type="password"
                icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12.804 9c1.038-1.793 2.977-3 5.196-3 3.311 0 6 2.689 6 6s-2.689 6-6 6c-2.219 0-4.158-1.207-5.196-3h-3.804l-1.506-1.503-1.494 1.503-1.48-1.503-1.52 1.503-3-3.032 2.53-2.968h10.274zm7.696 1.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"/></svg>}
                required />

            <Input
                name="password"
                placeholder="Nieuw wachtwoord"
                example="Je nieuwe wachtwoord"
                type="password"
                icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12.804 9c1.038-1.793 2.977-3 5.196-3 3.311 0 6 2.689 6 6s-2.689 6-6 6c-2.219 0-4.158-1.207-5.196-3h-3.804l-1.506-1.503-1.494 1.503-1.48-1.503-1.52 1.503-3-3.032 2.53-2.968h10.274zm7.696 1.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"/></svg>}
                newPassword required />

            <Input
                name="check-password"
                placeholder="Herhaling nieuw wachtwoord"
                example="Typ het wachtwoord hierboven"
                type="password"
                icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12.804 9c1.038-1.793 2.977-3 5.196-3 3.311 0 6 2.689 6 6s-2.689 6-6 6c-2.219 0-4.158-1.207-5.196-3h-3.804l-1.506-1.503-1.494 1.503-1.48-1.503-1.52 1.503-3-3.032 2.53-2.968h10.274zm7.696 1.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"/></svg>}
                required />

            </Form>

        </>

    } else if (props.type == 'financial') {

        formComponents = <>
            <h4>Financiële gegevens<span className="stress">.</span></h4>
            <p>Deze hebben we nodig voor uitbetalingen.</p>

            <Form 
                action="/api/v1/user"
                submitBtnText="Opslaan"
                additionalButton={cancelButton}
                submitCallback={onSubmit}
                >

                <Input 
                    id="edit-profile-iban-number"
                    name="iban_number"
                    type="text"
                    placeholder="IBAN"
                    example="Bijv. NL20INGB0001234567"
                    defaultValue={user ? user.iban_number : ""}
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 21h-4v-11h4v11zm7-11h-4v11h4v-11zm7 0h-4v11h4v-11zm2 12h-22v2h22v-2zm-23-13h24l-12-9-12 9z"/></svg>}
                    minLength="18"
                    maxLength="22"
                    iban
                    required
                    />

                <Input 
                    id="edit-profile-beneficiary-name"
                    name="beneficiary_name"
                    type="text"
                    placeholder="Naam begunstigde"
                    example="Bijv. You Be Well B.V."
                    defaultValue={user ? user.beneficiary_name : ""}
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 21h-4v-11h4v11zm7-11h-4v11h4v-11zm7 0h-4v11h4v-11zm2 12h-22v2h22v-2zm-23-13h24l-12-9-12 9z"/></svg>}
                    minLength="1"
                    maxLength="100"
                    required
                    />

                <div className="two-inputs">

                    <div>
                        <Input 
                            id="edit-profile-coc-number"
                            name="coc_number"
                            type="text"
                            placeholder="KvK-nummer"
                            defaultValue={user ? user.coc_number : ""}
                            icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M1 22h2v-22h18v22h2v2h-22v-2zm7-3v4h3v-4h-3zm5 0v4h3v-4h-3zm-6-5h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2z"/></svg>}
                            minLength="8"
                            maxLength="8"
                            required
                            />
                    </div>

                    <div>
                        <Input   
                            id="edit-profile-vat-number"
                            name="vat_number"
                            type="text"
                            placeholder="Btw-nummer"
                            example="Bijv. NL123456789B01"
                            defaultValue={user ? user.vat_number : ""}
                            icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M1 22h2v-22h18v22h2v2h-22v-2zm7-3v4h3v-4h-3zm5 0v4h3v-4h-3zm-6-5h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2z"/></svg>}
                            minLength="14"
                            maxLength="14"
                            />
                    </div>

                </div>

            </Form>
        </>

    }

    return (

        <div 
            id="edit-profile"
            className={fadingOut ? "fading-out" : ""}
            >

            <div className="form-section form-container">

                {formComponents}  

            </div>

        </div>

    );

}

function Listings(props) {

    const [ads, setAds] = useState([]);

    const updateShownAds = (ads) => {

        const adElements = document.getElementsByClassName('ad');
        if (adElements.length == 0) return;
        let i = 0;
        const showAdInterval = setInterval(() => {
  
           if (adElements[i] == undefined) {
              clearInterval(showAdInterval);
              return;
           }
  
           adElements[i].classList.add('show');
           i++;
           if (i == adElements.length) {
              clearInterval(showAdInterval);
           }
  
        }, 35);
  
     }

    const request = {
        activities: {
            endpoint: "/api/v1/activities/personal",
            method: "GET",
        },
    }
    const [data, loading, error] = useFetch(request);

    useEffect(() => {

        if (data.activities && data.activities.data) {
            for (const ad of data.activities.data) {
               ad["excl"] = ad.excl_price > 0 ? 1 : 0;
            }
            new Promise((resolve, reject) => {
               setAds(data.activities.data);
               resolve();
            }).then(() => {
               updateShownAds(ads);
            })
         }

    }, [loading]);


    if (!['editor', 'admin'].includes(props.user.type)) {
        return <></>
    }

    return (

        <section id="locations-info" className="container">
        
            <h3>Listings<span className="stress">.</span></h3>

            <p>{ads.length ? "Bekijk en bewerk je bestaande listings." : "Je hebt nog geen listings."}</p>

            <div id="profile-ads">
                {ads.map((value, key) => {
                    return (<Ad edit key={key} {...value} optionMap={props.optionMap} noFavorite />)
                })} 

                <Link 
                    to="/activities/new"
                    className="create-ad">
                    <div className="create-ad-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z"/></svg>
                        Listing aanmaken
                    </div>
                </Link>

            </div>           

        </section>

    );

}

function Favorites(props) {

    const [favoriteAds, setFavoriteAds] = useState([]);

    const request = {
        favoriteActivities: {
            endpoint: "/api/v1/favorites/activities",
            method: "GET",
        },
    }
    const [data, loading, error] = useFetch(request);

    useEffect(() => {

        if (data.favoriteActivities && data.favoriteActivities.data) {
            setFavoriteAds(data.favoriteActivities.data);
        }

    }, [loading]);

    useEffect(() => {
        const shownAds = document.getElementsByClassName('ad');
        for (const ad of shownAds) {
           ad.classList.add('show');
        }
     }, [favoriteAds]);

    return (
        <section id="profile-favorites" className={"container " + props.className}>

            <div className="container">
            <h3>Favorieten<span className="stress">.</span></h3>
            {(favoriteAds && favoriteAds.length > 0) &&
                <>
                    <p>Hieronder zie je de activiteiten die je geliket hebt.</p>
                    <div className="ads">
                        {favoriteAds.map((value, key) => {
                                return (<Ad key={key} optionMap={props.optionMap} {...value} noFavorite />)
                        })}                     
                    </div>        
                </>
            }
            {!favoriteAds || favoriteAds.length == 0 &&
                    <p>Like een activiteit uit ons <Link to="/activities">overzicht</Link> en zie deze hier terug.</p>}

            </div>   

        </section>
    );

}

function ReferralLink(props) {

    const baseURL = window.location.hostname + "/r/";
    const referralURL = encodeURIComponent("https://" + baseURL + props.code);
    const message = encodeURIComponent("Ik heb iets voor je waarmee je kan werken aan je well-being. Meld je aan op You Be Well!");
    
    const { addNotification } = useNotifications();

    const copyToClipboard = () => {
        navigator.clipboard.writeText("https://" + baseURL + props.code);
        addNotification('Je hebt je link gekopiëerd.');
    }

    return (
        <div id="referral-link">
            <h4
                onClick={copyToClipboard} 
                >
                <span>{baseURL}</span>{props.code}
                <svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.143 13.244l.837-2.244 2.698 5.641-5.678 2.502.805-2.23s-8.055-3.538-7.708-10.913c2.715 5.938 9.046 7.244 9.046 7.244zm8.857-7.244v18h-18v-6h-6v-18h18v6h6zm-2 2h-12.112c-.562-.578-1.08-1.243-1.521-2h7.633v-4h-14v14h4v-3.124c.6.961 1.287 1.823 2 2.576v6.548h14v-14z"/></svg>
            </h4>
            <div id="referral-services">
                
                <Link
                    target="_blank"
                    to={`https://wa.me/?text=${message}%20${referralURL}`}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/></svg>
                </Link>

                <Link 
                    target="_blank"
                    to={`http://twitter.com/share?text=${message}&url=${referralURL}&hashtags=youbewell,wellbeing`}>
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
                </Link>

                <Link 
                    target="_blank"
                    to={`https://www.linkedin.com/sharing/share-offsite/?url=${referralURL}`}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
                </Link>

                <Link
                    target="_blank"
                    to={`https://www.facebook.com/sharer/sharer.php?u=${referralURL}&quote=${message}`}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
                </Link>

                <Link
                    target="_blank"
                    to={`mailto:?subject=${encodeURIComponent('Aanmelden Bij You Be Well Via Mijn Link')}&body=${message}%20${referralURL}`}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"/></svg>
                    
                </Link>

            </div>
        </div>
    );

}

function Wallet(props) {

    const [user, setUser] = useState({});

    useEffect(() => {
        if (props.user) {
            setUser(props.user);
        }
    }, [props.user]);

    const icon = (
        props.bits ?
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 1c-.438 1.438-1.563 2.562-3 3 1.437.438 2.562 1.563 3 3 .438-1.437 1.563-2.561 3-3-1.437-.438-2.562-1.562-3-3zm-19.001 2c-.292.957-1.042 1.708-1.999 2 .959.293 1.707 1.042 2.001 2 .292-.958 1.042-1.708 1.999-2-.957-.292-1.707-1.042-2.001-2zm18.5 16c-.365 1.196-1.303 2.134-2.499 2.5 1.199.366 2.134 1.303 2.501 2.5.365-1.197 1.303-2.134 2.499-2.5-1.196-.364-2.134-1.303-2.501-2.5zm-9.499-19c-.292.958-1.042 1.708-2 2 .959.293 1.708 1.042 2.002 2 .292-.958 1.042-1.708 1.998-2-.956-.292-1.706-1.042-2-2zm-8.001 18c-.435 1.436-1.563 2.561-2.999 3 1.439.439 2.564 1.564 3 3 .439-1.436 1.564-2.561 2.999-3-1.435-.437-2.56-1.564-3-3zm14.063-12h-10.054l-5.008 5.625 10 12.375 10-12.305-4.938-5.695zm-9.394 6l3.039 7.218-5.832-7.218h2.793zm7.579 0l-3.247 7.711-3.247-7.711h6.494zm-6.04-1l2.793-2.793 2.793 2.793h-5.586zm7.125 1h2.843l-5.899 7.259 3.056-7.259zm2.418-1h-2.543l-3-3h2.942l2.601 3zm-10.846-3h2.889l-3 3h-2.559l2.67-3z"/></svg> :
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.728 20.517c-3.488 0-5.613-2.461-6.443-5.517h6.715l.333-2h-7.398c-.059-.664-.064-1.335-.014-2h7.746l.333-2h-7.755c.786-3.106 2.855-5.626 6.154-5.626 1.133 0 2.391.203 3.836.62l.765-3.162c-1.854-.552-3.616-.832-5.244-.832-5.959 0-9.541 4.152-10.594 9h-2.162l-.333 2h2.203c-.049.666-.051 1.334-.007 2h-2.53l-.333 2h3.145c1.033 4.848 4.664 9 11.085 9 1.5 0 3.004-.276 4.476-.821l-.883-3.23c-1.048.378-2.088.568-3.095.568z"/></svg>
    )

    const progressToNextLevel = (props.balance - props.prevLevel) / (props.nextLevel - props.prevLevel);

    return (

        <div 
            className={"wallet" + (props.bits ? " wellbits": "") + (props.short ? " short" : "")}>

            <svg
                className="wallet-waves" 
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity="1" d="M0,96L48,106.7C96,117,192,139,288,170.7C384,203,480,245,576,234.7C672,224,768,160,864,128C960,96,1056,96,1152,117.3C1248,139,1344,181,1392,202.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>

            <div className="wallet-text">
                <span className="balance">
                    <span className="title">Saldo</span>
                    {icon} {props.balance}
                </span>
                {props.nextLevel &&
                    <div className="balance-progress">
                        {props.currentLevelTitle &&
                            <span
                                className="level-title current">
                                {props.currentLevelTitle}
                            </span>
                        }
                        {props.nextLevelTitle &&
                            <span
                                className="level-title next">
                                {props.nextLevelTitle}
                            </span>
                        }
                        <div 
                            className={"progress" + (progressToNextLevel > 0.8 ? " align-right": "")}
                            style={{width: String(progressToNextLevel * 100) + "%"}}
                            >
                            <span>
                                <span>{props.balance}</span>
                                {props.nextLevel}
                            </span>
                        </div>
                    </div>
                }
            </div>

            <Logo
                className="wallet-logo"
                 />

            {(props.href && props.hrefText) &&
                <Link 
                    className="wallet-link"
                    to={props.href}>
                        {props.hrefText}
                    <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m13.022 14.999v3.251c0 .412.335.75.752.75.188 0 .375-.071.518-.206 1.775-1.685 4.945-4.692 6.396-6.069.2-.189.312-.452.312-.725 0-.274-.112-.536-.312-.725-1.451-1.377-4.621-4.385-6.396-6.068-.143-.136-.33-.207-.518-.207-.417 0-.752.337-.752.75v3.251h-9.02c-.531 0-1.002.47-1.002 1v3.998c0 .53.471 1 1.002 1z" fillRule="nonzero"/></svg>
                </Link>
            }

            {props.tag &&
                <span className="wallet-tag">
                    {props.tag}
                </span>
            }

        </div>

    );

}

function Achievements(props) {

    const [achievements, setAchievements] = useState([]);

    const request = {
        achievements: {
            endpoint: "/api/v1/wellbits/achievements",
            method: "GET",
        }
    }
    const [data, loading, error] = useFetch(request);

    useEffect(() => {

        if (data.achievements && data.achievements.data) {
            setAchievements(data.achievements.data);
        }

    }, [loading]);

    const codeToStatusMap = {
        'ACHIEVEMENT_SIGNUP_REFERRED': 'Wachten op eerste boeking',
        'ACHIEVEMENT_SIGNUP_REFERRAL': 'Wachten op eerste boeking andere gebruiker',
        'ACHIEVEMENT_BOOKING_MADE': 'Wachten op scan QR-code',
        'ACHIEVEMENT_BOOKING_RECEIVED': 'Wachten op scan QR-code'
    }

    return (

        <>

            <section 
                id="profile-achievements-balance"
                > 

                <h3>Voortgang.</h3>
                <p>WellBits beloont je voor activiteit op You Be Well. Bekijk je voortgang naar het volgende WellBits-niveau.</p>

                <Wallet 
                    balance={props.user.num_bits}
                    tag="WellBits"
                    prevLevel={props.currentLevel ? props.currentLevel.num_bits : 0}
                    nextLevel={props.nextLevel ? props.nextLevel.num_bits : 1}
                    currentLevelTitle={props.currentLevel ? props.currentLevel.title : null}
                    nextLevelTitle={props.nextLevel ? props.nextLevel.title : null}
                    short
                    bits
                    />

            </section>

            <section
                id="profile-referral">

                <h3>You Be Well Refer-a-friend.</h3>
                <p>Als een nieuwe gebruiker zich aanmeldt via jouw link en een boeking plaatst of ontvangt, krijgen jullie allebei 1000 WellBits.</p>

                <ReferralLink
                    code={props.user.referral_link}
                    />

            </section>

            <section 
                id="profile-achievements"
                className="last"
                >

                <h3>Prestaties.</h3>
                <p>Bekijk hoe je WellBits-saldo is opgebouwd.</p>

                <div className="achievements">

                    {!achievements.length &&
                        <div className="achievement grey">
                            <h4>Nog niets te zien...</h4>
                            <p>Maak gebruik van You Be Well en verdien WellBits met prestaties!</p>
                            <span>+ ...</span>
                        </div>   
                    }

                    {[achievements.map((achievement, key) => {
                        
                        return (
                            <div 
                                key={key} 
                                className={"achievement" + (achievement.status == 'reserved' ? " reserved" : "")}>
                                <div className="achievement-waves-wrapper">
                                    <svg
                                        className="achievement-waves" 
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity="1" d="M0,96L48,106.7C96,117,192,139,288,170.7C384,203,480,245,576,234.7C672,224,768,160,864,128C960,96,1056,96,1152,117.3C1248,139,1344,181,1392,202.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                                </div>

                                <h4><span>{achievement.title}</span></h4>
                                <p><span>{achievement.description}</span></p>
                                <span>
                                    + {achievement.num_bits}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 1c-.438 1.438-1.563 2.562-3 3 1.437.438 2.562 1.563 3 3 .438-1.437 1.563-2.561 3-3-1.437-.438-2.562-1.562-3-3zm-19.001 2c-.292.957-1.042 1.708-1.999 2 .959.293 1.707 1.042 2.001 2 .292-.958 1.042-1.708 1.999-2-.957-.292-1.707-1.042-2.001-2zm18.5 16c-.365 1.196-1.303 2.134-2.499 2.5 1.199.366 2.134 1.303 2.501 2.5.365-1.197 1.303-2.134 2.499-2.5-1.196-.364-2.134-1.303-2.501-2.5zm-9.499-19c-.292.958-1.042 1.708-2 2 .959.293 1.708 1.042 2.002 2 .292-.958 1.042-1.708 1.998-2-.956-.292-1.706-1.042-2-2zm-8.001 18c-.435 1.436-1.563 2.561-2.999 3 1.439.439 2.564 1.564 3 3 .439-1.436 1.564-2.561 2.999-3-1.435-.437-2.56-1.564-3-3zm14.063-12h-10.054l-5.008 5.625 10 12.375 10-12.305-4.938-5.695zm-9.394 6l3.039 7.218-5.832-7.218h2.793zm7.579 0l-3.247 7.711-3.247-7.711h6.494zm-6.04-1l2.793-2.793 2.793 2.793h-5.586zm7.125 1h2.843l-5.899 7.259 3.056-7.259zm2.418-1h-2.543l-3-3h2.942l2.601 3zm-10.846-3h2.889l-3 3h-2.559l2.67-3z"/></svg>
                                </span>

                                {achievement.status == 'reserved' && 
                                    <div className="status">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18.513 7.119c.958-1.143 1.487-2.577 1.487-4.036v-3.083h-16v3.083c0 1.459.528 2.892 1.487 4.035l3.087 3.68c.566.677.57 1.625.009 2.306l-3.13 3.794c-.937 1.136-1.453 2.555-1.453 3.995v3.107h16v-3.107c0-1.44-.517-2.858-1.453-3.994l-3.13-3.794c-.562-.681-.558-1.629.009-2.306l3.087-3.68zm-.513-4.12c0 1.101-.363 2.05-1.02 2.834l-.978 1.167h-8.004l-.978-1.167c-.66-.785-1.02-1.736-1.02-2.834h12zm-.996 15.172c.652.791.996 1.725.996 2.829h-1.061c-1.939-2-4.939-2-4.939-2s-3 0-4.939 2h-1.061c0-1.104.344-2.039.996-2.829l3.129-3.793c.342-.415.571-.886.711-1.377h.164v1h2v-1h.163c.141.491.369.962.711 1.376l3.13 3.794zm-6.004-1.171h2v1h-2v-1zm0-2h2v1h-2v-1z"/></svg>
                                        <span>{codeToStatusMap[achievement.code]}</span>
                                    </div>
                                }

                            </div>
                        );
                    
                    })]}

                </div>

            </section>

        </>

    );

}

export default function Profile(props) {

    const [editFormType, setEditFormType] = useState(null);
    const { user } = useContext(AuthContext);
    const [optionMap, setOptionMap] = useState({});
    const [bookings, setBookings] = useState(null);

    // get level information
    const [currentLevel, setCurrentLevel] = useState(null);
    const [nextLevel, setNextLevel] = useState(null);

    const location = useLocation();

    const request = {
        options: {
            endpoint: "/api/v1/activities/options",
            method: "GET",
        },
        bookings: {
            endpoint: "/api/v1/bookings",
            method: "GET",
        },
        levels: {
            endpoint : "/api/v1/wellbits/levels",
            method: "GET",
        }
    }
    const [data, loading, error] = useFetch(request);

    const switcherOptions = [{
        to: "/hub/profile", 
        text: 'Overzicht',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/></svg>
    }, {
        to: "/hub/chats", 
        text: 'Boekingen',
        icon: <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fillRule="evenodd" clipRule="evenodd"><path d="M24 20h-3v4l-5.333-4h-7.667v-4h2v2h6.333l2.667 2v-2h3v-8.001h-2v-2h4v12.001zm-6-6h-9.667l-5.333 4v-4h-3v-14.001h18v14.001z"/></svg>
    }, {
        to: "/hub/wellbits",
        text: 'WellBits',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 1c-.438 1.438-1.563 2.562-3 3 1.437.438 2.562 1.563 3 3 .438-1.437 1.563-2.561 3-3-1.437-.438-2.562-1.562-3-3zm-19.001 2c-.292.957-1.042 1.708-1.999 2 .959.293 1.707 1.042 2.001 2 .292-.958 1.042-1.708 1.999-2-.957-.292-1.707-1.042-2.001-2zm18.5 16c-.365 1.196-1.303 2.134-2.499 2.5 1.199.366 2.134 1.303 2.501 2.5.365-1.197 1.303-2.134 2.499-2.5-1.196-.364-2.134-1.303-2.501-2.5zm-9.499-19c-.292.958-1.042 1.708-2 2 .959.293 1.708 1.042 2.002 2 .292-.958 1.042-1.708 1.998-2-.956-.292-1.706-1.042-2-2zm-8.001 18c-.435 1.436-1.563 2.561-2.999 3 1.439.439 2.564 1.564 3 3 .439-1.436 1.564-2.561 2.999-3-1.435-.437-2.56-1.564-3-3zm14.063-12h-10.054l-5.008 5.625 10 12.375 10-12.305-4.938-5.695zm-9.394 6l3.039 7.218-5.832-7.218h2.793zm7.579 0l-3.247 7.711-3.247-7.711h6.494zm-6.04-1l2.793-2.793 2.793 2.793h-5.586zm7.125 1h2.843l-5.899 7.259 3.056-7.259zm2.418-1h-2.543l-3-3h2.942l2.601 3zm-10.846-3h2.889l-3 3h-2.559l2.67-3z"/></svg>,
        className: "wellbits",
    }];

    if (['admin', 'editor'].includes(user.type)) {
        switcherOptions.splice(2, 0, {
            to: "/hub/financial", 
            text: 'Financiën',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 8v-2c0-1.104.896-2 2-2h20c1.104 0 2 .896 2 2v2h-24zm24 3v7c0 1.104-.896 2-2 2h-20c-1.104 0-2-.896-2-2v-7h24zm-15 5h-6v1h6v-1zm3-2h-9v1h9v-1zm9 0h-3v1h3v-1z"/></svg>
        })
    }
    
    if (user.type == 'admin') {
        switcherOptions.push({
            to: "/hub/admin",
            text: 'Admin',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 2c1.654 0 3 1.346 3 3v14c0 1.654-1.346 3-3 3h-14c-1.654 0-3-1.346-3-3v-14c0-1.654 1.346-3 3-3h14zm5 3c0-2.761-2.238-5-5-5h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14zm-13 12h-2v3h-2v-3h-2v-3h6v3zm-2-13h-2v8h2v-8zm10 5h-6v3h2v8h2v-8h2v-3zm-2-5h-2v3h2v-3z"/></svg>
        });
    }

    useEffect(() => {

        if (!loading) {

            if (data.options && data.options.data) {
                const newOptionMap = {};
                for (const option of data.options.data) {
                   newOptionMap[option.id] = option;
                }
                setOptionMap(newOptionMap);
            }
    
            if (data.bookings && data.bookings.data) {
                setBookings(data.bookings.data);
            }

            if (data.levels && data.levels.data) {

                const levels = data.levels.data;
                for (let i = 0; i < levels.length; i++) {
                    if (user.num_bits >= levels[i].num_bits && (user.num_bits < levels[i + 1].num_bits || i == levels.length - 1)) {
                        setCurrentLevel(levels[i]);
                        setNextLevel(i == levels.length - 1 ? null : levels[i + 1]);
                        break;
                    }
                }

            }

        }

    }, [loading]);


    return (

        <div id="profile" className="container">

            <section id="profile-name" className="first">

                <div id="profile-image">

                    {!user.profile_image_path &&
                        <div id="profile-image-placeholder">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/></svg>
                        </div>    
                    }

                </div>

                <div id="profile-name-text">

                    <h3
                        onClick={() => {setEditFormType('name')}}
                        >
                        <span>{user.first_name}&nbsp;</span>
                        <span>{user.last_name}</span>
                        <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m19 20.25c0-.402-.356-.75-.75-.75-2.561 0-11.939 0-14.5 0-.394 0-.75.348-.75.75s.356.75.75.75h14.5c.394 0 .75-.348.75-.75zm-12.023-7.083c-1.334 3.916-1.48 4.232-1.48 4.587 0 .527.46.749.749.749.352 0 .668-.137 4.574-1.493zm1.06-1.061 3.846 3.846 8.824-8.814c.195-.195.293-.451.293-.707 0-.255-.098-.511-.293-.706-.692-.691-1.742-1.741-2.435-2.432-.195-.195-.451-.293-.707-.293-.254 0-.51.098-.706.293z" fillRule="nonzero"/></svg>
                    </h3>

                    <span id="profile-badges">
                        {currentLevel &&
                            <span>Level {currentLevel.level} • {currentLevel.title}</span>
                        }
                    </span>
                
                </div>

            </section>

            <div id="profile-switcher">
                {switcherOptions.map((link, key) => {
                    return (
                        <Link
                            className={(location.pathname.startsWith(link.to) ? "active" : "") + (link.className ? " " + link.className : "")}
                            to={link.to}>
                            {link.icon}
                            {link.text}
                        </Link>
                    )
                })
                }
            </div>

            <div
                style={{
                    display: location.pathname.startsWith('/hub/profile') ? 'block' : 'none'
                }}
                >

                    <section id="profile-wallets">

                        <h3>Wallets.</h3>

                        <p>Beheer je tegoed en WellBits-saldo.</p>

                        <Wallet 
                            user={user}
                            balance={user.num_tokens}
                            tag={"Tegoed"}
                            // href="#"
                            // hrefText="Ophogen"
                            />

                        <Wallet 
                            user={user}
                            balance={user.num_bits}
                            tag={"WellBits"}
                            href="/hub/wellbits"
                            hrefText="Bekijken"
                            bits
                            />

                    </section>

                    <Listings
                        optionMap={optionMap}
                        user={user} />

                    <Favorites />

                    <section 
                        id="profile-edit-buttons"
                        className="last"
                        >

                        <h3>Gegevens.</h3>
                        <p>Beheer je informatie.</p>

                        {[
                            {name: "Voor- en achternaam", type: 'name', icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/></svg>},
                            {name: "Beveiliging", type: 'password', icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 8v-2c0-3.313 2.687-6 6-6 3.312 0 6 2.687 6 6v2h-2v-2c0-2.206-1.795-4-4-4s-4 1.794-4 4v2h-2zm-3 2v14h18v-14h-18z"/></svg>},
                            {name: "Financiële gegevens", type: 'financial', icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 16.947v1.053h-1v-.998c-1.035-.018-2.106-.265-3-.727l.455-1.644c.956.371 2.229.765 3.225.54 1.149-.26 1.384-1.442.114-2.011-.931-.434-3.778-.805-3.778-3.243 0-1.363 1.039-2.583 2.984-2.85v-1.067h1v1.018c.724.019 1.536.145 2.442.42l-.362 1.647c-.768-.27-1.617-.515-2.443-.465-1.489.087-1.62 1.376-.581 1.916 1.712.805 3.944 1.402 3.944 3.547.002 1.718-1.343 2.632-3 2.864z"/></svg>}
                        ].map((button, key) => {

                            if (user.type == 'user' && button.type == 'financial') {
                                return <></>;
                            }

                            return (
                                <Button
                                    key={key}
                                    name={button.name}
                                    customIcon={button.icon}
                                    onClick={() => {
                                        setEditFormType(button.type)
                                    }}
                                    />
                            )
                        })}

                    </section>

            </div>

            <div
                style={{
                    display: location.pathname.startsWith('/hub/chats') ? 'block' : 'none'
                }}
                >

                <section id="profile-chats" className="last">

                    <h3>Chats.</h3>
                    <p>Beheer je boekingen via de onderstaande chats.</p>

                    <Chat
                        user={user}
                        bookings={bookings}
                        />
                
                </section>

            </div>

            <div
                style={{
                    display: location.pathname.startsWith('/hub/wellbits') ? 'block' : 'none'
                }}
                >
                
                <Achievements
                    user={user}
                    currentLevel={currentLevel}
                    nextLevel={nextLevel}
                    />
                
            </div>

            {['admin', 'editor'].includes(user.type) &&
                <div
                    style={{
                        display: location.pathname.startsWith('/hub/financial') ? 'block' : 'none'
                    }}
                    >

                    <ProfileFinance />

                </div>
            }

            {user.type == 'admin' &&
                <div
                    style={{
                        display: location.pathname.startsWith('/hub/admin') ? 'block' : 'none'
                    }}
                    >

                    <ProfileAdmin />

                </div>
            }

            {editFormType !== null && 
                <EditProfile
                    type={editFormType}
                    user={user}
                    closeFormFn={() => { setEditFormType(null)}}
                    />
            }

        </div>

    );

}