import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";

export default function ReferralPage() {

   const { path } = useParams();
   const navigate = useNavigate();

   useEffect(() => {
      navigate(`/signup?referral=${path}`, {replace: true} );
   }, [path]);

   return;

}