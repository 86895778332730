import React, { createContext, useContext, useEffect, useState } from 'react';

import useTokenStorage from '../hooks/useTokenStorage';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {

   const [getTokens, saveToken, removeToken, clearTokens, getToken, getTokenExpriy, refreshToken, newTokenObtained] = useTokenStorage();
   const [user, setUser] = useState({});

   const login = async (data) => {

      await clearTokens();
      saveToken('refresh_token', data.refresh_token, Date.now() + data.refresh_token_expires * 1000);
      saveToken('access_token', data.access_token, Date.now() + data.access_token_expires * 1000);
      if (data.user_type) {
         saveToken('user_type', data.user_type, null);
      }
      updateUser(data.access_token);    

   };

   const logout = () => {
      setUser({});
      clearTokens();
   };

   const updateUser = (accessToken) => {

      const headers = {
         'Authorization': 'Bearer ' + accessToken,
      }

      fetch('/api/v1/user', { headers: headers})
         .then(response => response.json())
         .then(data => {
            if (data.data) {
               setUser(data.data);
            }
         });

   }

   useEffect(() => {

      const accessToken = getToken('access_token');
      if (accessToken) {
         updateUser(accessToken);
      }

   }, []);

   return (
      <AuthContext.Provider value={{ user, login, logout }}>
         {children}
      </AuthContext.Provider>
   );

};

function useAuth() {
   return useContext(AuthContext);
}

export { AuthProvider, useAuth };
