import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import Ad from '../components/Ad';
import { AuthContext } from '../contexts/AuthContext';
import MapTile from '../components/MapTile';
import Button from '../components/inputs/Button';
import Checkbox from '../components/inputs/Checkbox';
import FavoriteButton from '../components/inputs/FavoriteButton';
import Form from '../components/inputs/Form';
import Input from '../components/inputs/Input';
import Offerings from '../components/Offerings';
import Rating from '../components/Rating';
import Select from '../components/inputs/Select';
import { useNotifications } from '../contexts/NotificationContext';
import useFetch from '../hooks/useFetch';
import useTokenStorage from '../hooks/useTokenStorage';

import './ActivityPage.scss';


function ActivityImages(props) {

   const [active, setActive] = useState(null); 
   const [images, setImages] = useState([]);

   const setActiveImage = (e) => {
      setActive(parseInt(e.target.getAttribute('data-key')));
   }

   useEffect(() => {

      setImages([...Array(4).keys()].map((value, key) => {
         if (value < props.images.length) {

            return (
               <div className={"img-container" + (active == key ? " show" : "")}
                  key={key}>

                  {active == key &&  
                     <span className="scroll-image left"
                        onClick={() => { setActive(key - 1); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>
                     </span>
                  }

                  <img  
                     src={props.images[value]} 
                     data-key={key}
                     onClick={active !== key ? (() => { setActive(key) }) : null}
                     className={active === key ? "active" : ""}/>
                  
                  {(active == key && props.images.length > key + 1) && 
                     <span className="scroll-image right"
                        onClick={() => { setActive(key + 1) }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>
                     </span>
                  }

                  {active == key && 
                     <span
                        className="shrink-image"
                        onClick={() => { setActive(null); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
                     </span>
                  }

               </div>
            );
         }
         
         return (
            <div key={key} className="camera-placeholder">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 4h-3v-1h3v1zm8 6c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm11-5v17h-24v-17h5.93c.669 0 1.293-.334 1.664-.891l1.406-2.109h8l1.406 2.109c.371.557.995.891 1.664.891h3.93zm-19 4c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1zm13 4c0-2.761-2.239-5-5-5s-5 2.239-5 5 2.239 5 5 5 5-2.239 5-5z"/></svg>
            </div>
         )

      }))

   }, [props.images, active]);

   return (
      <>
         <div id="info-images" className={"box " + (active !== null ? " pop-out" : "")}>

            {active !== null &&
               <>
                  <div className={"others"}>
                     {images}
                  </div>
                  <div id="close-images" onClick={() => { setActive(null); }}></div>
               </>}

            <div className={"others" + (active !== null ? " active" : "")}>
               
               {images}
            
            </div>

            {props.children}

            <div className={"placeholder img-placeholder"}></div>

         </div>
      </>
   )

}

function EditActivityButton(props) {

   return (
      <div 
         className={"edit-activity-btn" + (props.required ? " required": "")}
         onClick={props.onClick ? props.onClick : null}
         >
            {props.required && <span>Ontbrekend</span>}
            <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4.481 15.659c-1.334 3.916-1.48 4.232-1.48 4.587 0 .528.46.749.749.749.352 0 .668-.137 4.574-1.492zm1.06-1.061 3.846 3.846 11.321-11.311c.195-.195.293-.45.293-.707 0-.255-.098-.51-.293-.706-.692-.691-1.742-1.74-2.435-2.432-.195-.195-.451-.293-.707-.293-.254 0-.51.098-.706.293z" fillRule="nonzero"/></svg>
      </div>
   );

}

function EditActivityForm(props) {

   const { path } = useParams();
   const [shown, setShown] = useState(false);
   const [fadingOut, setFadingOut] = useState(false);
   
   const [warnings, setWarnings] = useState([]);

   // opening hours
   const [openingHours, setOpeningHours] = useState({});

   // city retrieval
   const housenumberRef = useRef(null);
   const zipRef = useRef(null);
   const streetRef = useRef(null);
   const cityRef = useRef(null);

   const [getTokens, saveToken, removeToken, clearTokens, getToken, getTokenExpriy, refreshToken] = useTokenStorage();

   // images
   const [currentImages, setCurrentImages] = useState([]);
   const [newImages, setNewImages] = useState([]);
   const [removedImages, setRemovedImages] = useState([]);

   // defaults
   const [defaultActivityOptions, setDefaultActivityOptions] = useState([]);

   const navigate = useNavigate();
   
   const { addNotification } = useNotifications();

   const [defaultCity, setDefaultCity] = useState(null);

   useEffect(() => {
      setShown(props.shown);
      setCurrentImages(props.values.imagePaths);
      setOpeningHours(props.values.openingHours);
      const newDefaultActivityOptions = [];
      for (const option of props.values.options) {
         newDefaultActivityOptions.push(option.value);
      }
      setDefaultActivityOptions(newDefaultActivityOptions);
      setDefaultCity(props.values.city);
   }, [props]);

   const closeForm = (e) => {

      setFadingOut(true);
      setTimeout(() => {
         props.closeFormFn();
         setFadingOut(false);
      }, 350);

   }

   const updateOpeningHours = (day, part, value) => {

      const newOpeningHours = {...openingHours};
      if (!value) {
         const otherPart = part === "start" ? "end" : "start"; 
         if (day in newOpeningHours && newOpeningHours[day][otherPart] === undefined) {
            delete newOpeningHours[day];
         } else if (day in newOpeningHours && newOpeningHours[day][otherPart] !== undefined) {
            delete newOpeningHours[day][part];
         }
      } else {
         if (!(day in newOpeningHours)) {
            newOpeningHours[day] = {};
         }
         newOpeningHours[day][part] = value;
      }

      setOpeningHours(newOpeningHours);

   }

   const retrieveCity = () => {

      const housenumber = housenumberRef.current.value;
      const zip = zipRef.current.value;
      const street = streetRef.current.value;

      if (housenumber && zip && street) {

         const formData = new FormData();
         formData.append('housenumber', housenumber);
         formData.append('street', street);
         formData.append('zip', zip);
         const requestOptions = {
            method: 'POST',
            body: formData,
            headers: {},
         };

         const access_token = getToken('access_token');
         const access_token_expiry = getTokenExpriy('access_token');
         const refresh_token_expiry = getTokenExpriy('refresh_token');

         if (access_token && access_token_expiry && refresh_token_expiry) {
            if (Date.now() > access_token_expiry && Date.now() <= refresh_token_expiry) {
               refreshToken().then(() => {
                  continueWithFetch();
               });
            } else if (Date.now() + 5 > refresh_token_expiry) {
               navigate('/login');
            } else {
               continueWithFetch();
            }
         } else {
            continueWithFetch();
         }

         function continueWithFetch() {
            const new_access_token = getToken('access_token');

            if (new_access_token) requestOptions.headers['Authorization'] = 'Bearer ' + new_access_token;

            fetch('/api/v1/address/geocode', requestOptions)
               .then(response => response.json())
               .then(data => {

                  if (data.data) {
                     if (data.data.city) {
                        setDefaultCity(data.data.city);
                        cityRef.current.classList.remove('error');
                        const error = cityRef.current.parentElement.nextElementSibling;
                        if (error.classList.contains('error-message')) {
                           error.remove();
                        }
                     } else {
                        setDefaultCity('Geen geldige plaatsnaam gevonden');
                        cityRef.current.classList.add('error');
                     }
                     
                  }

               });
         }


      }

   }

   const onSubmit = (e, data) => {

      if ('data' in data) {
         if (props.type == 'description') {
            
            props.values.setDescription(document.getElementById('edit-form-description').value);
            addNotification('Je listing is succesvol gewijzigd.');

         } else if (props.type == 'location') {
            
            props.values.setZip(document.getElementById('edit-form-zip').value);
            props.values.setHouseNumber(document.getElementById('edit-form-housenumber').value);
            props.values.setStreet(document.getElementById('edit-form-street').value);
            props.values.setClosestParking(document.getElementById('edit-form-closest-parking-spot').value);
            props.values.setClosestPublicTransport(document.getElementById('edit-form-closest-public-transport').value);
            addNotification('Je listing is succesvol gewijzigd.');

         } else if (props.type == 'attributes') {
            
            const attributes = [];
            for (const attribute of data.data.attributes) {
               attributes.push(attribute)
            }
            props.values.setAttributes(attributes);
            addNotification('Je listing is succesvol gewijzigd.');

         } else if (props.type == 'people_price') {
            props.values.setName(document.getElementById('edit-form-name').value);
            props.values.setShortDescription(document.getElementById('edit-form-short-description').value);
            props.values.setType(document.getElementById('edit-form-type-value').value);
            props.values.setOpeningHours({...openingHours});

            const newOptions = [];
            for (const option of document.getElementById('edit-form-options-value').value.split(";")) {
               newOptions.push({
                  value: option,
                  name: props.values.optionMap[option].pretty_name,
               })
            }
            props.values.setOptions(newOptions);

            addNotification('Je listing is succesvol gewijzigd.');


         } else if (props.type == 'images') {

            // update displayed images
            let paths = [];
            for (const image of data.data.images) {
               paths.push('/images/' + image.image_path.replace(".png", ".jpg").replace(".jpg", "_1000.jpg"));
            }
            setCurrentImages(paths);
            props.values.setImagePaths(paths);

            // reset temporary variables
            setNewImages([]);
            setRemovedImages([]);

            addNotification('Je listing is succesvol gewijzigd.');

         } else if (props.type == 'offerings') {
            const newOfferingIds = data.data.inserted_ids;
            const newOfferings = [];
            const offeringsUpdates = JSON.parse(document.getElementById("offerings-value").value);

            // update existing offerings
            for (const offering of props.values.offerings) {
               let offeringUpdateFound = false;
               for (const offeringUpdate of offeringsUpdates) {
                  if (offering.id == offeringUpdate) {
                     offeringUpdateFound = true;
                     newOfferings.push(offeringUpdate);
                     break;  
                  }
               }
               if (!offeringUpdateFound) {
                  newOfferings.push(offering);
               }
            }

            // insert or delete offerings
            let newOfferingIdPtr = 0;
            for (const offeringUpdate of offeringsUpdates) {
               if (offeringUpdate.delete) {
                  for (let i = 0; i < newOfferings.length; i++) {
                     if (newOfferings[i].id = offeringUpdate.id) {
                        newOfferings.splice(i, 1);
                        break;
                     }
                  }
               } else if (!offeringUpdate.id) {
                  offeringUpdate.id = newOfferingIds[newOfferingIdPtr];
                  newOfferings.push(offeringUpdate);
                  newOfferingIdPtr++;
               }
            }
            props.values.setOfferings(newOfferings);

            addNotification('Je listing is succesvol gewijzigd.');

         } else if (props.type == 'user_offerings') {

            // navigate to new booking
            if (data.data && data.data.chat_id) {
               navigate('/hub/chats/' + data.data.chat_id);
            }

            addNotification(`Je boeking is ontvangen door de aanbieder.`);

         }

         closeForm();
      }

   }

   if (!shown) return;

   let formComponents;
   if (props.type == 'images') {

      const handleImageUpload = async (e) => {
         if (e.target.files && e.target.files.length > 0) {
           const { maxImages } = props.values;
           const allowedImagesCount = maxImages - currentImages.length;
           const selectedFiles = Array.prototype.slice.call(e.target.files, 0, allowedImagesCount);
       
           const newWarnings = [];
           const uploadedImages = [];
       
           const promises = selectedFiles.map((file) => {
             return new Promise((resolve, reject) => {
               const image = new Image();
               image.onload = () => {
                 const imageName = file.name;
                 let hasWarning = false;
       
                 if (image.width < 500 || image.height < 500) {
                   newWarnings.push(`Het formaat van afbeelding ${imageName} moet minimaal 500x500 zijn.`);
                   hasWarning = true;
                 }
       
                 if (!hasWarning && (image.width > 2000 || image.height > 2000)) {
                   newWarnings.push(`Het formaat van afbeelding ${imageName} mag maximaal 2000x2000 zijn.`);
                   hasWarning = true;
                 }
       
                 const imageSize = file.size / 1024;
                 if (!hasWarning && imageSize > 2048) {
                   newWarnings.push(`Het formaat van bestand ${imageName} mag maximaal 2MB zijn.`);
                 }
       
                 if (!hasWarning) {
                   uploadedImages.push(URL.createObjectURL(file));
                 }
       
                 resolve();

               };
       
               image.onerror = () => {
                 newWarnings.push(`Afbeelding ${file.name} niet geladen.`);
                 resolve();
               };
       
               image.src = URL.createObjectURL(file);
             });
           });
       
           await Promise.all(promises);

           setNewImages(uploadedImages);
           setWarnings(newWarnings);

         }
       };
       
      const remainingImages = props.values.maxImages - currentImages.length - newImages.length;

      formComponents = 
         <>
            <h3>Afbeeldingen<span className="stress">.</span></h3>
            <p>Kies vier afbeeldingen die een goede indruk geven van de activiteit.</p>
            <div id="edit-activity-images-container">
               {currentImages.map((value, key) => {

                  return (
                     <label 
                        key={key}
                        className={"edit-activity-image"}>
                        <img src={value} />
                        
                        <svg 
                           onClick={() => {
                              const newCurrentImages = [...currentImages];
                              const removedImage = newCurrentImages.splice(key, 1)[0];
                              if (props.values.imagePaths.includes(removedImage)) {
                                 setRemovedImages((previousState) => [...previousState, removedImage]);
                               }
                              setCurrentImages(newCurrentImages);
                           }}
                           className="remove-image"
                           xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>

                           {key > 0 &&
                              <svg 
                                 className="move-image left"
                                 onClick={() => {
                                    const newCurrentImages = [...currentImages];
                                    [newCurrentImages[key - 1], newCurrentImages[key]] = [newCurrentImages[key], newCurrentImages[key - 1]];
                                    setCurrentImages(newCurrentImages);
                                 }}
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg> 
                           }

                           {key < currentImages.length - 1 &&
                              
                              <svg 
                                 className="move-image right"
                                 onClick={() => {
                                    const newCurrentImages = [...currentImages];
                                    [newCurrentImages[key + 1], newCurrentImages[key]] = [newCurrentImages[key], newCurrentImages[key + 1]];
                                    setCurrentImages(newCurrentImages);
                                 }}
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                                 </svg>
                           }

                     </label>
                  );
               })}
               {newImages.map((value, key) => {
                  return (
                     <label 
                        key={key}
                        className={"edit-activity-image upload"}>
                        <img src={value} />
                        
                     </label>
                  );
               })}
               {remainingImages > 0 &&
                  [...Array(remainingImages).keys()].map((value, key) => {
                     return (
                        <label key={key} 
                           className='edit-activity-image inactive'>
                              <svg className="add-image" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z"/></svg>
                        </label>
                     ); 
                  })
               }
               <label
                  id="edit-activity-images-upload"
                  className={remainingImages == 0 ? "disabled" : ""}
                  htmlFor={remainingImages == 0 ? "" : "edit-activity-images"}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 8h-4l7-8 7 8h-4v9h-6v-9zm12 11v5h-18v-5h18zm-6 2h-1v1h1v-1zm2 0h-1v1h1v-1zm2 0h-1v1h1v-1z"/></svg>
                     Afbeeldingen selecteren
               </label>
               {warnings.length > 0 && (
                  <div id="edit-activity-images-warnings">
                     <ul>
                        {warnings.map((warning, index) => (
                        <li key={index}>{warning}</li>
                        ))}
                     </ul>
                  </div>
               )}
               <input
                  id="edit-activity-images"
                  name="images"
                  type="file"
                  onChange={handleImageUpload}
                  accept="image/png,image/jpeg"
                  multiple
                  />

               <input 
                  id="edit-activity-images-order" 
                  type="hidden"
                  name="image-order"
                  value={currentImages}
                  />
               <input 
                  id="edit-activity-images-removed"
                  type="hidden"
                  name="images-removed"
                  value={removedImages}
                  />

            </div>
         </>

   } else if (props.type == 'description') {
      formComponents = 
         <>
            <h3>Omschrijving<span className="stress">.</span></h3>
            <p>Schrijf een pakkende beschrijving van maximaal 1500 tekens.</p>
            
            <Input   
               id="edit-form-description"
               type="text"
               placeholder="Beschrijf je listing"
               name="description"
               maxLength="1500"
               defaultValue={props.values.description}
               multiline
               noContactInfo
               />
         </>
   } else if (props.type == 'location') {
      formComponents = 
         <>
            <h3>Bereikbaarheid<span className="stress">.</span></h3>
            <p>Beschrijf waar gasten kunnen parkeren en bijvoorbeeld waar de dichtstbijzijnde bus, tram of trein stopt.</p>

            <h4>Adres.</h4>

            <div className="two-inputs">

               <Input
                  type="text"
                  id="edit-form-housenumber"
                  name="housenumber"
                  innerRef={housenumberRef}
                  placeholder="Huisnummer"
                  example="Bijv. 1A"
                  maxLength="8"
                  defaultValue={props.values.houseNumber}
                  onBlur={retrieveCity}
                  icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22.548 9l.452-2h-5.364l1.364-6h-2l-1.364 6h-5l1.364-6h-2l-1.364 6h-6.184l-.452 2h6.182l-1.364 6h-5.36l-.458 2h5.364l-1.364 6h2l1.364-6h5l-1.364 6h2l1.364-6h6.185l.451-2h-6.182l1.364-6h5.366zm-8.73 6h-5l1.364-6h5l-1.364 6z"/></svg>}
                  required
               />

               <Input
                  type="text"
                  id="edit-form-zip"
                  name="zip"
                  innerRef={zipRef}
                  placeholder="Postcode"
                  example="Bijv. 1234 AB"
                  maxLength="8"
                  defaultValue={props.values.zip}
                  onBlur={retrieveCity}
                  icon={<svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M24 23h-24v-13.275l2-1.455v-7.27h20v7.272l2 1.453v13.275zm-20-10.472v-9.528h16v9.527l-8 5.473-8-5.472zm14-.528h-12v-1h12v1zm0-3v1h-12v-1h12zm-7-1h-5v-3h5v3zm7 0h-6v-1h6v1zm0-2h-6v-1h6v1z"/></svg>}
                  required
               />

            </div>
            
            <Input
               id="edit-form-street"
               name="street"
               innerRef={streetRef}
               type="text"
               maxLength="70"
               example="Bijv. Kerkstraat"
               placeholder="Straatnaam"
               defaultValue={props.values.street}
               onBlur={retrieveCity}
               icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M9 20h-3v3h-4v-8.766l5.698-4.921 1.711 1.384 6.591-5.697 6 5.236v12.764h-5v-4h-3v4h-5v-3zm-2-5h-2v2h2v-2zm3 0h-2v2h2v-2zm5-1h-2v2h2v-2zm3 0h-2v2h2v-2zm-8.642-7.253l6.642-5.747 8 7-1.329 1.495-6.671-5.819-6.624 5.738-1.678-1.414-6.369 5.495-1.329-1.495 7.698-6.676 1.66 1.423zm5.642 4.253h-2v2h2v-2zm3 0h-2v2h2v-2z"/></svg>}
               required
               />

            <Input
               id="edit-form-city"
               type="text"
               innerRef={cityRef}
               placeholder="Plaatsnaam"
               defaultValue={defaultCity}
               icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M9 20h-3v3h-4v-8.766l5.698-4.921 1.711 1.384 6.591-5.697 6 5.236v12.764h-5v-4h-3v4h-5v-3zm-2-5h-2v2h2v-2zm3 0h-2v2h2v-2zm5-1h-2v2h2v-2zm3 0h-2v2h2v-2zm-8.642-7.253l6.642-5.747 8 7-1.329 1.495-6.671-5.819-6.624 5.738-1.678-1.414-6.369 5.495-1.329-1.495 7.698-6.676 1.66 1.423zm5.642 4.253h-2v2h2v-2zm3 0h-2v2h2v-2z"/></svg>}
               required
               readOnly
            />
            
            <h4>Voorzieningen.</h4>

            <Input   
               id="edit-form-closest-parking-spot"
               type="text"
               placeholder="Parkeerplek"
               name="closest_parking_spot"
               maxLength="100"
               example="Bijv. parkeren in de straat"
               defaultValue={props.values.closestParking}
               icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-5.523 0-10 4.394-10 9.815 0 5.505 4.375 9.268 10 14.185 5.625-4.917 10-8.68 10-14.185 0-5.421-4.478-9.815-10-9.815zm0 18c-4.419 0-8-3.582-8-8s3.581-8 8-8c4.419 0 8 3.582 8 8s-3.581 8-8 8zm1.08-9.204c0 .745-.549 1.008-1.293 1.008h-.463v-1.979h.64c.705 0 1.116.256 1.116.971zm3.92-1.713v5.833c0 1.151-.933 2.084-2.083 2.084h-5.834c-1.15 0-2.083-.933-2.083-2.083v-5.834c0-1.15.933-2.083 2.083-2.083h5.833c1.151 0 2.084.933 2.084 2.083zm-2.5 1.663c0-.69-.21-1.209-.628-1.557-.42-.348-1.031-.522-1.836-.522h-2.119v6.667h1.407v-2.371h.604c.823 0 1.457-.19 1.903-.57.446-.381.669-.93.669-1.647z"/></svg>               }
               onBlurCorrect onBlurCapitalize
               />

            <Input   
               id="edit-form-closest-public-transport"
               type="text"
               placeholder="Openbaar vervoer"
               name="closest_public_transport"
               example="Bijv. bushalte op 5 min."
               maxLength="100"
               defaultValue={props.values.closestPublicTransport}
               icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M6 24h-2c-.552 0-1-.448-1-1v-1c-.53 0-1.039-.211-1.414-.586s-.586-.884-.586-1.414v-8c-.552 0-1-.448-1-1v-3c0-.552.448-1 1-1v-4c0-1.657 1.343-3 3-3h16c1.657 0 3 1.343 3 3v4c.552 0 1 .448 1 1v3c0 .552-.448 1-1 1v8c0 .53-.211 1.039-.586 1.414s-.884.586-1.414.586v1c0 .552-.448 1-1 1h-2c-.552 0-1-.448-1-1v-1h-10v1c0 .552-.448 1-1 1zm-1.5-7c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm15 0c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm-5 1h-5c-.276 0-.5.224-.5.5s.224.5.5.5h5c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm6.5-12.5c0-.276-.224-.5-.5-.5h-17c-.276 0-.5.224-.5.5v8.5s3.098 1 9 1 9-1 9-1v-8.5zm-5-3.5h-8v1h8v-1z"/></svg>            }
               onBlurCorrect onBlurCapitalize
               />
         </>
   } else if (props.type == 'attributes') {
      let checkedAttributes = [];
      for (const attribute of props.values.attributes) {
         checkedAttributes.push(attribute.field);
      }

      props.values.uniqueAttributes.sort((a, b) => {
         if (a.category > b.category) {
            return 1;
         } else if (a.category < b.category) {
            return -1;
         }
         return 0;
      });

      const categoryPrettyNames = {
         food: 'Eten & drinken',
         prep: 'Voorbereiding',
         location: 'Ligging',
      }

      formComponents = 
         <>
            <h3>Kenmerken<span className="stress">.</span></h3>
            <p>Klik hieronder de faciliteiten aan die voor je listing van toepassing zijn.</p>
            
            <div className='checkboxes'>
               {props.values.uniqueAttributes.map((value, key) => {

                  let header = <></>;
                  if (key == 0 || props.values.uniqueAttributes[key - 1].category != value.category) {
                     header = <h4>{categoryPrettyNames[value.category]}</h4>;
                  }

                  return (
                     <>
                        {header}
                        <Checkbox
                           key={key}
                           id={"edit-form-attributes-" + value.id}
                           name={"attributes-" + value.field}
                           label={value.field_value}
                           checked={checkedAttributes.includes(value.field)}
                           icon={<div className="icon" dangerouslySetInnerHTML={{__html: value.icon}}></div>}
                        />
                     </>
                  );
               })}
            </div>

         </>
   } else if (props.type == 'people_price') {
       
      formComponents = 
         <>
            <h3>Over deze activiteit<span className="stress">.</span></h3>
            <p>Dit is de meest beknopte samenvatting van je listing.</p>

            <Input   
               id="edit-form-name"
               type="text"
               placeholder="Titel"
               name="location_name"
               maxLength="50"
               defaultValue={props.values.name}
               noContactInfo />

            <Input   
               id="edit-form-short-description"
               type="text"
               placeholder="Korte beschrijving"
               name="short_description"
               maxLength="200"
               defaultValue={props.values.shortDescription}
               multiline multilineSmall noContactInfo />

            <Select 
               id="edit-form-options"
               name="options"
               example="Bijv. coaching"
               placeholder="Aangeboden activiteiten"
               icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M23.769 14.359c-1.097 5.495-5.952 9.641-11.768 9.641-6.623 0-12-5.377-12-12s5.377-12 12-12c2.68 0 5.656 1.047 7.598 2.774-2.604-.958-5.138-.87-6.553-.626-3.951.757-6.731 3.32-7.972 6.232-2.447 5.743 1.359 10.347 5.599 10.343 2.746 0 5.152-1.853 6.583-4.202 1.099-1.802 2.308-2.388 3.187-2.357 1.259.044 2.089.566 3.326 2.195zm.231-2.541c-.981-.94-2.085-1.612-3.535-1.662-1.903-.065-3.726 1.37-4.916 3.323-1.007 1.652-2.444 2.795-3.941 3.136-3.359.765-6.683-2.785-4.694-7.451 3.461-8.121 13.861-4.826 14.826-3.618.798.999 2.219 3.515 2.26 6.272z"/></svg>}
               options={props.values.allOptions}
               defaultValue={defaultActivityOptions}
               className="z-index-select-9"
               max={5}
               explicitValidation
               required
               multi
               />

            <Select
               id="edit-form-type"
               type="text"
               placeholder="Type"
               name="type"
               defaultValue={props.values.type && props.values.type.split(";")}
               icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-8v-9h2v7h6v2z"/></svg>}
               options={[
                  {value: 'group', name: 'Groep'},
                  {value: 'individual', name: 'Individueel'},
                  {value: 'online', name: 'Online'}
               ]}
               zIndex={5}
               max={3}
               />

            <div className="checkbox-row">

               <span>Openingstijden</span>

               {[{name: 'mon', display: 'ma'}, {name: 'tue', display: 'di'}, {name: 'wed', display: 'wo'}, {name: 'thu', display: 'do'}, {name: 'fri', display: 'vr'}, {name: 'sat', display: 'za'}, {name: 'sun', display: 'zo'}].map((weekday, key) => {
                  return (
                     <div className="two-inputs" style={{zIndex: 1}}> 

                        <label 
                           htmlFor={`edit-form-start-time-${weekday.name}`}>{weekday.display}</label>

                        <Input   
                           id={`edit-form-start-time-${weekday.name}`}
                           type="text"
                           placeholder="Openingstijd"
                           example="Bijv. 09:00"
                           maxLength="5"
                           defaultValue={props.values.openingHours && props.values.openingHours[weekday.name] && props.values.openingHours[weekday.name].start ? props.values.openingHours[weekday.name].start : ''}
                           icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-8v-9h2v7h6v2z"/></svg>}
                           onBlur={(e, newValue, setValue) => { updateOpeningHours(weekday.name, 'start', newValue) }}
                           time
                           />
         
                        <Input   
                           id={`edit-form-end-time-${weekday.name}`}
                           type="text"
                           placeholder="Sluitingstijd"
                           example="Bijv. 18:00"
                           maxLength="5"
                           defaultValue={props.values.openingHours && props.values.openingHours[weekday.name] && props.values.openingHours[weekday.name].end ? props.values.openingHours[weekday.name].end : ''}
                           icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-8v-9h2v7h6v2z"/></svg>}
                           onBlur={(e, newValue, setValue) => { updateOpeningHours(weekday.name, 'end', newValue) }}
                           time
                           />
         
                     </div>

                  )
               })}

               <input 
                  id="edit-form-opening-hours"
                  type="hidden"
                  name="opening_hours"
                  value={JSON.stringify(openingHours)}
                  />

            </div>

         </>

   } else if (props.type == 'offerings' || props.type == 'user_offerings') {

      formComponents = 
      
      <>
         <h3>Arrangementen<span className="stress">.</span></h3>
         <p>Hier vind je de mogelijke arrangementen bij deze activiteit.</p>

         <Offerings
            offerings={props.values.offerings}
            edit={props.type == 'offerings'}
            defaultOptions={props.values.options}
            optionMap={props.values.optionMap}
            user={props.values.user}
         />

      </>

   }

   const noSubmit = (!props.values.user || Object.keys(props.values.user).length == 0);
   const cancelButton = noSubmit ? null : <Button name="Annuleren" onClick={closeForm} noIcon />;
   const submitBtnText = (props.type == 'user_offerings' ? 'Reserveren' : 'Opslaan');
   let formActionPath = (props.type == 'user_offerings' ? "/bookings" : (props.type == 'images' ? "/images" : ""))

   return (

      <>

         <div 
            id="close-edit-form"
            onClick={closeForm}
            ></div>


         <div id="edit-form-wrapper" className={fadingOut ? "fading-out" : ""}>
            
            <div id="edit-form">

               <div className="form-container">

                  <Form
                     id="edit-activity-form"
                     action={"/api/v1/activities/" + path + formActionPath}
                     submitBtnText={submitBtnText}
                     submitCallback={onSubmit}
                     additionalButton={cancelButton}
                     noSubmit={noSubmit}
                     >

                     {formComponents}

                  </Form>

                  {noSubmit &&
                     <p id="edit-form-not-loggedin">Deze activiteit boeken? <Link to={"/login?redirect=" + window.location.pathname}>Log hier in</Link>.</p>
                  }

               </div>
            
            </div>

         </div>

      </>
   )

}



export default function ActivityPage(props) {

   // properties
   const [name, setName] = useState('');
   const [id, setId] = useState('');
   const [city, setCity] = useState('');
   const [cityID, setCityID] = useState('');
   const [type, setType] = useState('');
   const [peopleMin, setPeopleMin] = useState(null);
   const [peopleMax, setPeopleMax] = useState(null);
   const [price, setPrice] = useState(null);
   const [openingHours, setOpeningHours] = useState({});
   const [zip, setZip] = useState(null);
   const [houseNumber, setHouseNumber] = useState(null);
   const [street, setStreet] = useState(null);
   const [closestParking, setClosestParking] = useState(null);
   const [closestPublicTransport, setClosestPublicTransport] = useState(null);
   const [attributes, setAttributes] = useState([]);
   const [description, setDescription] = useState('');
   const [shortDescription, setShortDescription] = useState('');
   const [imagePaths, setImagePaths] = useState([]);
   const [activities, setActivities] = useState('');
   const [offerings, setOfferings] = useState([]);
   const [isOwner, setIsOwner] = useState(false);
   const [availability, setAvailability] = useState([]);
   const [favorite, setFavorite] = useState(false);
   const [options, setOptions] = useState([]);

   // map
   const [lat, setLat] = useState(null);
   const [lon, setLon] = useState(null);
   const [marker, setMarker] = useState([]);
   const [zoom, setZoom] = useState(null);

   // status controls
   const [status, setStatus] = useState(null);
   const [deleteMode, setDeleteMode] = useState(false);

   // ratings
   const [ratings, setRatings] = useState([]);
   const [averageRating, setAverageRating] = useState(null);
   const [numRatings, setNumRatings] = useState(null);

   // options
   const [optionMap, setOptionMap] = useState({});
   const [allOptions, setAllOptions] = useState([]);

   const [datetimeNow, setDatetimeNow] = useState(Date());

   const [hot, setHot] = useState(false);
   const [excl, setExcl] = useState(false);
   const [url, setUrl] = useState(null);

   const [recommendedAds, setRecommendedAds] = useState([]);

   const [showRequest, setShowRequest] = useState(false);
   const toggleRequest = () => {
      setShowRequest(!showRequest);
   }

   const [editFormShown, setEditFormShown] = useState(false);
   const [editFormType, setEditFormType] = useState('');

   const [uniqueAttributes, setUniqueAttributes] = useState([]);

   const [getTokens, saveToken, removeToken, clearTokens, getToken, getTokenExpriy, refreshToken] = useTokenStorage();

   const [showOfferingsButton, setShowOfferingsButton] = useState(true);

   const { addNotification } = useNotifications();
   const { user } = useContext(AuthContext);

   const navigate = useNavigate();

   // request activity
   const { path } = useParams();
   const request = {
      activity: {
          endpoint: "/api/v1/activities/" + path + "?attributes=true",
          method: "GET",
      },
      attributes: {
         endpoint: "/api/v1/attributes",
         method: "GET",
     },
      recommended_activities: {
         endpoint: "/api/v1/activities?n=4&exclude=" + path,
         method: "GET",
      },
      options: {
         endpoint: "/api/v1/options",
         method: "GET",
      },
      favorites: {
         endpoint: "/api/v1/favorites",
         method: "GET",
      },
      ratings: {
         endpoint: "/api/v1/activities/" + path + "/ratings",
         method: "GET",
      }
   }
   if (window.location.pathname.split("/").at(-1) == "edit") {
      request.attributes = {
         endpoint: "/api/v1/attributes",
         method: "GET",
      }
   }

   const [data, loading, error] = useFetch(request);

   useEffect(() => {

      if (!loading) {
         
         const favoriteSet = new Set();
         if (data.favorites && data.favorites.data) {
            for (const favorite of data.favorites.data) {
               favoriteSet.add(favorite.activity_id);
            }
         }

         let ad;

         if (data.activity && data.activity.data) {

            ad = data.activity.data;
            setName(ad.location_name);
            setId(ad.id);
            setCity(ad.city_name);
            setCityID(ad.city);
            setLat(ad.lat ? ad.lat : 52.379189);
            setLon(ad.lon ? ad.lon : 4.899431);
            setMarker(ad.lat ? [{lat: ad.lat, lon: ad.lon}] : []);
            setZoom(ad.lat ? 13 : 6);
            setType(ad.type);
            setPeopleMin(ad.people_min);
            setPeopleMax(ad.people_max);
            setPrice(ad.price_person);
            setZip(ad.zip);
            setHouseNumber(ad.housenumber);
            setStreet(ad.street);
            setClosestParking(ad.closest_parking_spot);
            setClosestPublicTransport(ad.closest_public_transport);
            setDescription(ad.description);
            setShortDescription(ad.short_description);
            setActivities(ad.activities);
            setHot(ad.hot);
            setExcl(ad.excl);
            setUrl(ad.url);
            setOfferings(ad.offerings);
            setStatus(ad.status);
            setIsOwner(ad.is_owner);
            setOptions(ad.options);
            setAvailability(JSON.parse(ad.availability));
            setAverageRating(ad.rating);
            setNumRatings(ad.n_ratings);
            setFavorite(favoriteSet.has(ad.id));

            setOpeningHours(ad.opening_hours ? JSON.parse(ad.opening_hours) : {});

            let paths = [];
            if (ad.images) {
               for (const image of ad.images) {
                  paths.push('/images/' + image.image_path.replace(".png", ".jpg").replace(".jpg", "_1000.jpg"));
               }
            }
            setImagePaths(paths);

            if (ad.attributes) setAttributes(ad.attributes);

         } else {

            navigate('/activities');

         }

         if (data.recommended_activities && data.recommended_activities.data) {
            const newRecommendedAds = [];
            for (const activity of data.recommended_activities.data) {
               if (activity.id != id) {
                  newRecommendedAds.push(activity);
               }
            }
            setRecommendedAds(newRecommendedAds.slice(0, 3));
         }

         if (data.ratings && data.ratings.data) {
            setRatings(data.ratings.data);
         }

         if (ad && ad.location_name && ad.city) {
            document.title = "Boeken bij " + ad.location_name + (ad.city_name ? " " + ad.city_name : '') + " - You Be Well";
         };

         if (data.attributes && data.attributes.data) {
            setUniqueAttributes(data.attributes.data);
         }

         if (data.datetime && data.datetime.data) {
            setDatetimeNow(new Date(data.datetime.data));
         }

         if (data.options.data && data.activity.data) {

            const newOptionMap = {};
            for (const option of data.options.data) {
               newOptionMap[option.id] = option;
            }
            setOptionMap(newOptionMap);

            const newOptions = [];
            for (const option of data.activity.data.options.split(";")) {
               newOptions.push({
                  value: option,
                  name: newOptionMap[option].pretty_name,
               })
            }
            setOptions(newOptions);

            const newAllOptions = [];
            for (const option of data.options.data) {
               newAllOptions.push({value: option.id, name: option.pretty_name, category: option.category});
            }
            setAllOptions(newAllOptions);

         }

      }

   }, [loading]);

   useEffect(() => {
      const shownAds = document.getElementsByClassName('ad');
      for (const ad of shownAds) {
         ad.classList.add('show');
      }
   }, [recommendedAds]);

   return (
      <main id="ad" className={loading ? "loading": ""}>

         <section id="hero">

            <div className="container">
               <h1>
                  <span>
                     {name} <span className="stress">{city}</span>
                  </span>
               </h1>
            </div>
            
         </section>

         <section id="breadcrumb">
            <div className="container">

            {!loading && 
               <>
                  <Link 
                     to="/activities">
                        Overzicht
                     </Link>
                     <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m16.5 11.995c0-1.242 1.008-2.25 2.25-2.25s2.25 1.008 2.25 2.25-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25zm-6.75 0c0-1.242 1.008-2.25 2.25-2.25s2.25 1.008 2.25 2.25-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25zm-6.75 0c0-1.242 1.008-2.25 2.25-2.25s2.25 1.008 2.25 2.25-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25z"/></svg>
                  {city &&
                     <>
                        <Link 
                           to={"/activities?city=" + cityID}>
                              {city}
                           </Link>
                        <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m16.5 11.995c0-1.242 1.008-2.25 2.25-2.25s2.25 1.008 2.25 2.25-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25zm-6.75 0c0-1.242 1.008-2.25 2.25-2.25s2.25 1.008 2.25 2.25-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25zm-6.75 0c0-1.242 1.008-2.25 2.25-2.25s2.25 1.008 2.25 2.25-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25z"/></svg>
                     </>
                  }
                  <span>{name} {city}</span>
            
               </>
            }

            {loading && 
               <div className="placeholder"></div>
            }

            </div>

         </section>

         <section id="info" className="container">

            <div id="read-info">

               {isOwner && 
                  <EditActivityButton 
                     required={imagePaths.length == 0}
                     onClick={() => {
                        if (!editFormShown) {
                           setEditFormShown(true);
                           setEditFormType('images');
                        }
                  }} />
               }

               {!isOwner &&
                  <FavoriteButton
                     isFavorite={favorite}
                     activityID={id}
                     text="Favoriet"
                     />
               }               


               <ActivityImages 
                  images={imagePaths}
                  />

               {activities && 
                  <div id="info-activities">
                     {
                        activities.split(",").map((value, key) => {
                           return (<span className="tag" key={key}>{value.charAt(0).toUpperCase() + value.substring(1)}</span>);
                        })
                     }
                  </div>
               }

               <div id="info-people-price" className="box">

                  {isOwner && 
                     <EditActivityButton 
                        required={!openingHours || Object.keys(openingHours).length === 0}
                        onClick={() => {
                           if (!editFormShown) {
                              setEditFormShown(true);
                              setEditFormType('people_price');
                           }
                        }} />
                  }

                  <div className="h3-placeholder placeholder"></div>
                  <div className="p-placeholder placeholder small"></div>

                  <h3>
                     De activiteit<span className="stress">.</span>
                  </h3>

                  <Rating 
                     href="#activity-ratings"
                     rating={averageRating}
                     n={numRatings}
                     invert
                     />

                  <div id="description-hours">

                     <div id="description-hours-left">

                        <p id="short-description">
                           {shortDescription}
                           {!shortDescription && <>Deze activiteit vermeldt geen korte omschrijving.</>}
                        </p>
                        
                        {options &&
                           <div className="types">
                              <p>Je kunt hier terecht voor:</p>
                              {options && options.map((val, key) => {
                                 return (
                                    <span className="option">{val.name}</span>
                                 )
                              })}
                           </div>
                        }

                        {type &&
                           <div className="types">
                              <p>De wijze waarop de activiteit plaatsvindt:</p>
                              {type.split(";").map((val, key) => {
                                 return (
                                    <span>{val == "group" ? "Groep" : (val == "individual" ? "1-op-1" : "Online")}</span>
                                 )
                              })}
                           </div>
                        }

                     </div>

                     <div id="description-hours-right">

                        <ul
                           id="opening-hours"
                           >

                              <li>
                                 <span>Openingstijden</span>
                              </li>
                           
                           {[{name: 'mon', display: 'ma'}, {name: 'tue', display: 'di'}, {name: 'wed', display: 'wo'}, {name: 'thu', display: 'do'}, {name: 'fri', display: 'vr'}, {name: 'sat', display: 'za'}, {name: 'sun', display: 'zo'}].map((weekday) => {
                              
                              let closed = false;
                              let innerHTML;
                              if (!(weekday.name in openingHours)) {
                                 innerHTML = <span>Gesloten</span>;
                                 closed = true;
                              } else if (openingHours[weekday.name].start && openingHours[weekday.name].end) {
                                 innerHTML = <><span>{openingHours[weekday.name].start} - {openingHours[weekday.name].end}</span></>;
                              } else if (openingHours[weekday.name].start) {
                                 innerHTML = <span>Vanaf {openingHours[weekday.name].start}</span>;
                              } else {
                                 innerHTML = <span>Tot {openingHours[weekday.name].end}</span>;
                              }

                              return (
                                 <li
                                    className={closed ? "closed" : ""}
                                    >
                                    <span>{weekday.display}</span>
                                    {innerHTML}
                                 </li>
                              );

                           })}

                        </ul>

                     </div>

                  </div>

               </div>

               <div id="info-features" className="box">

                  {isOwner && 
                     <EditActivityButton 
                        onClick={() => {
                        if (!editFormShown) {
                           setEditFormShown(true);
                           setEditFormType('attributes');
                        }
                     }} />}

                  <div className="h3-placeholder placeholder"></div>
                  <div className="p-placeholder placeholder small"></div>

                  <h3>Kenmerken<span className="stress">.</span></h3>

                  {(attributes && attributes.length > 0) &&
                     <p>Dit is kenmerkend voor {name}:</p>}

                  {attributes.map((value, key) => {
                     return (
                        <div className="feature" key={key}>
                           <div className="icon" dangerouslySetInnerHTML={{__html: value.icon}}></div>
                           <span>{value.field_value}</span>
                        </div>
                     ) 
                  })}

                  {(!attributes || !attributes.length) &&
                     <p>Deze locatie vermeldt geen kenmerken.</p>
                  }

               </div>

               <div id="info-map" className="box">

                  {isOwner && 
                     <EditActivityButton 
                        required={houseNumber === null}
                        onClick={() => {
                        if (!editFormShown) {
                           setEditFormShown(true);
                           setEditFormType('location');
                        }
                     }} />}

                  <div className="h3-placeholder placeholder"></div>
                  <div className="p-placeholder placeholder small"></div>

                  <h3>Bereikbaarheid<span className="stress">.</span></h3>

                  <div id="transport-features">

                     <div className="transport-feature">
                        <div className="icon">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-5.523 0-10 4.394-10 9.815 0 5.505 4.375 9.268 10 14.185 5.625-4.917 10-8.68 10-14.185 0-5.421-4.478-9.815-10-9.815zm0 18c-4.419 0-8-3.582-8-8s3.581-8 8-8c4.419 0 8 3.582 8 8s-3.581 8-8 8zm1.08-9.204c0 .745-.549 1.008-1.293 1.008h-.463v-1.979h.64c.705 0 1.116.256 1.116.971zm3.92-1.713v5.833c0 1.151-.933 2.084-2.083 2.084h-5.834c-1.15 0-2.083-.933-2.083-2.083v-5.834c0-1.15.933-2.083 2.083-2.083h5.833c1.151 0 2.084.933 2.084 2.083zm-2.5 1.663c0-.69-.21-1.209-.628-1.557-.42-.348-1.031-.522-1.836-.522h-2.119v6.667h1.407v-2.371h.604c.823 0 1.457-.19 1.903-.57.446-.381.669-.93.669-1.647z"/></svg>
                        </div>
                        <span>{closestParking ? closestParking : 'Parkeergelegenheid is niet bekend'}</span>
                     </div>

                     <div className="transport-feature">
                        <div className="icon">
                           <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M6 24h-2c-.552 0-1-.448-1-1v-1c-.53 0-1.039-.211-1.414-.586s-.586-.884-.586-1.414v-8c-.552 0-1-.448-1-1v-3c0-.552.448-1 1-1v-4c0-1.657 1.343-3 3-3h16c1.657 0 3 1.343 3 3v4c.552 0 1 .448 1 1v3c0 .552-.448 1-1 1v8c0 .53-.211 1.039-.586 1.414s-.884.586-1.414.586v1c0 .552-.448 1-1 1h-2c-.552 0-1-.448-1-1v-1h-10v1c0 .552-.448 1-1 1zm-1.5-7c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm15 0c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm-5 1h-5c-.276 0-.5.224-.5.5s.224.5.5.5h5c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm6.5-12.5c0-.276-.224-.5-.5-.5h-17c-.276 0-.5.224-.5.5v8.5s3.098 1 9 1 9-1 9-1v-8.5zm-5-3.5h-8v1h8v-1z"/></svg>
                        </div>
                        <span>{closestPublicTransport ? closestPublicTransport : 'OV-bereikbaarheid is niet bekend'}</span>
                     </div>

                  </div>

                  <MapTile 
                     lat={lat}
                     lon={lon}
                     zoom={zoom}
                     markers={marker}
                     noDrag
                     children={
                        street && city ?
                           <Link 
                              to={`https://www.google.com/maps/search/?api=1&query=${(street + ' ' + houseNumber).replace(' ', '%20')}%2C${city}`}
                              target="_blank"
                              id="map-street-city">
                              <svg 
                                 className="icon"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.507 19l-1.507-6-6-1.5 12-4.5-4.493 12z"/></svg>
                              {street} {houseNumber}, {city}
                              <svg 
                                 className="link"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"/></svg>
                           </Link> : 
                           <></>
                     }
                     />

               </div>

               <div 
                  id="info-description" 
                  className={"box" + (!description || description.length < 200 ? " expand": "")}
                  onClick={(e) => {
                     e.target.classList.add('expand');
                  }}>

                  {isOwner && 
                     <EditActivityButton 
                        onClick={() => {
                           if (!editFormShown) {
                              setEditFormShown(true);
                              setEditFormType('description');
                           }
                     }} />
                  }

                  <div className="h3-placeholder placeholder"></div>
                  <div className="p-placeholder placeholder large"></div>

                  <h3>Omschrijving<span className="stress">.</span></h3>
                  
                  {description && description.split(/\r\n|\n/).map((value, key) => {
                     if (value.length) {
                        return (
                           <p key={key}>{value}</p>
                        )
                     }                     
                  })}

                  {!description && 
                     <p>Deze locatie vermeldt geen beschrijving.</p>
                  }

               </div>

               <div 
                  id="info-ratings"
                  className="box"
                  >
                  
                  <div id="activity-ratings"></div>

                  <h3>Reviews<span className="stress">.</span></h3>

                  {ratings.length == 0 &&
                     <p>Er zijn nog geen reviews.</p>   
                  }

                  {ratings.length > 0 &&
                     <p>{ratings.length} review{ratings.length == 1 ? "" : "s"}</p>}

                  {ratings.map((rating, key) => {
                     return (
                        <div className="rating-box">

                           <Rating 
                              rating={rating.rating}
                              single
                              />

                           <h4>{rating.title ? rating.title : 'Geen titel'}<span> • door {rating.first_name}</span></h4>
                           <p>{rating.message ? rating.message : 'Geen bericht.'}</p>

                        </div>
                     );
                  })}

               </div>

            </div>

            <div id="request-info" className={showRequest ? "show" : ""}>

               <div className="form-container">

                  <h3>Reserveren<span className="stress">.</span></h3>
                  {activities && 
                     <>
                        <h4>Type</h4>
                        <p>{activities}</p>
                     </>
                  }
                  <p>Na het reserveren wordt tegoed in rekening gebracht en kun je een afspraak plannen. De transactie is pas definitief na een afspraak met de aanbieder.</p>

                  {(user && !isOwner) && 
                     <Button 
                        onClick={() => {
                           if (!editFormShown) {
                              setEditFormShown(true);
                              setEditFormType('user_offerings');
                           }
                        }} 
                        name="Reserveren"
                        cta
                        disabled={isOwner || user.type == 'editor'}
                     />
                  }

                  {(user && isOwner) &&
                     <div id="request-info-insufficient" className="blue">
                        <p>Als aanbieder kun je geen activiteiten reserveren. Gebruikers van You Be Well zien hier een knop om te reserveren.</p>
                     </div>
                  }

               </div>

               <div id="close-request" onClick={toggleRequest}></div>

            </div>

         </section>

         <section id="recommendations">

            <div className="container">
               <h3 className="center">Dit vind je mogelijk interessant<span className="stress">.</span></h3>
               <p className="subtitle center accent">Bekijk de volgende activiteiten.</p>
               
               <div className="ads">
                  {recommendedAds.map((value, key) => {
                     return (<Ad key={key} optionMap={optionMap} {...value} />)
                  })}

               </div>

               <Button 
                  name="Bekijk meer"
                  href="/activities"
                  cta
                  />

            </div>   

         </section>
               
         {(!loading && !isOwner) && <Button id="show-request" 
            name="Reserveren"
            onClick={() => {
               if (!editFormShown) {
                  setEditFormShown(true);
                  setEditFormType('user_offerings');
               }
            }} 
            icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 0l-8.412 15h-5.215l-8.373-15h22zm-13 17v7h4v-7h-4z"/></svg>}
            cta />}

         <EditActivityForm
            shown={editFormShown}
            type={editFormType}
            values={{
               name: name,
               setName: setName,
               city: city,
               setCity: setCity,
               description: description,
               setDescription: setDescription,
               zip: zip,
               setZip: setZip,
               houseNumber: houseNumber,
               setHouseNumber: setHouseNumber,
               street: street,
               setStreet: setStreet,
               closestParking: closestParking,
               setClosestParking: setClosestParking,
               closestPublicTransport: closestPublicTransport,
               setClosestPublicTransport: setClosestPublicTransport,
               uniqueAttributes: uniqueAttributes,
               attributes: attributes,
               setAttributes: setAttributes,
               availability: availability,
               setAvailability: setAvailability,
               peopleMin: peopleMin,
               setPeopleMin: setPeopleMin,
               peopleMax: peopleMax,
               setPeopleMax: setPeopleMax,
               shortDescription: shortDescription,
               setShortDescription: setShortDescription,
               price: price,
               setPrice: setPrice,
               type: type,
               setType: setType,
               openingHours: openingHours,
               setOpeningHours: setOpeningHours,
               imagePaths: imagePaths,
               setImagePaths: setImagePaths,
               maxImages: 4,
               datetimeNow: datetimeNow,
               offerings: offerings,
               setOfferings, setOfferings,
               options: options,
               setOptions: setOptions,
               allOptions: allOptions,
               optionMap: optionMap,
               user: user,
            }}
            closeFormFn={() => { setEditFormShown(false); }}
         />

         {isOwner && 
            <div 
               id="manage-buttons"
               className={"show" + (deleteMode ? " delete" : "")}
               >

               <Form
                  className="update-status delete"
                  action={"/api/v1/activities/" + path + "/status"}
                  method="post"
                  submitCallback={() => {
                     navigate('/hub');
                     addNotification('Je listing is verwijderd.');
                  }}
                  noLoad
                  noSubmit
                  >

                  <input 
                     id="update-status-delete-submit"
                     type="submit" 
                     value="" />

                  <input id="update-status-value" type="hidden" value="archived" name="status" />

                  <label
                     onClick={() => {if (!deleteMode) setDeleteMode(true)}}
                     >
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"/></svg>
                     
                     {deleteMode &&
                        <>
                           <span>Zeker weten?</span>
                           <div>
                              <label onClick={() => {setDeleteMode(false)}} >Nee</label>
                              <label htmlFor="update-status-delete-submit">Ja</label>
                           </div>
                        </>
                     }

                  </label>

               </Form>

               <Form
                  className="update-status status"
                  action={"/api/v1/activities/" + path + "/status"}
                  method="post"
                  submitCallback={() => {setStatus(status == 'live' ? 'paused' : 'live')}}
                  noLoad
                  noSubmit
                  >

                  <input 
                     id="update-status-submit"
                     type="submit" 
                     value="" />

                  <input id="update-status-value" type="hidden" value={status == 'live' ? 'paused' : 'live'} name="status" />

                  <label
                     id="update-status-pause"
                     htmlFor="update-status-submit"
                     >
                     {status == 'paused' && 
                        <>
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 22v-20l18 10-18 10z"/></svg>
                        </>}
                     {status == 'live' && 
                        <>
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11 22h-4v-20h4v20zm6-20h-4v20h4v-20z"/></svg>
                           <span><span id="live-indicator"></span>Live</span>
                        </>}

                  </label>

               </Form>

               <Button
                  id="manage-offerings"
                  onClick={() => {
                     if (!editFormShown) {
                        setEditFormShown(true);
                        setEditFormType('offerings');
                     }
                  }} 
                  name="Arrangementen"
                  customIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3v-2c0-.552.447-1 1-1s1 .448 1 1v2c0 .552-.447 1-1 1s-1-.448-1-1zm-12 1c.553 0 1-.448 1-1v-2c0-.552-.447-1-1-1-.553 0-1 .448-1 1v2c0 .552.447 1 1 1zm13 13v-3h-1v4h3v-1h-2zm-5 .5c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5-4.5 2.019-4.5 4.5zm11 0c0 3.59-2.91 6.5-6.5 6.5s-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5zm-14.237 3.5h-7.763v-13h19v1.763c.727.33 1.399.757 2 1.268v-9.031h-3v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-9v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-3v21h11.031c-.511-.601-.938-1.273-1.268-2z"/></svg>}
                  cta
                  />
            </div>
         }

      </main>

   )

}