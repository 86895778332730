import { useEffect, useState } from 'react';

import './Checkbox.scss';

export default function Checkbox(props) {

   const [checked, setChecked] = useState(props.checked || false);

   const handleChecked = (e) => {
      if (props.disabled) return;
      setChecked(!checked);
      if (props.onChange()) props.onChange();
   }

   return (
      <div className={"checkbox" + (props.label ? "": " no-name") + (props.disabled ? " disabled": "") + (props.className ? " " + props.className : "")}>
         <input id={props.id} 
            name={props.name} 
            value={props.value ? props.value : "yes"}
            type={props.radio ? "radio" : "checkbox"}
            defaultChecked={props.checked}
            onChange={handleChecked} />
         <label style={{
               backgroundColor: (checked ? props.backgroundColor: ''),
               borderColor: props.backgroundColor,
               color: (!checked ? props.backgroundColor: ''),
               fill: (!checked ? props.backgroundColor: ''),
            }} 
            onClick={props.onClick}
            htmlFor={props.id} >
            {props.icon}
            {props.label}
         </label>
      </div>
   )

}