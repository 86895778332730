import { Helmet } from 'react-helmet';

const LeadinfoScript = () => {

   const checkCookie = (name) => {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
          return cookie.substring(name.length + 1);
        }
      }
      return null;
    }
   
    return (
        checkCookie("analytics-consent") == "yes" && (
            <Helmet>
                <script>
                    {`
                        (function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];
                        l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n;
                        l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);}
                        }(window,document,'script','https://cdn.leadinfo.net/ping.js','leadinfo','LI-6672C7A7B79FC'));
                    `}
                </script>
            </Helmet>
        )
    );
};

export default LeadinfoScript;