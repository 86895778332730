import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import Form from './Form';

import './FavoriteButton.scss';

export default function FavoriteButton(props) {

   const [isFavorite, setIsFavorite] = useState(false);

   const navigate = useNavigate();
   const location = useLocation();
   const redirectToLogin = (e, data) => {
      if ('error' in data) {
         navigate('/login?redirect=' +  encodeURIComponent(location.pathname));
      }
   }

   useEffect(() => {
      if (props.isFavorite) {
         setIsFavorite(true);
      }
   }, [props.isFavorite]);

   return (

      <Form
         action="/api/v1/favorites"
         method="POST"
         className="favorite-form"
         id={"favorite-" + props.activityID}
         submitCallback={redirectToLogin}
         noLoad
         >

         <input type="hidden" name="activity_id" value={props.activityID} />

         <label 
            onClick={() => { setIsFavorite(!isFavorite) }}
            htmlFor={"favorite-" + props.activityID + "-submit"}
            className={"favorite-btn" + (isFavorite ? " favorite" : "") + (props.text ? " text" : "")}>
            
            {props.text && 
               <span>{props.text}</span>
            }

            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/></svg>
            
         </label>

      </Form>

   );

}