import { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";

import Table from "./Table";

import './ProfileFinance.scss';

export default function ProfileFinance(props) {

   const [bookings, setBookings] = useState([]);
   const [settlements, setSettlements] = useState([]);

   const request = {
      bookings: {
          endpoint: "/api/v1/bookings",
          method: "GET",
      },
      settlements: {
         endpoint: "/api/v1/settlements",
         method: "GET",
      }
   }

   const [data, loading, error] = useFetch(request);

   useEffect(() => {

      if (!loading) {
         
         if (data.bookings && data.bookings.data) {
            const bookings = [...data.bookings.data];
            for (let i = 0; i < bookings.length; i++) {
               bookings[i]['description'] = bookings[i]['description'] && bookings[i]['description'].length < 30 ? bookings[i].description : bookings[i].description.substring(0, 27) + '...';
               bookings[i]['full_name'] = bookings[i]['first_name'] + ' ' + bookings[i]['last_name']
               bookings[i]['status'] = bookings[i]['editor_status'] !== 'confirmed' ? 'Uit te voeren' : (bookings[i]['settlement_id'] !== null ? "Betaald" : "Te betalen");
               bookings[i]['pretty_created_at'] = new Date(bookings[i]['created_at']).toLocaleDateString();
            }
            setBookings(bookings);
         }

         if (data.settlements && data.settlements.data) {
            const settlements = [...data.settlements.data];
            for (const settlement of settlements) {
               settlement['created_at'] = new Date(settlement['created_at']).toLocaleDateString();
            }
            setSettlements(settlements);
         }

      }

   }, [loading]);

   return (
      <div id="finance">

         <h3>Financieel overzicht.</h3>
         <p>Hier krijg je inzicht in de betalingsstatus van boekingen.</p>

         <Table
            headers={[
               {field: 'location_name', label: 'Volledige naam', sortable: true, searchable: true},
               {field: 'description', label: 'Omschrijving', sortable: true, searchable: true},
               {field: 'full_name', label: 'Geboekt door', sortable: true, searchable: true},
               {field: 'status', label: 'Status', className: 'tag', alignRight: true, tagMap: {
                  Beheerder: {
                     icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 19h14v3h-14v-3zm18-11c-.729 0-1.295.79-.832 1.521-1.229 1.474-3.371 2.971-4.355 2.438-1.201-.65-.277-3.302.451-4.982.958.15 1.736-.591 1.736-1.477 0-.829-.672-1.5-1.5-1.5s-1.5.671-1.5 1.5c0 .452.204.853.52 1.127-.645 1.643-2.325 3.807-3.41 3.591-1.347-.268-1.69-3.448-1.685-5.287.62-.183 1.075-.751 1.075-1.431 0-.829-.672-1.5-1.5-1.5s-1.5.671-1.5 1.5c0 .68.455 1.248 1.075 1.432.006 1.839-.338 5.019-1.685 5.287-1.084.216-2.765-1.949-3.41-3.592.316-.274.52-.675.52-1.127 0-.829-.672-1.5-1.5-1.5s-1.5.671-1.5 1.5c0 .886.778 1.627 1.736 1.476.729 1.681 1.652 4.333.451 4.982-.984.533-3.127-.964-4.355-2.438.463-.73-.103-1.52-.832-1.52-.553 0-1 .448-1 1 0 .704.726 1.221 1.413.905 1.134 1.264 3.335 4.242 3.587 7.095h14c.252-2.853 2.453-5.831 3.587-7.095.687.316 1.413-.201 1.413-.905 0-.552-.447-1-1-1z"/></svg>,
                  },
                  Aanbieder: {
                     icon: <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m15.4 19.25c0-.414.336-.75.75-.75h5.1c.414 0 .75.336.75.75s-.336.75-.75.75h-5.1c-.414 0-.75-.336-.75-.75zm-1.4-14.25c0-.53-.47-1-1-1h-10c-.53 0-1 .47-1 1v14c0 .53.47 1 1 1h10c.53 0 1-.47 1-1zm1.4 10.625c0-.414.336-.75.75-.75h5.1c.414 0 .75.336.75.75s-.336.75-.75.75h-5.1c-.414 0-.75-.336-.75-.75zm0-3.625c0-.414.336-.75.75-.75h5.1c.414 0 .75.336.75.75s-.336.75-.75.75h-5.1c-.414 0-.75-.336-.75-.75zm0-3.625c0-.414.336-.75.75-.75h5.1c.414 0 .75.336.75.75s-.336.75-.75.75h-5.1c-.414 0-.75-.336-.75-.75zm0-3.625c0-.414.336-.75.75-.75h5.1c.414 0 .75.336.75.75s-.336.75-.75.75h-5.1c-.414 0-.75-.336-.75-.75z" fillRule="nonzero"/></svg>,
                  },
                  Gebruiker: {
                     icon: <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fillRule="evenodd" clipRule="evenodd"><path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7zm-3-8c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"/></svg>
                  }
               }},
               {field: 'price', alignRight: true, label: 'Prijs'},
               {field: 'pretty_created_at', alignRight: true, label: 'Boekingsdatum'},
            ]}
            data={bookings}
            rowsPerPage={20}
            />

         <h3>Betalingen.</h3>
         <p>Hier zie je de betalingen vanuit You Be Well aan jou.</p>

         <div id="finance-settlements">

            {settlements.map((settlement, key) => {

               return (
                  <div key={key} className="settlement">
                     <span className="settlement-date">{settlement.created_at}</span>
                     <span className="settlement-amount">{settlement.token_amount}</span>
                  </div>
               )

            })}

            {settlements.length === 0 &&
               <p
                  id="no-settlements"
                  >Er zijn nog geen betalingen.</p>
            }

         </div>


      </div>
   );

}