import { useEffect } from 'react';

import Calculator from "../components/Calculator";

import './CalculatorPage.scss';

export default function CalculatorPage() {

   useEffect(() => {
      document.title = "Calculator - You Be Well";
   }, []);  

   return (

      <main id="calculator">

         <section>

            <Calculator />

         </section>

      </main>

   );

}