import { HashLink } from 'react-router-hash-link';

import './Button.scss';

export default function Button(props) {

   if (props.submit) {

      return (
         <div 
            className={"btn-container" + (props.className ? " " + props.className : '')}
            style={props.style}>

            <input
               id={props.id}
               form={props.form}
               type="submit"
               className={"btn" + (props.cta ? " cta" : "") + (props.disabled ? " disabled" : "")}
               value={props.name}
               disabled={props.disabled}
               />

            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M2.65335 15.7847C1.63801 16.394 0.33868 15.6627 0.33868 14.4733L0.33868 1.53068C0.33868 0.341348 1.63801 -0.389985 2.65335 0.219348L13.44 6.69201C14.4287 7.28602 14.4267 8.71935 13.44 9.31202L2.65335 15.7847Z" fill="white"/>
            </svg>

         </div>
      );

   }

   return (
      <div 
         id={props.id} 
         className="btn-container" 
         style={props.style}>

         {!props.noLink &&
            <HashLink 
               className={"btn" + (props.cta ? " cta" : "") + (props.className ? " " + props.className : '') + (props.disabled ? " disabled" : "")}
               onClick={props.onClick}
               to={props.href}
               target={props.target}
               >
               {props.name}
            </HashLink>
         }

         {props.noLink &&
            <div 
               className={"btn" + (props.cta ? " cta" : "") + (props.className ? " " + props.className : '') + (props.disabled ? " disabled" : "")}
               onClick={props.onClick}
               >
               {props.name}
            </div>
         }

         {(!props.customIcon && !props.noIcon) &&
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M2.65335 15.7847C1.63801 16.394 0.33868 15.6627 0.33868 14.4733L0.33868 1.53068C0.33868 0.341348 1.63801 -0.389985 2.65335 0.219348L13.44 6.69201C14.4287 7.28602 14.4267 8.71935 13.44 9.31202L2.65335 15.7847Z" fill="white"/>
            </svg>}
         
         {props.customIcon}

      </div>
   )

}