import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
import useTokenStorage from "../../hooks/useTokenStorage";

export const RefreshRoute = ({ children }) => {

    const [getTokens, saveToken, removeToken, clearTokens, getToken, getTokenExpriy, refreshToken, newTokenObtained] = useTokenStorage();
    const navigate = useNavigate();
    const location = useLocation();
    const { user, login } = useAuth();

    const [refreshStatus, setRefreshStatus] = useState('pending');

    useEffect(() => {
      
        const access_token_expiry =  getTokenExpriy('access_token');
        const refresh_token_expiry = getTokenExpriy('refresh_token');

        if (Date.now() > access_token_expiry && Date.now() <= refresh_token_expiry) {
          
          refreshToken().then((data) => {
            
            if ('error' in data) {
              clearTokens();
            } else {
              login(data.data);
            }
            setRefreshStatus('done');

          });


        }

        setRefreshStatus('done');
      }, [getTokens, getToken, getTokenExpriy, location.pathname]); // Monitor changes in the tokens state
      
      if (refreshStatus === 'pending') {
        return <div style={{width: '100vw', height: '100vh', display: 'block'}}></div>;
      }

      if (user) {
        if (user.first_name === null && location.pathname != '/welcome') {
          navigate('/welcome');
          return;
        }
      }
    
      return children;

};