import { useState } from "react";

import Button from "../inputs/Button";
import Checkbox from "../inputs/Checkbox";
import { ReactComponent as HorizontalLogo } from '../../assets/ybw_logo.svg';

import './CookieBanner.scss';

export default function CookieBanner() {

   const checkCookie = (name) => {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
          return cookie.substring(name.length + 1);
        }
      }
      return null;
    }

   const [expanded, setExpanded] = useState(false);
   const [shown, setShown] = useState(true);

   if (!shown) return;
   if (checkCookie("consent") == "yes") return;

   const agreeToAll = () => {

      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);

      document.cookie = "consent=yes; expires=" + expirationDate.toUTCString() + "; path=/";
      document.cookie = "analytics-consent=yes; expires=" + expirationDate.toUTCString() + "; path=/";
      document.cookie = "marketing-consent=yes; expires=" + expirationDate.toUTCString() + "; path=/";

      window.gtag('consent', 'update', {
         'ad_storage': 'granted',
         'analytics_storage': 'granted',
      });

      window.gtag('event', 'consent', {
         event_category: 'engagement',
         event_label: 'consent_banner',
         value: 1
       });

   }

   const agreeToSubset = () => {

      const formData = new FormData(document.getElementById("cookie-banner-consents"));

      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);

      const analyticsConsent = formData.get("analytics") == "yes" ? "yes" : "no";
      const marketingConsent = formData.get("marketing") == "yes" ? "yes" : "no";

      document.cookie = "consent=yes; expires=" + expirationDate.toUTCString() + "; path=/";
      document.cookie = "analytics-consent=" + analyticsConsent + "; expires=" + expirationDate.toUTCString() + "; path=/";
      document.cookie = "marketing-consent=" + marketingConsent + "; expires=" + expirationDate.toUTCString() + "; path=/";

      window.gtag('consent', 'update', {
         'ad_storage': marketingConsent ? 'granted' : 'denied',
         'analytics_storage': analyticsConsent ? 'granted' : 'denied',
      });

      window.gtag('event', 'consent', {
         event_category: 'engagement',
         event_label: 'consent_banner',
         value: 2
      });

   }

   return (
      <div id="cookie-banner">

         <HorizontalLogo />

         <h2>Cookies<span className="stress">.</span></h2>
         <p>Met cookies stemmen we je ervaring met You Be Well beter af op jouw voorkeuren.</p>

         {expanded && 
            <form id="cookie-banner-consents">

               <div className="cookie-consent">
                  <div className="text">
                     <h3>Functioneel</h3>
                     <p>Deze cookies zijn noodzakelijk voor een werkende site.</p>
                  </div>
                  <div className="switch">
                     <Checkbox 
                        id="cookie-banner-checkbox-functional"
                        name="functional"
                        checked={true}
                        disabled
                     />
                  </div>
               </div>

               <div className="cookie-consent">
                  <div className="text">
                     <h3>Analytisch</h3>
                     <p>Voor tools die het gedrag van bezoekers van You Be Well in kaart brengen.</p>
                  </div>
                  <div className="switch">
                     <Checkbox 
                        id="cookie-banner-checkbox-analytics"
                        name="analytics"
                        checked="" />
                  </div>
               </div>

               <div className="cookie-consent">
                  <div className="text">
                     <h3>Marketing</h3>
                     <p>Voor tools waarmee we advertenties personaliseren en retargeting uitvoeren.</p>
                  </div>
                  <div className="switch">
                     <Checkbox 
                        id="cookie-banner-checkbox-marketing"
                        name="marketing"
                        checked="" />
                  </div>
               </div>

            </form>
         }

         <div id="cookie-banner-buttons">
            {!expanded &&
            <a id="cookie-banner-expand" onClick={() => setExpanded(true)}>
               Meer weten
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>
            </a>}

            {expanded &&
            <Button 
               name={(expanded ? "Selectie" : "") + " akkoord"}
               onClick={() => { agreeToSubset(); setShown(false); }} />
               }

            <Button 
               name={(expanded ? "Alles" : "") + " akkoord"}
               onClick={() => { agreeToAll(); setShown(false); }}
               cta />

         </div>

      </div>
   );

}