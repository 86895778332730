import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import useTokenStorage from "./useTokenStorage";

export default function useFetch(requestObject, useEffectDependency, ignoreRouteChange = false) {

   const [data, setData] = useState({});    
   const [newData, setNewData] = useState({});
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(false);


   function handleData(requestKey, requestData) {
      setNewData(prevData => {
          const updatedData = {
              ...prevData,
              [requestKey]: requestData,
          };
          return updatedData; // This returns a new object, ensuring React detects a change
      });
  }

  useEffect(() => {
      if (Object.keys(requestObject).length == Object.keys(newData).length) {
         setData({...newData});
         setNewData({});
      } else {
         setLoading(true);
      }
  }, [newData])

   useEffect(() => {
      setLoading(Object.keys(requestObject).length > Object.keys(data).length);
  }, [data])

   const [getTokens, saveToken, removeToken, clearTokens, getToken, getTokenExpriy, refreshToken, newTokenObtained] = useTokenStorage();

   const location = useLocation();
   const { pathname, search } = location;
   const routeKey = ignoreRouteChange ? '' : `${pathname}${search}`;
      
   const tokenString = JSON.stringify(getTokens);

   useEffect(() => {

      if (requestObject === null || requestObject === undefined) return;

      // refresh token if triggered outside of <ProtectedRoute /> (as this handles automatic refreshes)
      const access_token_expiry = getTokenExpriy('access_token');
      const refresh_token_expiry = getTokenExpriy('refresh_token');
      if (access_token_expiry < Date.now() && Date.now() > refresh_token_expiry) {
         refreshToken();
      }

      for (const requestKey of Object.keys(requestObject)) {

         const requestOptions = {
            method: requestObject[requestKey].method,
            headers: {
               'Content-Type': 'application/json',
            },
         }
         
         if (requestObject[requestKey].body) requestOptions.body = JSON.stringify(requestObject[requestKey].body);
             
         if (getToken('access_token')) {
            if (access_token_expiry > Date.now()) {
               requestOptions.headers['Authorization'] = 'Bearer ' + getToken('access_token');
            }
         }

         fetch(requestObject[requestKey].endpoint, requestOptions)
            .then(response => response.json())
            .catch(() => {})
            .then(data => handleData(requestKey, data));

      }

   }, [routeKey, tokenString, useEffectDependency]);

   return [data, loading, error];

}